import messages_en from "./en";
import messages_bg from "./bg";
import messages_ru from "./ru";
import messages_ro from "./ro";

export type SupportedLanguages = "en" | "bg" | "ru" | "ro";
export const supportedLanguages: SupportedLanguages[] = ["en", "bg", "ru", "ro"];
export const languageMappping = {
  en: "gb",
  bg: "bg",
  ru: "ru",
  ro: "ro",
};

export const isLanguageSupported = (language: string): boolean => {
  return supportedLanguages.includes(language as SupportedLanguages);
};

export const messages = {
  en: messages_en,
  bg: messages_bg,
  ru: messages_ru,
  ro: messages_ro,
};
