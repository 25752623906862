import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { HeadCells, Order } from "../types/tables";
import { visuallyHidden } from "@mui/utils";

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  selectable?: boolean;
  headCells: HeadCells;
}

export const EnhancedTableHead = (props: EnhancedTableProps) => {
  return (
    <TableHead>
      <EnhancedTableHeadContents {...props} />
    </TableHead>
  );
};

export const EnhancedTableHeadContents = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectable = false,
    headCells,
  } = props;

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableRow>
      {selectable && (
        <TableCell padding="checkbox" component="th">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
      )}
      {headCells.map((headCell) =>
        headCell.sortable ? (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            component="th"
            style={{ width: headCell.width || "auto" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.tooltip ? (
                <Tooltip title={headCell.tooltip}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    {headCell.icon}
                    {headCell.label}
                  </div>
                </Tooltip>
              ) : (
                headCell.label
              )}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ) : (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            component="th"
            style={{ width: headCell.width || "auto" }}
          >
            {headCell.tooltip ? (
              <Tooltip title={headCell.tooltip}>
                <div style={{ display: "flex", gap: "5px" }}>
                  {headCell.icon}
                  {headCell.label}
                </div>
              </Tooltip>
            ) : (
              headCell.label
            )}
          </TableCell>
        )
      )}
    </TableRow>
  );
};
