import { Box, Typography, Button, Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import header_Image from "../../ressources/ibc_logo.png";

const NotAuthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{ mt: 8, p: 4, display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <img
          src={header_Image} // Replace with your logo path
          alt="Logo"
          style={{ width: "150px", height: "150px" }}
        />
        <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
          Not Authorized
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          You do not have permission to view this page.
        </Typography>
        <Box mt={4}>
          <Button variant="contained" color="primary" onClick={handleGoBack}>
            Go Back
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default NotAuthorized;
