import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  ApplicationDoc,
  ApplicationsDict,
  EMPTY_VISA_APPLICATION,
  VisaApplication,
} from "../../types/visa-application";

import { v4 as uuidv4 } from "uuid";

// import { functions } from "../../types/firebase";
import { httpsCallable } from "firebase/functions";

import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  MenuItem,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { TableCell } from "@mui/material";
import { Tooltip, IconButton } from "@mui/material";
import { Card, CardHeader, CardContent } from "@mui/material";
import {
  Save as SaveIcon,
  EditNote as EditNoteIcon,
  SportsScoreOutlined as FinishFlag,
  VerifiedUserOutlined as PrivacyIcon,
  CheckCircleOutlineOutlined as CheckIcon,
  ToggleOn,
  ToggleOff,
  Slideshow,
  PresentToAll,
  CancelPresentation,
  Input as InputIcon,
  InfoOutlined as InfoIcon,
} from "@mui/icons-material";

import { ApplicantsDict } from "../../types/applicants";
import { SponsorsDict } from "../../types/sponsors";
import { EmployersDict, SeasonsDict, WorkOrdersDict } from "../../types/orders";
import SimpleLinkDialog from "../dialogs/dialogSimpleLink";
import ApplicationDialog from "../dialogs/dialogApplicationSettings";
import { BASE_URL, isDateInThePast } from "../../utils/utils";
import { applicantFullName } from "../../utils/applicant-utils";
import ApplicationStatusDialog from "../dialogs/dialogApplicationStatus";
import { TitledPage } from "../../components/titled-page";
import { HeadCells, Order, getComparator } from "../../types/tables";
import { EnhancedTableHeadContents } from "../../components/enhanced-table";
import { loadEmployers, loadSponsors } from "../../data-functions/system-data_api";
import { AuthContext } from "../../components/auth-provider";
import { loadSeasons, loadWorkOrders } from "../../data-functions/orders-api";
import {
  deleteApplications,
  loadApplications,
  storeApplication,
  updateApplication,
} from "../../data-functions/applications-api";
import { addHOPSCosApplicants } from "../../utils/exporters";
import { loadAgents } from "../../data-functions/agent-api";
import { AgentsDict } from "../../types/agents";
import { TableVirtuoso } from "react-virtuoso";
import { loadApplicant, loadApplicants } from "../../data-functions/applicants-api";
import { VirtuosoTableComponents } from "../../components/enhanced-virtuoso-table";
import ApplicationsBatchUpdateDialog, {
  BatchUpdateProps,
} from "../dialogs/dialogApplicationBatchUpdate";
import { displayStatusDescription, displayStatusLabel } from "../../utils/application-utils";
import { functions } from "../../types/firebase";
import {
  VirtualPresentationInstance,
  VirtualPresentationsDict,
} from "../../types/virtual-presentations";
import ApplicationVirtualPresentationDialog from "../dialogs/dialogApplicationVirtualPresentation";
import _, { forEach } from "lodash";
import { loadVirtualPresentations } from "../../data-functions/virtual_presentataions_api";
import { useNavigate } from "react-router-dom";
import ApplicationRow from "../../components/application-row";
import { LogEntry } from "../../types/commons";
import { logEvent } from "../../utils/db-utils";
import { DocumentHistoryDialog } from "../dialogs/dialogDocumentHistory";
import { hasRole } from "../../utils/user-utils";

interface MailDataFormResult {
  data: {
    status: string;
    message: string;
    // Define other properties here if needed
  };
}

const headCells: HeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "workOrderId",
    numeric: false,
    disablePadding: false,
    label: "Work Order",
    sortable: true,
  },
  {
    id: "finished",
    numeric: false,
    disablePadding: false,
    label: "",
    tooltip: "Data form expiry date",
    sortable: true,
    icon: <FinishFlag />,
    width: "100px",
  },
  {
    id: "privacy_policy_accepted",
    numeric: false,
    disablePadding: false,
    label: "",
    tooltip: "Privacy policy accepted",
    sortable: true,
    icon: <PrivacyIcon />,
    width: "30px",
  },
  {
    id: "virtual_presentation",
    numeric: false,
    disablePadding: false,
    label: "",
    tooltip: "Virtual presentation status",
    sortable: true,
    icon: <Slideshow />,
    width: "30px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sortable: true,
    width: "130px",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
    width: "180px",
  },
];

const ApplicationsPage: React.FC = () => {
  const [applications, setApplications] = useState<ApplicationsDict>({});
  const [applicants, setApplicants] = useState<ApplicantsDict>({});
  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [sponsors, setSponsors] = useState<SponsorsDict>({});
  const [agents, setAgents] = useState<AgentsDict>({});
  const [employers, setEmployers] = useState<EmployersDict>({});
  const [orders, setOrders] = useState<WorkOrdersDict>({});
  const [presentations, setPresentations] = useState<VirtualPresentationsDict>({});
  const [selectedApplication, setSelectedApplication] = useState<ApplicationDoc | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<string>("");
  const [selectedSeason, setSelectedSeason] = useState<string>("");

  const [showOpenOrders, setShowOpenOrders] = useState<boolean>(true);

  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openVPDilog, setOpenVPDialog] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  //const [statusAnchorEl, setStatusAnchorEl] = useState<HTMLButtonElement | null>(null);
  //const [statusApplicationId, setStatusApplicationId] = useState<string>("");

  //  const [editMode, setEditMode] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [currentLink, setCurrentLink] = useState("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [openBatchUpdate, setOpenBatchUpdate] = useState(false);
  const [batchUpdateValues, setBatchUpdateValues] = useState<BatchUpdateProps>({});

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [selected, setSelected] = useState<readonly string[]>([]);

  const [editAppl, setEditAppl] = useState<ApplicationDoc>({
    id: "",
    application: { ...EMPTY_VISA_APPLICATION },
  });
  const [historyDoc, setHistoryDoc] = useState<string>("");

  const { currentUser } = useContext(AuthContext)!;
  const navigate = useNavigate();

  const fetchApplications = async (
    orderId: string = "all_orders",
    seasonId: string = "no_season"
  ) => {
    // load applications
    let filter: Record<string, string>;

    switch (orderId) {
      case "all_orders":
        filter = {};
        break;
      case "no_order":
        filter = { workOrderId: "" };
        break;

      default:
        filter = { workOrderId: orderId };
        break;
    }

    switch (seasonId) {
      case "no_season":
        filter = { ...filter, seasonId: "" };
        break;
      default:
        filter = { ...filter, seasonId };
        break;
    }

    console.log("filter: ", filter);

    const applicationsDict: ApplicationsDict = await loadApplications(currentUser?.appUser, filter);

    const applicantIds: string[] = [];
    Object.keys(applicationsDict).forEach((applicationId) => {
      const application = applicationsDict[applicationId];
      if (application.applicantId) {
        applicantIds.push(application.applicantId);
      }
    });

    let applicantsDict: ApplicantsDict = await loadApplicants(currentUser?.appUser);

    setApplicants(applicantsDict);
    setApplications(applicationsDict);
  };

  const fetchEmployers = async () => {
    // loading Employers
    const employersDict = await loadEmployers(currentUser?.appUser);
    setEmployers(employersDict);
  };

  const fetchSponsors = async () => {
    const sponsorsDict = await loadSponsors(currentUser?.appUser);

    if (sponsorsDict) {
      setSponsors(sponsorsDict);
    } else {
      setSponsors({});
    }
  };

  const fetchAgents = async () => {
    const agentsDict = await loadAgents(currentUser?.appUser);
    if (agentsDict) {
      setAgents(agentsDict);
    } else {
      setAgents({});
    }
  };

  const fetchWorkOrders = async () => {
    const newOrders = await loadWorkOrders(currentUser?.appUser);

    if (newOrders) {
      setOrders(newOrders);
      const fOrders = sortAndFilterOrders(newOrders, showOpenOrders, selectedSeason);

      if (fOrders.length > 0) {
        setSelectedOrder(fOrders[0][0]);
      }
    } else {
      setOrders({});
    }
  };

  const fetchPresentations = async () => {
    const presentationsDict: VirtualPresentationsDict = await loadVirtualPresentations(
      currentUser?.appUser
    );

    if (presentationsDict) {
      setPresentations(presentationsDict);
    } else {
      setPresentations({});
    }
  };

  const fetchSeasons = async () => {
    // loading Employers
    const seasonsDict = await loadSeasons(currentUser?.appUser);
    const year = new Date().getFullYear();
    const seasonId = Object.entries(seasonsDict).find(([id, season]) => {
      return new Date(season.start_date).getFullYear() === year;
    })?.[0];
    setSeasons(seasonsDict);
    if (seasonsDict && seasonId) setSelectedSeason(seasonId);
  };

  useEffect(() => {
    setShowOpenOrders(true);
    setSelectedApplication(null);
    setApplications({});
    setSelectedOrder("");
    fetchEmployers();
    fetchSponsors();
    fetchAgents();
    fetchSeasons();
    fetchWorkOrders();
    fetchPresentations();
  }, [currentUser]);

  useEffect(() => {
    if (selectedOrder && selectedSeason) {
      setSearchValue("");
      fetchApplications(selectedOrder, selectedSeason);
    }
  }, [selectedOrder, selectedSeason]);

  const createQuestionnaireLink = async (application: ApplicationDoc) => {
    if (!application.application.questionnaireId) {
      application.application.questionnaireId = uuidv4();
      await handleSaveApplication(application);
    }
  };

  const getWorkOrderStatusChip = (status: string | undefined) => {
    switch (status) {
      case "open":
        return <Chip label="Open" color="success" size="small" />;

      case "closed":
        return <Chip label="Closed" color="error" size="small" />;

      default:
        return <Chip label="n/a" variant="outlined" color="primary" size="small" />;
    }
  };

  const handleAddNewApplication = (applicationId: string, edit: boolean = false) => {
    let newApplication: ApplicationDoc;
    if (!edit) {
      newApplication = { id: "", application: { ...EMPTY_VISA_APPLICATION } };
      newApplication.application.workOrderId =
        selectedOrder && selectedOrder !== "all_orders" && selectedOrder !== "no_order"
          ? selectedOrder
          : "";
      newApplication.application.seasonId = selectedSeason;
      newApplication.application.sponsorId = currentUser?.appUser?.sponsorId || "";
      newApplication.application.agentId = currentUser?.appUser?.agentId || "";
    } else {
      newApplication = { id: applicationId, application: { ...applications[applicationId] } };
    }
    //    setEditMode(edit && !!selectedApplication);
    setEditAppl(newApplication);
    setOpen(true);
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  // const handleOpenStatus = (event: React.MouseEvent<HTMLButtonElement>, applicationId: string) => {
  //   setStatusApplicationId(applicationId);
  //   setStatusAnchorEl(event.currentTarget);
  // };

  const handleCloseStatus = () => {
    // setStatusApplicationId("");
    // setStatusAnchorEl(null);

    if (openStatus) {
      setOpenStatus(false);
    }
  };

  const handleExport = async () => {
    const count = selected.length === 0 ? visibleRows.length : selected.length;
    if (!window.confirm("Are you sure you want to export " + count + " applications?")) {
      return;
    }
    console.log("exporting " + count + " applications");
    const exportApplications: any[] = [];
    const exportIds: string[] = [];

    if (selected.length === 0) {
      visibleRows.forEach(([id, application]) => {
        exportApplications.push({
          application,
          applicant: applicants[application.applicantId] || null,
          workOrder: orders[application.workOrderId] || null,
        });
        exportIds.push(id);
      });
    } else {
      selected.forEach((id) => {
        const application = applications[id];
        if (application) {
          exportApplications.push({
            application,
            applicant: applicants[application.applicantId] || null,
            workOrder: orders[application.workOrderId] || null,
          });
          exportIds.push(id);
        }
      });
    }

    try {
      // Convert applications data to CSV format:
      const csvData = addHOPSCosApplicants(exportApplications);
      const curentDate = new Date().toISOString().split("T")[0];
      // Create a blob from the CSV data and download:
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "applicants " + curentDate + ".csv"; // specify the file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } finally {
      const logEntry: LogEntry = {
        collection: "applications",
        documentId: "",
        sponsorId: currentUser?.appUser?.sponsorId || "",
        operation: "export",
        changedFields: { applications: exportIds },
      };
      logEvent(logEntry);
    }
  };

  const handleBatchUpdate = async () => {
    if (selected.length === 0) {
      return;
    }

    let currentValues: BatchUpdateProps = { sendPresentationLink: false };
    selected.forEach((id) => {
      const application = applications[id];

      if (currentValues.agentId === undefined) {
        if (application.agentId) {
          currentValues.agentId = application.agentId;
        } else {
          currentValues.agentId = "";
        }
      } else if (application.agentId !== currentValues.agentId) {
        currentValues.agentId = "multiple";
      }

      if (currentValues.seasonId === undefined) {
        if (application.seasonId) {
          currentValues.seasonId = application.seasonId;
        } else {
          currentValues.seasonId = "";
        }
      } else if (application.seasonId !== currentValues.seasonId) {
        currentValues.seasonId = "multiple";
      }

      if (currentValues.workOrderId === undefined) {
        if (application.workOrderId) {
          currentValues.workOrderId = application.workOrderId;
        } else {
          currentValues.workOrderId = "";
        }
      } else if (application.workOrderId !== currentValues.workOrderId) {
        currentValues.workOrderId = "multiple";
      }

      if (currentValues.status === undefined) {
        if (application.status) {
          currentValues.status = application.status;
        } else {
          currentValues.status = "";
        }
      } else if (application.status !== currentValues.status) {
        currentValues.status = "multiple";
      }

      console.log("virtual presentation: ", application.virtualPresentation);
      if (currentValues.virtualPresentationId === undefined) {
        if (application.virtualPresentation?.virtualPresentationId) {
          currentValues.virtualPresentationId =
            application.virtualPresentation.virtualPresentationId;
          currentValues.expiry_date = application.virtualPresentation.expiry_date;
        } else {
          // setting it to "" shows that at least one application has been checked
          currentValues.virtualPresentationId = "";
        }
      } else if (
        (application.virtualPresentation?.virtualPresentationId || "") !==
        currentValues.virtualPresentationId
      ) {
        currentValues.virtualPresentationId = "multiple";
        currentValues.expiry_date = "";
      }
    });

    console.log("currentValues: ", currentValues);

    const initialValues: BatchUpdateProps = { ...currentValues };
    // if (currentValues.agentId !== "multiple") {
    //   initialValues.agentId = currentValues.agentId;
    // }

    // if (currentValues.seasonId !== "multiple") {
    //   initialValues.seasonId = currentValues.seasonId;
    // }

    // if (currentValues.workOrderId !== "multiple") {
    //   initialValues.workOrderId = currentValues.workOrderId;
    // }

    // if (currentValues.status !== "multiple") {
    //   initialValues.status = currentValues.status;
    // }

    console.log("initialValues: ", initialValues);

    setBatchUpdateValues(initialValues);
    setOpenBatchUpdate(true);
  };

  const onBatchUpdate = async (values: BatchUpdateProps) => {
    const newApplications: ApplicationsDict = { ...applications };

    console.log("batch update values: ", values);

    let seasonMissmatch = false;
    if (values.seasonId && !values.workOrderId) {
      forEach(selected, (id) => {
        const woId = applications[id].workOrderId;
        if (woId && orders[woId].seasonId !== values.seasonId) {
          seasonMissmatch = true;
        }
      });

      if (seasonMissmatch) {
        const res = window.confirm(
          "The selected applications are assigned to work orders that do not macht the desired season. If you continue, the work orders will be changed too. Do you want to continue?"
        );
        if (!res) {
          return;
        }
      }
    }

    seasonMissmatch = false;
    if (!values.seasonId && values.workOrderId && values.workOrderId !== undefined) {
      const woId = values.workOrderId;
      forEach(selected, (id) => {
        if (orders[woId].seasonId !== applications[id].seasonId) {
          seasonMissmatch = true;
        }
      });

      if (seasonMissmatch) {
        const res = window.confirm(
          "The selected applications are assigned to a seasons that do not macht the desired work order. If you continue, the applications will be assigned the correct season. Do you want to continue?"
        );
        if (!res) {
          return;
        }
      }
    }

    let vpMissmatch = false;
    if (values.virtualPresentationId) {
      forEach(selected, (id) => {
        if (applications[id].virtualPresentation?.virtualPresentationId) {
          vpMissmatch = true;
        }
      });

      if (vpMissmatch) {
        const res = window.confirm(
          "Some applications are already assigned a virtual presentation. If you continue, the corresponding applications will not be assigned the selected virtual presentation. Do you want to continue?"
        );
        if (!res) {
          return;
        }
      }
    }

    let vpMails: string[] = [];
    let applChanges: Promise<ApplicationDoc | null>[] = [];

    selected.forEach((id) => {
      console.log(`checking application ${id} for updates`);
      const application = newApplications[id];
      let changed = false;
      if (values.agentId !== undefined && values.agentId !== application.agentId) {
        application.agentId = values.agentId;
        console.log("setting agent");
        changed = true;
      }

      if (values.seasonId !== undefined && values.workOrderId !== undefined) {
        if (
          values.seasonId !== application.seasonId ||
          values.workOrderId !== application.workOrderId
        ) {
          application.seasonId = values.seasonId;
          application.workOrderId = values.workOrderId;
          console.log("setting work order && season");
          changed = true;
        }
      } else {
        if (values.seasonId !== undefined && values.seasonId !== application.seasonId) {
          if (
            application.workOrderId &&
            orders[application.workOrderId].seasonId === values.seasonId
          ) {
            console.log("setting season");
            application.seasonId = values.seasonId;
            changed = true;
          }
        }

        if (values.workOrderId !== undefined && values.workOrderId !== application.workOrderId) {
          application.workOrderId = values.workOrderId;
          console.log("setting work order");
          const wo = orders[values.workOrderId];
          application.seasonId = wo.seasonId;
          changed = true;
        }
      }

      if (values.status !== undefined && values.status !== application.status) {
        application.status = values.status;
        console.log("setting status");
        changed = true;
      }

      if (
        values.virtualPresentationId !== undefined &&
        !application.virtualPresentation?.virtualPresentationId
      ) {
        if (values.virtualPresentationId !== "") {
          console.log("setting virtual presentation");
          application.virtualPresentation = {
            virtualPresentationId: values.virtualPresentationId,
            status: "new",
            link_id: uuidv4(),
            expiry_date: values.expiry_date,
          };
          if (values.sendPresentationLink) {
            vpMails.push(id);
          }
        } else {
          console.log("deleting virtual presentation");
          application.virtualPresentation = undefined;
        }

        changed = true;
      }

      if (changed) {
        applChanges.push(storeApplication(currentUser?.appUser, { id, application }, false));
        newApplications[id] = application;
      }
    });

    if (values.virtualPresentationId && values.sendPresentationLink) {
      doSendInvitations(vpMails, "virtual_presentations");
    }

    if (applChanges.length > 0) {
      await Promise.all(applChanges);
      await fetchApplications(selectedOrder, selectedSeason);
    }
  };

  const doSendInvitations = async (appl: string[], template: string) => {
    if (appl.length > 0) {
      const mailDataFormFunction = httpsCallable(functions, "api/mailDataForm");
      const data: any = {
        template: template,
        applications: appl,
      };

      if (!currentUser?.appUser?.sponsorId) {
        data.sponsorId = applications[appl[0]].sponsorId;
      }

      try {
        const result = (await mailDataFormFunction(data)) as MailDataFormResult;
        console.log("result: ");
        console.log(result);
        if (result.data.status === "OK") {
          alert("Emails sent successfully");
        } else {
          alert("Error sending emails: " + result.data.message);
        }
      } catch (error) {
        console.error("Error calling cloud function:", error);
      }
    }
  };

  const handleSendInvitations = async (template: string) => {
    const appl: string[] = [];
    selected.forEach((id) => {
      appl.push(id);
      if (template === "data_form") {
        createQuestionnaireLink({ id, application: applications[id] });
      }
    });

    doSendInvitations(appl, template);
  };

  const handleSendSelectedInvition = async (applicationId: string, template: string) => {
    if (!applicationId) {
      console.log("no application selected");
      return;
    }
    const appl: string[] = [];
    appl.push(applicationId);
    doSendInvitations(appl, template);
  };

  const handleSendLink = async (applicationId: string) => {
    if (!applicationId) return;

    const application = applications[applicationId];
    createQuestionnaireLink({ id: applicationId, application });
    const baseUrl = BASE_URL + "/questionnaire/";
    setCurrentLink(baseUrl + application.questionnaireId);
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    //    setEditMode(false);
    setOpenAlert(false);
  };

  const handleAssignVirtualPresentation = async (applicationId: string) => {
    setEditAppl({ id: applicationId, application: { ...applications[applicationId] } });
    setOpenVPDialog(true);
  };

  const handleCloseVPDialog = () => {
    setOpenVPDialog(false);
  };

  const handleToggleShowopenOrders = () => {
    console.log("toggle show opened campaigns");
    const newSO = !showOpenOrders;
    if (newSO && selectedOrder && orders[selectedOrder]) {
      if (orders[selectedOrder].status === "closed") {
        const fOrders = sortAndFilterOrders(orders, newSO, selectedSeason);
        if (fOrders.length > 0) {
          setSelectedOrder(fOrders[0][0]);
        } else setSelectedOrder("all_orders");
        setSelectedApplication(null);
      }
    }
    setShowOpenOrders(newSO);
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOrder(value);
    setSelectedApplication(null);
    if (value !== "all_orders") setSelected([]);
  };

  const onSeasonInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("season changed", event.target.value);
    const value = event.target.value;
    setSelectedSeason(value);
    const fOrders = sortAndFilterOrders(orders, showOpenOrders, value);
    if (fOrders.length > 0) {
      setSelectedOrder(fOrders[0][0]);
    } else setSelectedOrder("all_orders");
    setSelectedApplication(null);
    setSelected([]);
  };

  const handleSelectApplication = async (applicationId: string) => {
    if (applicationId) {
      // const docRef = doc(db, "applications", applicationId);
      // const applicationSnapshot = await getDoc(docRef);
      // if (applicationSnapshot) {
      //   loadedApplication = {
      //     id: applicationSnapshot.id,
      //     application: getApplicationFromFB(applicationSnapshot.data() as VisaApplicationFB),
      //   };
      // }
      console.log("selecting application: " + applicationId);
      const loadedApplication = {
        id: applicationId,
        application: { ...applications[applicationId] },
      };
      setSelectedApplication(loadedApplication);
    }
  };

  const handleEditApplication = async (applicationId: string) => {
    handleAddNewApplication(applicationId, true);
  };

  const handleShowHistory = async (documentId: string) => {
    setHistoryDoc(documentId);
    setOpenHistory(true);
  };

  // const handleEditApplicationStatus = async (applicationId: string) => {
  //   if (!selectedApplication || applicationId !== selectedApplication?.id) {
  //     await handleSelectApplication(applicationId);
  //   }
  //   setEditAppl({ id: applicationId, application: { ...applications[applicationId] } });
  //   setOpenStatus(true);
  // };

  // const handleStatusChange = (status: string) => {
  //   console.log(status); // Here you can call the function to change the status
  //   // setStatusApplicationId("");
  //   // setStatusAnchorEl(null);
  //   setOpenStatus(false); // Close the menu after selection
  // };

  const handleStatusChange = async (applicationId: string, status: string) => {
    if (!applicationId) return;

    const newApplications = { ...applications };
    const application = newApplications[applicationId];
    application.status = status;
    await updateApplication(currentUser?.appUser, applicationId, { status: application.status });

    if (selectedApplication && selectedApplication.id === applicationId) {
      setSelectedApplication({ id: applicationId, application });
    }

    setApplications(newApplications);
  };

  const handleSaveApplication = async (applicationDoc: ApplicationDoc) => {
    console.log("storing application: " + applicationDoc.id);
    const storedApplication = await storeApplication(currentUser?.appUser, applicationDoc, true);
    if (selectedApplication && selectedApplication.id === applicationDoc.id) {
      console.log("updating the selected application");
      setSelectedApplication({ ...applicationDoc });
    }

    //check if the applicant is loaded in the list
    if (!applicants[applicationDoc.application.applicantId]) {
      console.log("applicant not loaded, loading...");
      const newApplicants = { ...applicants };
      const applicant = await loadApplicant(applicationDoc.application.applicantId);
      if (applicant?.applicant) {
        newApplicants[applicationDoc.application.applicantId] = applicant?.applicant;
        setApplicants(newApplicants);
      } else {
        console.log("Applicant not found: " + applicationDoc.application.applicantId);
        alert("Applicant not found!");
        return;
      }
    }

    const newApplications = _.cloneDeep(applications);
    if (storedApplication) {
      newApplications[storedApplication.id] = _.cloneDeep(applicationDoc.application);
      setApplications(newApplications);
    }
  };

  const handleSaveApplicationVP = async (applicationDoc: ApplicationDoc) => {
    console.log("storing application: " + applicationDoc.id);
    await storeApplication(currentUser?.appUser, applicationDoc, false);
    const newApplications = _.cloneDeep(applications);
    if (applicationDoc.id) {
      newApplications[applicationDoc.id] = _.cloneDeep(applicationDoc.application);
      setApplications(newApplications);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map(([id, application]) => id);
      setSelected(newSelected);
    } else setSelected([]);
  };

  const handleCheckRow = (event: React.ChangeEvent<HTMLInputElement>, applicationId: string) => {
    // setChecked(event.target.checked);
    const selectedIndex = selected.indexOf(applicationId);
    const newSelected: string[] = [...selected];

    if (selectedIndex === -1) {
      newSelected.push(applicationId);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.includes(id);

  // const handleCallFunction = async () => {
  //   //    const assignCosNumbers = httpsCallable(functions, "api/assignCosNumbers");
  //   // const result = await assignCosNumbers({ cos: cosNumbers });
  //   const appl = await loadApplications(currentUser?.appUser);
  //   const wOrders = await loadWorkOrders(currentUser?.appUser);
  //   let count = 0;
  //   Object.keys(appl).forEach((id: string) => {
  //     const application = appl[id] as VisaApplication;
  //     let changed = false;
  //     if (application.sponsorId === undefined) {
  //       console.log(id + " has no sponsor");
  //     }
  //     if (changed) {
  //       storeApplication(currentUser?.appUser, { id, application }, false);
  //       count++;
  //     }
  //   });
  //   alert("Changed " + count + " applications");
  // };

  const handleDelete = async (applicationId: string | null) => {
    if (!applicationId) return;

    if (window.confirm("Are you sure you want to delete this application?")) {
      await deleteApplications([applicationId]);
      //      await deleteDoc(doc(db, "applications", applicationId));
      const newApplications = { ...applications };
      delete newApplications[applicationId];
      if (selectedApplication && selectedApplication.id === applicationId) {
        setSelectedApplication(null);
      }
      if (selected.indexOf(applicationId) >= 0) {
        const newSelected = [...selected];
        newSelected.splice(selected.indexOf(applicationId), 1);
        setSelected(newSelected);
      }

      setApplications(newApplications);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getApplicantFullName = (applicantId: string) => {
    return applicantFullName(applicants[applicantId]);
  };

  const getOrderName = (workOrderId: string) => {
    return orders[workOrderId]?.name || "";
  };

  const displayVPStatusIcon = (vpInstance: VirtualPresentationInstance | null | undefined) => {
    if (!vpInstance || !vpInstance.virtualPresentationId) {
      return <></>;
    }

    if (
      vpInstance.expiry_date &&
      vpInstance.status !== "completed" &&
      isDateInThePast(vpInstance.expiry_date)
    ) {
      return (
        <Tooltip title="virtual presentation expired">
          <CancelPresentation sx={{ color: "red" }} />
        </Tooltip>
      );
    }

    switch (vpInstance.status) {
      case "new":
        return (
          <Tooltip title="virtual presentation assigned">
            <PresentToAll />
          </Tooltip>
        );
      case "started":
        return (
          <Tooltip title="virtual presentation in progress">
            <Slideshow />
          </Tooltip>
        );
      case "completed":
        return (
          <Tooltip title="virtual presentation completed">
            <CheckIcon sx={{ color: "green" }} />
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  const displayApplicationDetails = (selectedApplication: ApplicationDoc | null) => {
    if (!selectedApplication) {
      return;
    }

    const applicant = applicants[selectedApplication.application?.applicantId];

    return (
      <Card>
        <CardHeader
          title={
            <>
              {`${applicantFullName(applicant)} (${applicant.email})`}
              <Tooltip title="Go to Applicant">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    navigate(`/applicants/${selectedApplication.application?.applicantId}`);
                  }}
                  sx={{ color: "white", paddingLeft: "1rem" }}
                >
                  <InputIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Sponsor:
              </Typography>
              <Typography>
                {selectedApplication.application.sponsorId
                  ? sponsors[selectedApplication.application.sponsorId].name
                  : "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Agent:
              </Typography>
              <Typography>
                {selectedApplication.application.agentId
                  ? agents[selectedApplication.application.agentId].company
                  : "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Visa Type:
              </Typography>
              <Typography>{selectedApplication.application.type.label}</Typography>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                GWF number:
              </Typography>
              <Typography>
                {selectedApplication.application.gwf ? selectedApplication.application.gwf : "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={2}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                CoS number:
              </Typography>
              <Typography>
                {selectedApplication.application.cos ? selectedApplication.application.cos : "n/a"}
              </Typography>
            </Grid>
            {/* 2nd line -------------------------- */}
            <Grid item xs={6} lg={3}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Survey expiry date:
              </Typography>
              <Typography>
                {selectedApplication.application.dataForm_expiry_date
                  ? selectedApplication.application.dataForm_expiry_date
                  : "n/a"}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Survey completed:
              </Typography>
              <Typography>
                {selectedApplication.application.dataForm_completed
                  ? selectedApplication.application.dataForm_completed
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Privacy policy accepted:
              </Typography>
              <Typography>
                {selectedApplication.application.privacy_policy_accepted ? "yes" : "no"}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={4}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Sufficient funds:
              </Typography>
              <Typography>
                {selectedApplication.application.sufficient_funds ? "yes" : "no"}
              </Typography>
            </Grid>
            {/* 3rd line -------------------------- */}
            <Grid item xs={12} lg={3}>
              <Typography variant="button" sx={{ fontWeight: "bold" }}>
                Virtual Presentation:
              </Typography>
              <Typography>
                {selectedApplication.application.virtualPresentation?.virtualPresentationId
                  ? presentations[
                      selectedApplication.application.virtualPresentation?.virtualPresentationId
                    ].name
                  : "Not Assigned"}
              </Typography>
            </Grid>
            {selectedApplication.application.virtualPresentation?.virtualPresentationId && (
              <>
                <Grid item xs={6} lg={2}>
                  <Typography variant="button" sx={{ fontWeight: "bold" }}>
                    Presentation expiry date:
                  </Typography>
                  <Typography>
                    {selectedApplication.application.virtualPresentation.expiry_date
                      ? selectedApplication.application.virtualPresentation.expiry_date
                      : "n/a"}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Typography variant="button" sx={{ fontWeight: "bold" }}>
                    Presentation completed:
                  </Typography>
                  <Typography>
                    {selectedApplication.application.virtualPresentation.completedDate
                      ? selectedApplication.application.virtualPresentation.completedDate
                      : "-"}
                  </Typography>
                </Grid>
              </>
            )}
            {!selectedApplication.application.virtualPresentation?.virtualPresentationId && (
              <Grid item xs={6} lg={9}></Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  /* ------------ Search functions -------------- */
  function descendingComparator(
    a: [string, VisaApplication],
    b: [string, VisaApplication],
    orderBy: string
  ): number {
    const application1 = a[1];
    const application2 = b[1];
    if (!orderBy) {
      return 0;
    }

    // console.log("a1: ");
    // console.log(application1);

    // console.log("a2: ");
    // console.log(application2);

    // console.log("orderBy: " + orderBy);

    if (application1 && application2) {
      let res: number = 0;
      switch (orderBy) {
        case "dates":
          res = application1.start_date && !application2.start_date ? 1 : 0;
          if (res === 0) {
            res = !application1.start_date && application2.start_date ? -1 : 0;
          }
          if (res === 0 && application1.start_date && application2.start_date) {
            res =
              new Date(application2.start_date).getTime() -
              new Date(application1.start_date).getTime();
          }

          // end date
          if (res === 0) {
            res = application1.end_date && !application2.end_date ? 1 : 0;
          }

          if (res === 0) {
            res = !application1.end_date && application2.end_date ? -1 : 0;
          }

          if (res === 0 && application1.end_date && application2.end_date) {
            res =
              new Date(application2.end_date).getTime() - new Date(application1.end_date).getTime();
          }
          return res;

        case "name":
          const name1 = applicantFullName(applicants[application1.applicantId]);
          const name2 = applicantFullName(applicants[application2.applicantId]);

          res = name2.localeCompare(name1);
          return res;

        case "employerId":
          if (application1.employerId && application2.employerId) {
            const e1 = employers[application1.employerId].name;
            const e2 = employers[application2.employerId].name;

            res = e2.localeCompare(e1);
          } else if (application1.employerId && !application2.employerId) {
            res = 1;
          } else if (!application1.employerId && application2.employerId) {
            res = -1;
          } else res = 0;
          return res;

        case "workOrderId":
          if (application1.workOrderId && application2.workOrderId) {
            const e1 = orders[application1.workOrderId]?.name || "";
            const e2 = orders[application2.workOrderId]?.name || "";

            res = e2.localeCompare(e1);
          } else if (application1.workOrderId && !application2.workOrderId) {
            res = 1;
          } else if (!application1.workOrderId && application2.workOrderId) {
            res = -1;
          } else res = 0;
          return res;

        case "sponsorId":
          if (application1.sponsorId && application2.sponsorId) {
            const e1 = sponsors[application1.sponsorId].name;
            const e2 = sponsors[application2.sponsorId].name;
            res = e2.localeCompare(e1);
            return res;
          } else if (application1.sponsorId && !application2.sponsorId) {
            return -1;
          } else if (!application1.sponsorId && application2.sponsorId) {
            return 1;
          } else return 0;

        case "finished":
          res = application1.dataForm_expiry_date && !application2.dataForm_expiry_date ? 1 : 0;
          if (res === 0) {
            res = !application1.dataForm_expiry_date && application2.dataForm_expiry_date ? -1 : 0;
          }
          if (res === 0 && application1.dataForm_expiry_date && application2.dataForm_expiry_date) {
            res =
              new Date(application2.dataForm_expiry_date).getTime() -
              new Date(application1.dataForm_expiry_date).getTime();
          }
          return res;

        case "privacy_policy_accepted":
          if (application1.privacy_policy_accepted && !application2.privacy_policy_accepted) {
            res = 1;
          } else if (
            !application1.privacy_policy_accepted &&
            application2.privacy_policy_accepted
          ) {
            res = -1;
          } else res = 0;

          return res;

        case "status":
          return application2.status.localeCompare(application1.status);
        default:
          break;
      }
    } else {
      if (application1 && !application2) {
        return -1;
      } else if (!application1 && application2) {
        return 1;
      }
    }
    return 0;
  }

  const filteredApplications = useMemo(
    () =>
      Object.entries(applications).filter(([applicationId, application]) => {
        const applicant = applicants[application.applicantId];
        try {
          const sval = searchValue.toLocaleLowerCase();
          const order = application.workOrderId ? orders[application.workOrderId] : null;

          const agent = application.agentId ? agents[application.agentId] : null;

          return (
            searchValue.length < 3 ||
            applicantFullName(applicant).toLocaleLowerCase().includes(sval) ||
            (applicant && applicant.email?.toLocaleLowerCase().includes(sval)) ||
            (applicant &&
              displayStatusLabel(application.status).toLocaleLowerCase().includes(sval)) ||
            (application.sponsorId &&
              sponsors[application.sponsorId].name.toLowerCase().includes(sval)) ||
            (order &&
              order.name &&
              (order.name.toLowerCase().includes(sval) ||
                order.start_date?.includes(sval) ||
                order.end_date?.includes(sval))) ||
            (agent && agent.company.toLowerCase().includes(sval)) ||
            (application.cos && application.cos.toLowerCase().includes(sval)) ||
            (application.gwf && application.gwf.toLowerCase().includes(sval))
            //     ||
            // //
            // (application.employerId &&
            //   employers[application.employerId].name.toLowerCase().includes(sval)) ||
            // application.start_date?.includes(sval) ||
            // application.end_date?.includes(sval)
          );
        } catch (err) {
          if (err instanceof Error) {
            console.error("filter exception:" + err.message);
          } else {
            console.error("filter exception:", err);
          }
          return false;
        }
      }),
    [searchValue, applications, sponsors, orders, applicants]
  );

  const visibleRows = useMemo(
    () =>
      filteredApplications
        .slice()
        .sort(getComparator(order, orderBy as keyof VisaApplication, descendingComparator)),
    [order, orderBy, filteredApplications]
  );

  const sortAndFilterOrders = (fOrders: WorkOrdersDict, showOpen: boolean, season: string) => {
    const filteredOrders = Object.entries(fOrders)
      .filter(([orderId, order]) => {
        if (season && order.seasonId !== season) {
          return false;
        }

        return !showOpen || order.status !== "closed";
      })
      .sort(([idA, orderA], [idB, orderB]) => {
        if (orderA.name < orderB.name) {
          return -1;
        }
        if (orderA.name > orderB.name) {
          return 1;
        }
        return 0;
      });

    return filteredOrders;
  };

  const sortedWorkOrders = useMemo(
    () => sortAndFilterOrders(orders, showOpenOrders, selectedSeason),
    [orders, showOpenOrders, selectedSeason]
  );

  const sortedSeasons = useMemo(
    () => Object.entries(seasons).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
    [seasons]
  );

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <TitledPage title="Applications">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            onClick={() => handleAddNewApplication("")}
          >
            Add New Application
          </Button>

          <DocumentHistoryDialog
            open={openHistory}
            onClose={() => setOpenHistory(false)}
            documentId={historyDoc}
          />

          <ApplicationDialog
            open={open}
            onClose={handleClose}
            onSave={handleSaveApplication}
            application={editAppl}
            applicants={applicants}
          />
          <ApplicationVirtualPresentationDialog
            open={openVPDilog}
            onClose={handleCloseVPDialog}
            onSave={handleSaveApplicationVP}
            onSendLink={() =>
              handleSendSelectedInvition(selectedApplication?.id || "", "virtual_presentations")
            }
            application={editAppl}
          />
          {/* <Tooltip
            title={
              selected.length === 0
                ? "Convert all approved candidates to applicants"
                : "Convert selected approved candidates to applicants"
            }
            title="Send an invitation mail to the selected applicant"
          >
            <IconButton
              color="primary"
              edge={"end"}
              sx={{ marginTop: "8px", marginBottom: "4px" }}
              onClick={handleSendInvitations}
            >
              <AssignmentInd />
            </IconButton>
          </Tooltip> */}

          {/* Application Status */}
          {/* <Menu
            id="status-menu"
            anchorEl={statusAnchorEl}
            keepMounted
            open={Boolean(statusAnchorEl)}
            onClose={handleCloseStatus}
          >
            {APPLICATION_STATUS.map((status) => (
              <Tooltip title={status.description}>
                <MenuItem key={status.key} onClick={() => handleStatusChange(status.key)}>
                  {status.label}
                </MenuItem>
              </Tooltip>
            ))}
          </Menu> */}

          <ApplicationStatusDialog
            open={openStatus}
            onClose={handleCloseStatus}
            onSave={handleSaveApplication}
            application={editAppl}
          />

          {/* Dataform Link */}
          <SimpleLinkDialog
            label={currentLink}
            open={openAlert}
            showQRCode={false}
            showSendLink={true}
            onSendLink={() =>
              handleSendSelectedInvition(selectedApplication?.id || "", "data_form")
            }
            title={"Applicant data survey"}
            onClose={handleAlertClose}
          />
          <Tooltip title="Export applications to CSV">
            <span>
              <IconButton
                color="primary"
                edge={"end"}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                onClick={handleExport}
              >
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>

          {/* Batch Updates */}
          <ApplicationsBatchUpdateDialog
            open={openBatchUpdate}
            onClose={() => setOpenBatchUpdate(false)}
            onBatchUpdate={onBatchUpdate}
            onSendDataFormLink={handleSendInvitations}
            initialValues={batchUpdateValues}
            numberOfSelected={selected.length}
            agents={agents}
            workOrders={orders}
            seasons={seasons}
            virtualPresentations={presentations}
          />
          <Tooltip title="Batch edit applications">
            <span>
              <IconButton
                color="primary"
                edge={"end"}
                disabled={selected.length === 0}
                sx={{ marginTop: "8px", marginBottom: "4px" }}
                onClick={handleBatchUpdate}
              >
                <EditNoteIcon />
              </IconButton>
            </span>
          </Tooltip>
          {hasRole(currentUser?.appUser, ["admin"]) && (
            <Tooltip title="History">
              <span>
                <IconButton
                  color="primary"
                  edge={"end"}
                  sx={{ marginTop: "8px", marginBottom: "4px" }}
                  disabled={!selectedApplication}
                  onClick={() => handleShowHistory(selectedApplication?.id || "")}
                >
                  <InfoIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {/* <Button
            variant="outlined"
            color="primary"
            sx={{ marginTop: "8px", marginBottom: "4px" }}
            onClick={handleExport}
          >
            Export applications
          </Button> */}
          {/* <Button variant="outlined" color="primary" onClick={handleCallFunction}>
            Test functions
          </Button> */}

          {/* ----------------- Season ---------------- */}
          <TextField
            name={"seasonId"}
            id={"seasonId"}
            label={"Season"}
            select
            onChange={onSeasonInputComboChange}
            value={selectedSeason}
            margin={"dense"}
            size="small"
            sx={{ width: "150px" }}
          >
            <MenuItem value="no_season" key="no_season">
              <Typography fontStyle={"italic"}>no season</Typography>
            </MenuItem>
            {sortedSeasons.map(([seasonId, season]) => {
              return (
                <MenuItem value={seasonId} key={seasonId}>
                  {season.name}
                </MenuItem>
              );
            })}
          </TextField>

          {/* ----------------- Work Orders ---------------- */}
          <TextField
            name={"order"}
            id={"order"}
            label={"Work Order"}
            size="small"
            select
            onChange={onInputComboChange}
            value={selectedOrder || "all_orders"}
            margin={"dense"}
            sx={{ width: "500px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title={showOpenOrders ? "Show all orders" : "Show open orders"}>
                    <IconButton onClick={handleToggleShowopenOrders}>
                      {showOpenOrders ? <ToggleOn sx={{ color: "green" }} /> : <ToggleOff />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="all_orders" key="all_orders">
              <Typography fontStyle={"italic"}>Show all applications</Typography>
            </MenuItem>
            <MenuItem value="no_order" key="no_order">
              <Typography fontStyle={"italic"}>Show applications without an order</Typography>
            </MenuItem>
            {Object.values(sortedWorkOrders).map(([orderId, order]) => {
              return (
                <MenuItem
                  value={orderId}
                  key={orderId}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {order.name}
                  {getWorkOrderStatusChip(order.status)}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValueChange}
            sx={{ minWidth: "250px", marginTop: "8px", marginBottom: "4px" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "1rem",
            height: "calc(100vh - 250px)",
          }}
        >
          <Box sx={{ marginBottom: "24px", minHeight: "400px", height: "66%" }}>
            <TableVirtuoso
              style={{ height: "100%" }}
              data={visibleRows}
              context={{
                selectedId: selectedApplication?.id || "",
                handleSelect: handleSelectApplication,
              }}
              components={VirtuosoTableComponents}
              fixedHeaderContent={() => (
                <EnhancedTableHeadContents
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={visibleRows.length}
                  selectable={true}
                  headCells={headCells}
                />
              )}
              fixedFooterContent={() => (
                <TableRow>
                  <TableCell align="right" colSpan={headCells.length + 1}>
                    {visibleRows.length + " "} Applications
                  </TableCell>
                </TableRow>
              )}
              itemContent={(index, [applicationId, application]) => {
                return (
                  <ApplicationRow
                    key={applicationId}
                    index={index}
                    applicationId={applicationId}
                    application={application}
                    handleStatusChange={handleStatusChange}
                    handleEditApplication={handleEditApplication}
                    handleSendLink={handleSendLink}
                    handleAssignVirtualPresentation={handleAssignVirtualPresentation}
                    handleDelete={handleDelete}
                    isSelected={isSelected}
                    getApplicantFullName={getApplicantFullName}
                    getOrderName={getOrderName}
                    displayVPStatusIcon={displayVPStatusIcon}
                    displayStatusDescription={displayStatusDescription}
                    displayStatusLabel={displayStatusLabel}
                    handleCheckRow={handleCheckRow}
                  />
                );
              }}
            />
          </Box>
          {selectedApplication && (
            <Box sx={{ height: "33%" }}>{displayApplicationDetails(selectedApplication)}</Box>
          )}
        </Box>
      </Box>
    </TitledPage>
  );
};

export default ApplicationsPage;
