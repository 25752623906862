import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  CampaignsDict,
  Candidate,
  CandidateDoc,
  CandidateErrors,
  CandidateStatus,
  EMPTY_CANDIDATE,
  EMPTY_CANDIDATE_DOC,
  EMPTY_CANDIDATE_ERRORS,
} from "../../types/recruiting";
import { isEMailValid, isNameValid, isPhoneValid } from "../../utils/utils";
import db from "../../types/firebase";
import { RadioYesNo } from "../../components/radio-button-yes-no";
import { loadCampaigns } from "../../data-functions/recruiting_api";
import { AuthContext } from "../../components/auth-provider";

interface AddCandidateDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (candidate: CandidateDoc) => void;
  candidate: CandidateDoc | null;
  campaign: string;
}

const AddCandidateDialog = ({
  open,
  onClose,
  onSave,
  candidate: initialCandidate,
  campaign: initialCampaign,
}: AddCandidateDialogProps) => {
  const [candidateDoc, setCandidate] = useState<CandidateDoc>({
    ...EMPTY_CANDIDATE_DOC,
  });
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});

  const [errors, setErrors] = useState<CandidateErrors>({ ...EMPTY_CANDIDATE_ERRORS });

  const { currentUser } = useContext(AuthContext)!;

  const fetchCampaigns = async () => {
    const campaignsDict = await loadCampaigns(currentUser?.appUser);

    setCampaigns(campaignsDict);
  };

  useEffect(() => {
    fetchCampaigns();

    if (initialCandidate) {
      setCandidate({ ...initialCandidate });
    } else {
      const newCandidate = { ...EMPTY_CANDIDATE_DOC };

      newCandidate.candidate.campaignId = initialCampaign;

      setCandidate({ ...EMPTY_CANDIDATE_DOC });
    }
  }, [initialCandidate, initialCampaign]);

  const validateInput = async () => {
    let newErrors = { ...EMPTY_CANDIDATE_ERRORS };
    let formIsValid = true;

    if (!(candidateDoc.candidate.name && isNameValid(candidateDoc.candidate.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(candidateDoc.candidate.surname && isNameValid(candidateDoc.candidate.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!isPhoneValid(candidateDoc.candidate.phone)) {
      newErrors.phone = "Phone number is required and should only contain numbers!";
      formIsValid = false;
    }

    if (!(candidateDoc.candidate.email && isEMailValid(candidateDoc.candidate.email))) {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    } else if (!candidateDoc.id) {
      const candidateQuery = query(
        collection(db, "candidates"),
        where("email", "==", candidateDoc.candidate.email)
      );
      const candidateSnapshot = await getDocs(candidateQuery);

      if (!candidateSnapshot.empty) {
        newErrors.email = "Candidate with this email address already exists";
        formIsValid = false;
      }
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSave = async () => {
    const isValid = await validateInput();
    if (!isValid) {
      return;
    }

    onSave(candidateDoc);

    setCandidate({ id: "", candidate: { ...EMPTY_CANDIDATE } });
    handleClose();
  };

  const handleClose = () => {
    setErrors({ ...EMPTY_CANDIDATE_ERRORS });
    onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    let newCandidate: Candidate;

    if (key === "has_passport") {
      newCandidate = { ...candidateDoc.candidate, has_passport: event.target.value === "true" };
    } else {
      newCandidate = {
        ...candidateDoc.candidate,
        [event.target.name]: event.target.value,
      };
    }

    setCandidate({ ...candidateDoc, candidate: newCandidate });
  };

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    const eid = (event.target as Element).id;
    console.log("eid: " + eid);

    if (eid && candidateDoc && candidateDoc.candidate) {
      const qid = eid.split("_")[1] as CandidateStatus;
      const newCandidate: Candidate = {
        ...candidateDoc.candidate,
        status: qid,
      };

      setCandidate({ ...candidateDoc, candidate: newCandidate });
    }
  };

  const getCampaign = (candidateDoc: CandidateDoc) => {
    if (candidateDoc && candidateDoc.candidate && candidateDoc.candidate.campaignId) {
      const cId = candidateDoc.candidate.campaignId;
      return campaigns[cId] ? cId : "";
    } else {
      return "";
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"md"}>
      <DialogTitle>{candidateDoc.id ? "Candidate Details" : "Add New Candidate"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the details of the new candidate.</DialogContentText>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.name}
              helperText={errors.name}
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              required
              value={(candidateDoc && candidateDoc.candidate.name) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.surname}
              helperText={errors.surname}
              margin="dense"
              name="surname"
              label="Surname"
              type="text"
              fullWidth
              required
              value={(candidateDoc && candidateDoc.candidate.surname) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.email}
              helperText={errors.email}
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              required
              value={(candidateDoc && candidateDoc.candidate.email) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.phone}
              helperText={errors.phone}
              margin="dense"
              name="phone"
              label="Phone Number"
              type="tel"
              fullWidth
              required
              value={(candidateDoc && candidateDoc.candidate.phone) || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RadioYesNo
              id="has_passport"
              value={candidateDoc && !!candidateDoc.candidate.has_passport}
              label="Do you have a travel passport?"
              onChange={handleInputChange}
              translated={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!errors.passportId}
              helperText={errors.passportId}
              margin="dense"
              name="passportId"
              label="Passport ID"
              type="text"
              fullWidth
              value={(candidateDoc && candidateDoc.candidate.passportId) || ""}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            {initialCampaign && (
              <Typography variant="body1">
                Campaign: {(campaigns && campaigns[initialCampaign].name) || "No campaig"}
              </Typography>
            )}
            {!initialCampaign && (
              <TextField
                name={"campaignId"}
                label={"Campaign"}
                select
                onChange={handleInputChange}
                value={getCampaign(candidateDoc)}
                required
                margin={"dense"}
                fullWidth
              >
                {Object.entries(campaigns).map(([campaignId, campaign]) => {
                  return (
                    <MenuItem value={campaignId} key={campaignId}>
                      {campaign.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Grid>
          {candidateDoc && candidateDoc.id && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>Status</FormLabel>
                <ToggleButtonGroup
                  id="qid_0017"
                  value={(candidateDoc && candidateDoc.candidate.status) || "new"}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                  sx={{
                    marginTop: "8px",
                  }}
                >
                  <ToggleButton
                    id="status_new"
                    value="new"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.primary.dark,
                        },
                      },
                    }}
                  >
                    New
                  </ToggleButton>
                  <ToggleButton
                    id="status_verified"
                    value="verified"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.primary.dark,
                        },
                      },
                    }}
                  >
                    Verified
                  </ToggleButton>
                  <ToggleButton
                    id="status_interviewed"
                    value="interviewed"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: (theme) => theme.palette.primary.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.primary.dark,
                        },
                      },
                    }}
                  >
                    Interviewed
                  </ToggleButton>
                  <ToggleButton
                    id="status_approved"
                    value="approved"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.success.main,
                        color: (theme) => theme.palette.success.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.success.dark,
                        },
                      },
                    }}
                  >
                    Approved
                  </ToggleButton>
                  <ToggleButton
                    id="status_rejected"
                    value="rejected"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.error.main,
                        color: (theme) => theme.palette.error.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.error.dark,
                        },
                      },
                    }}
                  >
                    Rejected
                  </ToggleButton>
                  <ToggleButton
                    id="status_DnI"
                    value="DnI"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: (theme) => theme.palette.error.main,
                        color: (theme) => theme.palette.error.contrastText,
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.error.dark,
                        },
                      },
                    }}
                  >
                    Do not Invite
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCandidateDialog;
