import { Timestamp } from "firebase/firestore";
import {
  VirtualPresentationInstance,
  VirtualPresentationInstanceFB,
} from "./virtual-presentations";
import { TenantRecord, TrackedRecord } from "./commons";

export type ApplicationsDict = Record<string, VisaApplication>;
export type ApplicationDoc = { id: string; application: VisaApplication };

export interface VisaApplicationType {
  key: string;
  label: string;
}

export interface VisaApplicationStatus {
  key: string;
  label: string;
  description?: string;
}

export const APPLICATION_TYPES: VisaApplicationType[] = [
  { key: "seasonal", label: "Seasonal Worker" },
];

export const APPLICATION_STATUS: VisaApplicationStatus[] = [
  { key: "new", label: "New", description: "New application" },
  { key: "survey_link", label: "Link sent", description: "Survey link sent to applicant" },
  {
    key: "survey_finished",
    label: "Survey finished",
    description: "This applicant can be assigned a placement",
  },

  {
    key: "cos_ready",
    label: "Ready for CoS",
    description: "A Cos number can be requested for this application",
  },
  {
    key: "visa_ready",
    label: "Ready for Visa",
    description: "The visa application can be started for this application",
  },
  { key: "visa_open", label: "Visa in process", description: "The visa application is in process" },
  {
    key: "visa_completed",
    label: "Visa completed",
    description: "The visa application is completed",
  },
  {
    key: "visa_payment",
    label: "Payment link sent",
    description: "The visa payment link has been sent",
  },
  { key: "visa_paid", label: "Visa paid", description: "The visa has been paid" },
  {
    key: "visa_tls",
    label: "TLS appointment",
    description: "A TLS account has been created and the candidate has an inteview appointment",
  },
  {
    key: "visa_granted",
    label: "Visa granted",
    description: "The visa has been granted and can be picked up",
  },
  {
    key: "visa_rejected",
    label: "Visa rejected",
    description: "The applicant has been rejected a visa",
  },
  {
    key: "cancelled",
    label: "Cancelled",
    description: "The application has been cancelled by the candidate",
  },
  {
    key: "request_transfer",
    label: "Transfer requested",
    description: "The worker has requested a transfer to another employer",
  },
  {
    key: "transferred",
    label: "Transferred",
    description: "The worker has been transferred to another employer",
  },
  {
    key: "dismissed",
    label: "Dismissed",
    description: "The application has been cancelled by the employer",
  },
];

export const EMPTY_VISA_APPLICATION: VisaApplication = {
  applicantId: "",
  sponsorId: "",
  seasonId: "",
  type: { ...APPLICATION_TYPES[0] },
  workOrderId: "",
  status: "new",
};

export interface VisaApplication extends TrackedRecord, TenantRecord {
  applicantId: string;
  type: VisaApplicationType;
  status: string;
  cos?: string;
  gwf?: string;
  employerId?: string;
  seasonId: string;
  questionnaireId?: string;
  workOrderId: string;
  start_date?: string;
  end_date?: string;
  dataForm_expiry_date?: string;
  dataForm_completed?: string;
  privacy_policy_accepted?: boolean;
  sufficient_funds?: boolean;
  visa_application_link?: string;
  virtualPresentation?: VirtualPresentationInstance;
}

export interface VisaApplicationFB extends TrackedRecord, TenantRecord {
  applicantId: string;
  type: VisaApplicationType;
  status: string;
  cos?: string;
  gwf?: string;
  employerId?: string;
  seasonId: string;
  questionnaireId?: string;
  workOrderId: string;
  start_date?: Timestamp;
  end_date?: Timestamp;
  dataForm_expiry_date?: Timestamp;
  dataForm_completed?: Timestamp;
  privacy_policy_accepted?: boolean;
  sufficient_funds?: boolean;
  visa_application_link?: string;
  virtualPresentation?: VirtualPresentationInstanceFB;
}
