import { Timestamp } from "firebase/firestore";
import { TenantRecord, TrackedRecord } from "./commons";

export type VirtualPresentationStatus = "enabled" | "disabled";
export type VirtualPresentationInstanceStatus = "new" | "started" | "completed";

export interface VirtualPresentationSlide {
  id: string;
  url: string;
  storageRef: string;
  localImage?: string;
  localExtension?: string;
  thumbnail?: string;
  thumbnailRef?: string;
  localThumbnail?: string;
  description: string;
}

export interface VirtualPresentation extends TrackedRecord, TenantRecord {
  name: string;
  baseUrl: string;
  status: VirtualPresentationStatus;
  slides: VirtualPresentationSlide[];
}

export interface VirtualPresentationInstance {
  virtualPresentationId?: string;
  link_id?: string;
  status: VirtualPresentationInstanceStatus;
  expiry_date?: string;
  completedDate?: string;
  lastSlideViewed?: number;
}

export interface VirtualPresentationInstanceFB {
  virtualPresentationId?: string;
  link_id?: string;

  status: VirtualPresentationInstanceStatus;
  expiry_date?: Timestamp;
  completedDate?: Timestamp;
  lastSlideViewed?: number;
}

export const EMPTY_VP_INSTANCE: VirtualPresentationInstance = {
  virtualPresentationId: "",
  status: "new",
};

export const EMPTY_VIRTUAL_PRESENTATION: VirtualPresentation = {
  name: "",
  sponsorId: "",
  baseUrl: "",
  status: "enabled",
  slides: [],
};

export type VirtualPresentationsDict = Record<string, VirtualPresentation>;
export type VirtualPresentationDoc = { id: string; presentation: VirtualPresentation };
