import { FormControl, FormLabel } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import { FormattedMessage } from "react-intl";

type CustomTextFieldProps = {
  translated?: boolean;
  externalLabel?: boolean;
} & TextFieldProps;

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  children,
  name,
  label,
  helperText,
  margin,
  translated = true,
  externalLabel = false,
  InputLabelProps,
  ...restProps
}) => {
  const labelElement = translated ? (
    <FormattedMessage id={name} defaultMessage={label?.toString()} />
  ) : (
    label
  );
  const helperElement = translated ? (
    <FormattedMessage id={name + "_error"} defaultMessage={helperText?.toString()} />
  ) : (
    helperText
  );

  const textFieldElement = () => (
    <TextField
      label={externalLabel ? "" : labelElement}
      helperText={helperText && helperElement}
      name={name}
      margin={margin || "dense"}
      InputLabelProps={InputLabelProps || { shrink: true }}
      {...restProps}
    >
      {children}
    </TextField>
  );

  return (
    <React.Fragment>
      {externalLabel ? (
        <FormControl fullWidth>
          <FormLabel id={"textfield-" + name}>{labelElement}</FormLabel>
          {textFieldElement()}
        </FormControl>
      ) : (
        textFieldElement()
      )}
    </React.Fragment>
  );
};
