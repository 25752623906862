import React, { FC, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Container, Box, Typography, Button, Card } from "@mui/material";
import { Stepper, Step, StepButton } from "@mui/material";

import { PageGeneralInformation } from "./steps/pageGeneralInformation";
import { PageFamilyInformation } from "./steps/pageFamilyInformation";
import { PageTravelInformation } from "./steps/pageTravelInformation";
import { PageAdditionalInformation } from "./steps/pageAdditionalInformation";
import { PageWelcome } from "./steps/pageWelcome";

import { ApplicationDoc } from "../../types/visa-application";

import { LanguageSwitcher } from "../../components/language-switcher";

import { ApplicantDoc, ApplicantErrors, EMPTY_APPLICANT } from "../../types/applicants";
import { Country } from "../../types/commons";

import {
  validateAdditionalInformation,
  validateApplicant,
  validateFamily,
  validateFinalProvisions,
  validateTravel,
} from "./validation";

import { SupportedLanguages } from "../../languages/languages";
import {
  handleAddressChange,
  handleCountryChange,
  handleInputChange,
} from "../../utils/inputs/common-inputs";
import {
  handleInputChildrenChange,
  handlePersonCountryChange,
  handlePersonInputChange,
} from "../../utils/inputs/persons-inputs";
import {
  handleInputTravelChange,
  handleTravelDestinationChange,
} from "../../utils/inputs/travel-inputs";
import { setApplicantDefaults } from "../../utils/applicant-utils";
import {
  handleInputRelativesChange,
  handleRelativeNationalityChange,
} from "../../utils/inputs/relatives-inputs";
// import { useMapsLibrary } from "@vis.gl/react-google-maps";
//import { findCountry } from "../../utils/utils";
import { storeApplication } from "../../data-functions/applications-api";
import { storeApplicant } from "../../data-functions/applicants-api";
import { PageFinalProvisions } from "./steps/pageFinalProvisions";

interface Props {
  applicant: ApplicantDoc;
  application: ApplicationDoc;
  defaultLanguage: SupportedLanguages;
  changeLanguage: (language: SupportedLanguages) => void;
}

const steps = ["Welcome", "General", "Family", "Travels", "Miscelanous", "Finish"];

const STEP_WARNUNG = 0;
const STEP_GENERAL = 1;
const STEP_FAMILY = 2;
const STEP_TRAVEL = 3;
const STEP_ADDITIONAL = 4;
const STEP_FINAL_PROVISIONS = 5;

export const ApplicantDetails: FC<Props> = ({
  application: initialApplication,
  applicant: initialApplicant,
  defaultLanguage,
  changeLanguage,
}) => {
  //geocode library
  //  const geocodeLibrary = useMapsLibrary("geocoding");

  const [applicantDoc, setApplicant] = useState<ApplicantDoc>({
    id: "",
    applicant: { ...EMPTY_APPLICANT },
  });
  const [applicationDoc, setApplication] = useState<ApplicationDoc | null>(null);
  const [language, setLanguage] = useState<SupportedLanguages>("en");

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [errors, setErrors] = useState<ApplicantErrors>({
    name: "",
    surname: "",
  });
  const [disableChildren, setDisableChildren] = useState(true);
  const [disableParents, setDisableParents] = useState(true);
  const [disableRelatives, setDisableRelatives] = useState(true);
  const [disableTravelledToUK, setDisableTravelledToUK] = useState(true);
  const [disableTravelledAbroad, setDisableTravelledAbroad] = useState(true);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [sufficientFundsConfirmed, setSufficientFundsConfirmed] = useState(false);

  //  const [geoservice, setGeoService] = useState<google.maps.Geocoder | null>(null);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (
      initialApplicant &&
      initialApplication &&
      initialApplicant.id === initialApplication.application.applicantId
    ) {
      const applicantDoc = { ...initialApplicant };
      applicantDoc.applicant = setApplicantDefaults(applicantDoc.applicant);
      setDisableChildren(!applicantDoc.applicant.has_children);
      setDisableParents(!!applicantDoc.applicant.parents_unknown);
      setDisableRelatives(!applicantDoc.applicant.has_relatives_in_UK);
      setDisableTravelledToUK(!applicantDoc.applicant.has_travelled_to_UK);
      setDisableTravelledAbroad(!applicantDoc.applicant.has_travelled_abroad);

      setApplicant(applicantDoc);
      setApplication(initialApplication);
      console.log("application data: ");
      console.log(initialApplication);
      console.log("default language: " + defaultLanguage);
      console.log("preferred language: " + applicantDoc.applicant.preferredLanguage);

      if (
        applicantDoc.applicant.preferredLanguage &&
        applicantDoc.applicant.preferredLanguage !== defaultLanguage
      ) {
        setLanguage(applicantDoc.applicant.preferredLanguage as SupportedLanguages);
        changeLanguage(applicantDoc.applicant.preferredLanguage as SupportedLanguages);
        console.log("language: " + applicantDoc.applicant.preferredLanguage);
      } else {
        setLanguage(defaultLanguage);
        console.log("default language");
      }
    }
  }, [initialApplicant, initialApplication]);

  // create geocode once available
  // useEffect(() => {
  //   console.log("setting the geocode service");
  //   console.log(geocodeLibrary);
  //   if (geocodeLibrary) {
  //     const lib = new geocodeLibrary.Geocoder();
  //     console.log("lib: " + lib);
  //     setGeoService(lib);
  //   }
  // }, [geocodeLibrary]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (!applicationDoc) {
      return;
    }
    if (validateInput()) {
      if (!(activeStep === STEP_WARNUNG)) {
        handleSave();
      }

      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);

      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  //   const handleReset = () => {
  //     setActiveStep(0);
  //     setCompleted({});
  //   };

  const validateInput = () => {
    let validation;
    switch (activeStep) {
      case STEP_GENERAL:
        validation = validateApplicant(applicantDoc.applicant);
        break;

      case STEP_FAMILY:
        validation = validateFamily(applicantDoc.applicant);
        break;

      case STEP_TRAVEL:
        validation = validateTravel(applicantDoc.applicant);
        break;

      case STEP_ADDITIONAL:
        validation = validateAdditionalInformation(applicantDoc.applicant);

        break;

      case STEP_FINAL_PROVISIONS:
        validation = validateFinalProvisions(applicantDoc.applicant);
        if (!privacyPolicyAccepted) {
          validation.isValid = false;
          validation.errors.privacy_policy_accepted =
            "Please, accept the privacy policy, so that we can continue with your application!";
        }
        if (!sufficientFundsConfirmed) {
          validation.isValid = false;
          validation.errors.sufficient_funds =
            "Please, confirm that you have sufficient funds to cover the costs of the application!";
        }
        break;

      default:
        validation = { isValid: true, errors: { name: "", surname: "" } };
        break;
    }
    console.log("--------validation result ------------");
    console.log(validation);

    setErrors(validation.errors);
    return validation.isValid;
  };

  const handleSave = async () => {
    const newApplicant = { ...applicantDoc };

    // Clean unnecessary data before saving
    if (!newApplicant.applicant.has_children) {
      newApplicant.applicant.children = [];
    }
    if (!newApplicant.applicant.has_travelled_to_UK) {
      newApplicant.applicant.travel_details_UK = [];
    }
    if (!newApplicant.applicant.has_travelled_abroad) {
      newApplicant.applicant.travel_details_abroad = [];
    }
    if (!newApplicant.applicant.has_relatives_in_UK) {
      newApplicant.applicant.relatives_UK = [];
    }

    console.log("applicant data: ");
    console.log(newApplicant);

    await storeApplicant(null, newApplicant, false);

    console.log("saving application");
    console.log(applicationDoc);

    if (applicationDoc) {
      const newApplication = { ...applicationDoc };

      if (isLastStep() && privacyPolicyAccepted) {
        newApplication.application.dataForm_completed = new Date().toISOString();
        newApplication.application.status = "survey_finished";
        console.log("setting completed date: " + applicationDoc.application.dataForm_completed);
      }
      newApplication.application.privacy_policy_accepted ||= privacyPolicyAccepted;
      newApplication.application.sufficient_funds ||= sufficientFundsConfirmed;

      await storeApplication(null, newApplication, false);
      setApplication(newApplication);
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    // console.log("key: " + key + "; value: " + event.target.value);
    // console.log(event);

    if (key === "privacy_policy") {
      setPrivacyPolicyAccepted(event.target.checked);
    }

    if (key === "sufficient_funds") {
      setSufficientFundsConfirmed(event.target.checked);
    }

    const newApplicant = handleInputChange(applicantDoc.applicant, event);

    if (newApplicant) {
      setDisableParents(!!newApplicant.parents_unknown);
      setApplicant({ ...applicantDoc, applicant: newApplicant });
      //      console.log(newApplicant);
    }
  };

  const onPersonInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const key = event.target.name;
    //    console.log("key: " + key + "; value: " + event.target.value);

    const newApplicant = handlePersonInputChange(applicantDoc.applicant, event);

    if (newApplicant) {
      setApplicant({ ...applicantDoc, applicant: newApplicant });
      //      console.log(newApplicant);
    }
  };

  const onInputChildrenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApplicant = handleInputChildrenChange(applicantDoc.applicant, event);

    if (newApplicant) {
      setDisableChildren(!newApplicant.has_children);
      setApplicant({ ...applicantDoc, applicant: newApplicant });
      // console.log(newApplicant);
    }
  };

  const onInputRelativesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApplicant = handleInputRelativesChange(applicantDoc.applicant, event);
    if (newApplicant) {
      setDisableRelatives(!newApplicant.has_relatives_in_UK);
      setApplicant({ ...applicantDoc, applicant: newApplicant });
      //      console.log(newApplicant.relatives_UK);
    }
  };

  const onRelativeNationalityChange = (event: any, newValue: Country | null) => {
    const newApplicant = handleRelativeNationalityChange(applicantDoc.applicant, event, newValue);
    if (newApplicant) {
      //      console.log(newApplicant.relatives_UK);
      setApplicant({ ...applicantDoc, applicant: newApplicant });
    }
  };

  const onInputTravelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApplicant = handleInputTravelChange(applicantDoc.applicant, event);
    if (newApplicant) {
      setDisableTravelledToUK(!newApplicant.has_travelled_to_UK);
      setDisableTravelledAbroad(!newApplicant.has_travelled_abroad);
      setApplicant({ ...applicantDoc, applicant: newApplicant });
      // console.log(newApplicant);
    }
  };

  // const handleSelectChange = (event: SelectChangeEvent) => {
  //   applicant &&
  //     setApplicant({ ...applicant, [event.target.name]: event.target.value });
  // };

  const onTravelDestinationChange = (event: any, newValue: Country | null) => {
    const newApplicant = handleTravelDestinationChange(applicantDoc.applicant, event, newValue);
    if (newApplicant) {
      //      console.log(newApplicant.travel_details_abroad);
      setApplicant({ ...applicantDoc, applicant: newApplicant });
    }
  };

  const onDeleteTravel = (type: string, index: number) => {
    const newApplicant = { ...applicantDoc.applicant };

    switch (type.toLowerCase()) {
      case "uk":
        if (newApplicant.travel_details_UK && newApplicant.travel_details_UK.length > index) {
          newApplicant.travel_details_UK.splice(index, 1);
        }
        break;
      case "abroad":
        if (
          newApplicant.travel_details_abroad &&
          newApplicant.travel_details_abroad.length > index
        ) {
          newApplicant.travel_details_abroad.splice(index, 1);
        }
        break;
      default:
        return;
    }

    setApplicant({ ...applicantDoc, applicant: newApplicant });
  };

  // const handleAddressLanguage = async (address: string) => {
  //   // const result = await geoservice?.geocode({ address: address, region: "en" });
  //   // return result;
  //   return null;
  // };

  // const parseGeocode = (geocode: google.maps.GeocoderResult): Address => {
  //   const address: Address = { ...EMPTY_ADDRESS };
  //   console.log("received geocode for parsing: ");
  //   console.log(geocode);

  //   if (geocode) {
  //     console.log("empty address: ");
  //     console.log(address);
  //     geocode.address_components.forEach((comp: google.maps.GeocoderAddressComponent, ix) => {
  //       console.log(`component ${ix}: `);
  //       console.log(comp);
  //       if (comp.types.includes("street_number")) {
  //         address.street = address.street + " " + comp.short_name;
  //       } else if (comp.types.includes("route")) {
  //         address.street = comp.short_name + address.street;
  //       } else if (comp.types.includes("administrative_area_level_1")) {
  //         address.region = comp.short_name;
  //       } else if (comp.types.includes("locality")) {
  //         address.city = comp.long_name;
  //       } else if (comp.types.includes("country")) {
  //         const ctr = findCountry(comp.short_name);
  //         if (ctr) address.country = { ...ctr };
  //       }

  //       console.log(address);
  //     });
  //   }
  //   return address;
  // };

  const onAddressChange = async (event: any, newValue: string | null) => {
    // console.log("new value");
    // console.log(newValue);

    let newApplicant = null;

    if (newValue && event.target.id === "full_address") {
      console.log("changing full address: " + newValue);
      //      if (containsNonLatinCharacters(newValue)) {
      // const result = await handleAddressLanguage(newValue);
      // console.log("geocodig: ");
      // console.log(result?.results[0]);
      // let address;

      // if (result && result.results[0]) {
      //   address = parseGeocode(result.results[0]);
      //   console.log("translated adress: ");
      //   console.log(address);
      //   console.log("residency_province " + address.region || "");

      //   newApplicant = { ...applicantDoc.applicant };
      //   newApplicant.residency_street = address.street || "";
      //   newApplicant.residency_city = address.city || "";
      //   newApplicant.residency_province = address.region || "";
      //   newApplicant.residency_country = address.country;
      // }

      //      }
    } else {
      newApplicant = handleAddressChange(applicantDoc.applicant, event, newValue);
    }
    if (newApplicant) {
      setApplicant({ ...applicantDoc, applicant: newApplicant });
    }
    //    console.log(newApplicant);
  };

  const onCountryChange = (event: any, newValue: Country | null) => {
    // console.log("new country");
    // console.log(event);
    // console.log("new value");
    // console.log(newValue);
    const newApplicant = handleCountryChange(applicantDoc.applicant, event, newValue);
    if (newApplicant) {
      setApplicant({ ...applicantDoc, applicant: newApplicant });
    }
  };

  const onPersonCountryChange = (event: any, newValue: Country | null) => {
    const newApplicant = handlePersonCountryChange(applicantDoc.applicant, event, newValue);
    if (newApplicant) {
      setApplicant({ ...applicantDoc, applicant: newApplicant });
    }
  };

  const onLanguageChange = (language: SupportedLanguages) => {
    if (applicantDoc && applicantDoc.applicant) {
      if (applicantDoc.applicant.preferredLanguage !== language) {
        console.log("Updating language");
        const newApplicant = { ...applicantDoc };
        newApplicant.applicant.preferredLanguage = language;
        setApplicant(newApplicant);
      }
    }
    changeLanguage(language);
  };

  if (!applicantDoc) {
    return <p>Loading...</p>;
  }

  const getStepContent = (activeStep: number) => {
    switch (activeStep) {
      case STEP_WARNUNG:
        return <PageWelcome application={applicationDoc?.application} />;

      // ---------- General Information Page -------------------
      case STEP_GENERAL:
        return (
          <PageGeneralInformation
            applicant={applicantDoc.applicant}
            errors={errors}
            onInputChange={onInputChange}
            onCountryChange={onCountryChange}
            onAddressChange={onAddressChange}
          />
        );

      // ---------- Family Information Page -------------------
      case STEP_FAMILY:
        return (
          <PageFamilyInformation
            applicant={applicantDoc.applicant}
            errors={errors}
            disableChildren={disableChildren}
            disableRelatives={disableRelatives}
            disableParents={disableParents}
            onInputChange={onInputChange}
            onCountryChange={onCountryChange}
            onPersonInputChange={onPersonInputChange}
            onPersonCountryChange={onPersonCountryChange}
            onInputChildrenChange={onInputChildrenChange}
            onInputRelativesChange={onInputRelativesChange}
            onRelativeNationalityChange={onRelativeNationalityChange}
          />
        );

      // ---------- Travel Information Page -------------------
      case STEP_TRAVEL:
        return (
          <PageTravelInformation
            applicant={applicantDoc.applicant}
            errors={errors}
            disableTravelAbroad={disableTravelledAbroad}
            disableTravelUK={disableTravelledToUK}
            onInputChange={onInputChange}
            onCountryChange={onCountryChange}
            onTravelCountryChange={onTravelDestinationChange}
            onInputTravelChange={onInputTravelChange}
            onDeleteTravel={onDeleteTravel}
          />
        );

      // ---------- Additional Information Page -------------------
      case STEP_ADDITIONAL:
        return (
          <PageAdditionalInformation
            applicant={applicantDoc.applicant}
            errors={errors}
            onInputChange={onInputChange}
          />
        );

      case STEP_FINAL_PROVISIONS:
        return (
          <PageFinalProvisions
            applicant={applicantDoc.applicant}
            errors={errors}
            onInputChange={onInputChange}
          />
        );
      default:
        break;
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <LanguageSwitcher initialLanguage={language} onLanguageChange={onLanguageChange} />
      {smallScreen ? (
        <Typography variant="h5" sx={{ textAlign: "center", marginTop: "1rem" }}>
          <FormattedMessage id={"step_" + activeStep} defaultMessage={steps[activeStep]} />
        </Typography>
      ) : (
        <Stepper sx={{ marginTop: "2rem" }} activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                <FormattedMessage id={"step_" + index} defaultMessage={label} />
              </StepButton>
            </Step>
          ))}
        </Stepper>
      )}
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Card sx={{ marginTop: "1rem", padding: { xs: "0", md: "1rem" } }}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                <FormattedMessage
                  id="step_text_finished"
                  defaultMessage="All steps completed - you're finished!"
                />
              </Typography>
            </Card>
            {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box> */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Card sx={{ marginTop: "1rem", padding: "1rem" }}>{getStepContent(activeStep)}</Card>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                <FormattedMessage id="button_back" defaultMessage="Back" />
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
                {activeStep === totalSteps() - 1 ? (
                  <FormattedMessage id="button_finish" defaultMessage="Finish" />
                ) : (
                  <FormattedMessage id="button_next" defaultMessage="Next" />
                )}
              </Button>
              {/* {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block" }}
                    >
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button onClick={handleComplete}>
                    </Button>
                  ))} */}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Container>
  );
};
