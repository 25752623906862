import { Grid, Divider, Box, Link } from "@mui/material";
import { Typography, FormControlLabel, Checkbox, FormControl, FormHelperText } from "@mui/material";
import { CircleOutlined, Circle } from "@mui/icons-material";

import { Applicant, ApplicantErrors } from "../../../types/applicants";
import { FormattedMessage } from "react-intl";

const hint_text =
  "Why do we need your personal data and how are we going to use it? " +
  "By submitting this form you agree that IBC Student Exchange is an " +
  "administrator of personal data and as such will process it in accordance with GDPR " +
  "- EU General Data Protection Regulation 2016/679. " +
  "The collection of your personal data is necessary in connection with your application " +
  "for our programmes. Please read more about our Privacy Policy:";
const privacy_policy = "I agree to the Terms and Conditions of the Privacy Policy of IBC.";

const sufficient_funds =
  "I declare that I have sufficient funds to cover the TB test, visa and the travel costs to and back from the UK.";

interface PageFinalProvisionsProps {
  applicant: Applicant;
  errors: ApplicantErrors;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const PageFinalProvisions: React.FC<PageFinalProvisionsProps> = ({
  applicant,
  errors,
  onInputChange,
}) => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_sufficient_funds" defaultMessage="Sufficient Funds" />
        </Divider>
      </Grid>

      <Grid item xs={12}>
        <FormControl error={!!errors.sufficient_funds}>
          <FormControlLabel
            control={
              <Checkbox
                name="sufficient_funds"
                size="medium"
                checkedIcon={<Circle />}
                icon={<CircleOutlined />}
                onChange={onInputChange}
              />
            }
            label={<FormattedMessage id="sufficient_funds" defaultMessage={sufficient_funds} />}
          />
          <FormHelperText>
            {errors.sufficient_funds && (
              <FormattedMessage
                id="privacy_policy_error"
                defaultMessage={errors.sufficient_funds}
              />
            )}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left">
          <FormattedMessage id="divider_data_privacy" defaultMessage="Data Privacy Information" />
        </Divider>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Typography>
            <FormattedMessage id="privacy_hint" defaultMessage={hint_text} />

            <Link href="https://ibcbulgaria.com/privacypolicy/" target="_blank" rel="noreferrer">
              https://ibcbulgaria.com/privacypolicy/
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={!!errors.privacy_policy_accepted}>
          <FormControlLabel
            control={
              <Checkbox
                name="privacy_policy"
                size="medium"
                checkedIcon={<Circle />}
                icon={<CircleOutlined />}
                onChange={onInputChange}
              />
            }
            label={<FormattedMessage id="privacy_policy" defaultMessage={privacy_policy} />}
          />
          <FormHelperText>
            {errors.privacy_policy_accepted && (
              <FormattedMessage
                id="privacy_policy_error"
                defaultMessage={errors.privacy_policy_accepted}
              />
            )}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};
