import {
  CollectionReference,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { AppUser } from "../types/users";
import db from "../types/firebase";
import { ApplicationDoc, ApplicationsDict, VisaApplicationFB } from "../types/visa-application";
import { getApplicationFromFB, setApplicationToFB } from "../utils/application-utils";

export const loadApplications = async (
  user: AppUser | null | undefined,
  filter: Record<string, string> = {},
  cLimit: number = 0
) => {
  let applicationsRef: Query | CollectionReference;
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  const agentId = user && user.agentId ? user.agentId : "";

  if (agentId !== "") {
    applicationsRef = query(collection(db, "applications"), where("agentId", "==", agentId));
  } else if (sponsorId !== "") {
    applicationsRef = query(collection(db, "applications"), where("sponsorId", "==", sponsorId));
  } else {
    applicationsRef = collection(db, "applications");
  }

  Object.keys(filter).forEach((key) => {
    applicationsRef = query(applicationsRef, where(key, "==", filter[key]));
  });
  if (cLimit > 0) applicationsRef = query(applicationsRef, limit(cLimit));

  const applicationsSnapshot = await getDocs(applicationsRef);
  const applicationsDict: ApplicationsDict = {};

  console.log("loaded " + applicationsSnapshot.size + " applications");

  applicationsSnapshot.forEach((doc) => {
    const application = getApplicationFromFB(doc.data() as VisaApplicationFB);
    applicationsDict[doc.id] = application;
  });

  return applicationsDict;
};

export const storeApplication = async (
  user: AppUser | null | undefined,
  applicationDoc: ApplicationDoc | null,
  addIfNew: boolean
) => {
  if (!(applicationDoc && applicationDoc.application)) {
    return null;
  }

  let applicationSnapshot = null;
  if (!applicationDoc.id && addIfNew) {
    applicationDoc.application.createdAt = new Date().toISOString();
    applicationDoc.application.createdBy = user ? user.id : "anonymous";
  } else {
    applicationDoc.application.updatedAt = new Date().toISOString();
    applicationDoc.application.updatedBy = user ? user.id : "anonymous";
  }
  const appl = setApplicationToFB(applicationDoc.application);

  if (applicationDoc.id) {
    const docRef = doc(db, "applications", applicationDoc.id);
    applicationSnapshot = await getDoc(docRef);
    // Update the document
    applicationSnapshot && (await setDoc(docRef, appl));
    console.log("updated application:", applicationDoc.id);
  } else if (addIfNew) {
    applicationDoc.id = (await addDoc(collection(db, "applications"), appl)).id;
    console.log("created application:", applicationDoc.id);
  } else {
    return null;
  }

  return { ...applicationDoc };
};

export const updateApplication = async (
  user: AppUser | null | undefined,
  applicationId: string,
  applicationData: any
) => {
  if (!applicationId) return;

  console.log("updating application:", applicationId);
  console.log("applicationData:", applicationData);

  if (!applicationData.updatedAt) applicationData.updatedAt = new Date().toISOString();
  if (!applicationData.updatedBy) applicationData.updatedBy = user?.id || "anonymous";
  const docRef = doc(db, "applications", applicationId);
  const applicationSnapshot = await getDoc(docRef);

  if (applicationSnapshot.exists()) {
    await updateDoc(docRef, applicationData);
    console.log("Document successfully updated!");
  } else {
    console.log("No document to update!");
  }
};

export const deleteApplications = async (applications: string[]) => {
  if (!applications || applications.length === 0) return;

  const applicationPromises = applications.map((id) => deleteDoc(doc(db, "applications", id)));
  await Promise.all(applicationPromises);
};
