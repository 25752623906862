import { VirtualPresentationSlide } from "../types/virtual-presentations";
import React, { MouseEventHandler, useEffect, useRef } from "react";
import { DragDropProvider, useDroppable } from "@dnd-kit/react";
import { useSortable } from "@dnd-kit/react/sortable";
import { CollisionPriority } from "@dnd-kit/abstract";
import default_slide_thumbnail from "../ressources/ibc_icon.png";

export type DraggableListItemProps = {
  item: VirtualPresentationSlide;
  index: number;
  selected?: boolean;
  thumbnailList: string;
  onSelectItem?: (item: VirtualPresentationSlide) => void;
  onItemClick?: MouseEventHandler<HTMLDivElement>;
};

export type DraggableListProps = {
  droppableId: string;
  items: VirtualPresentationSlide[];
  selectedItem: string | null;
  onItemClick?: MouseEventHandler<HTMLDivElement>;
  onSelectItem?: (item: VirtualPresentationSlide) => void;
  onDragEnd: (event: any) => void;
};

export type DroppableProps = {
  id: string;
  children: React.ReactNode;
};

export const DraggableSlideItem = React.forwardRef<HTMLDivElement, DraggableListItemProps>(
  (
    { item, index, thumbnailList, selected, onItemClick, onSelectItem }: DraggableListItemProps,
    forwardedRef
  ) => {
    // Use forwardedRef for the forwarded ref
    const { ref: sortableRef } = useSortable({
      // Rename the destructured ref to avoid conflict
      id: item.id,
      index,
      type: "slide",
      accept: "slide",
      group: thumbnailList,
      data: item,
    });

    const onClick = (event: React.MouseEvent<HTMLDivElement>, item: VirtualPresentationSlide) => {
      onItemClick?.(event);
      onSelectItem?.(item);
    };

    const getItemStyle = (): React.CSSProperties => ({
      padding: "4px",
      paddingBottom: "0px",
      marginBottom: "8px",
      height: "125px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      border: "1px solid",
      borderColor: selected ? "grey" : "lightgray",
      backgroundColor: selected ? "lightgray" : "white",
    });

    return (
      <div
        ref={sortableRef} // Use the sortable ref here
        onClick={(event) => onClick(event, item)}
      >
        {/* this div is for passing the forward ref and the scrolling functionality */}
        <div ref={forwardedRef}>
          <div style={getItemStyle()}>
            <img
              src={item.localThumbnail || item.thumbnail || default_slide_thumbnail}
              alt="Sponsor"
              style={{
                maxWidth: "100%",
                maxHeight: "100px",
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                width: "100%",
                minHeight: "0.9rem",
                fontSize: "0.75rem",
                padding: "4px",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                justifyContent: "left",
              }}
            >
              {item.description}
            </div>
          </div>
        </div>{" "}
      </div>
    );
  }
);

export const DroppableSlideArea = ({ id, children }: DroppableProps) => {
  const { ref } = useDroppable({
    id,
    type: "column",
    accept: "slide",
    collisionPriority: CollisionPriority.Low,
  });

  return (
    <div className="SlideArea" ref={ref}>
      {children}
    </div>
  );
};

export const DraggableList = React.memo(
  ({
    droppableId,
    items,
    selectedItem,
    onDragEnd,
    onItemClick,
    onSelectItem,
  }: DraggableListProps) => {
    const lastItemRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [items.length]); // Dependency on items.length ensures this runs when an item is added

    const onDragOver = (event: any) => {
      const { source, target } = event.operation;
      if (!target || target.type === "slide") {
        return;
      }
      console.log("onDragOver....");
      console.log("source: ", source);
      console.log("target: ", target);
    };

    return (
      <DragDropProvider onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <div className={droppableId + "-root"}>
          <DroppableSlideArea id={droppableId}>
            {items.map((item, index) => (
              <DraggableSlideItem
                key={item.id}
                item={item}
                index={index}
                selected={selectedItem !== null && selectedItem === item.id ? true : false}
                thumbnailList={droppableId}
                onItemClick={onItemClick}
                onSelectItem={onSelectItem}
                ref={selectedItem !== null && selectedItem === item.id ? lastItemRef : null} // Assign ref to the last item
              />
            ))}
          </DroppableSlideArea>
        </div>
      </DragDropProvider>
    );
  }
);
