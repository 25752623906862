import { Applicant } from "../types/applicants";
import { Candidate } from "../types/recruiting";
import { WorkOrder } from "../types/orders";
import { getCandidateStatusText } from "./candidate-utils";

const HOPS_DELIMITER = ",";

const escapeString = (str: string | undefined, delimiter: string) => {
  if (!str) {
    return "";
  }

  if (str.indexOf(delimiter) > -1) {
    return `"${str}"`;
  }
  return str;
};

const translateEnglishLevelToHops = (level: string) => {
  switch (level) {
    case "Advanced":
      return "Good";
    case "Intermediate":
      return "Moderate";
    case "Beginner":
      return "Poor";
    case "None":
      return "None";
    default:
      return "";
  }
};

const formatDate = (date: string | undefined) => {
  if (!date) {
    return "";
  }
  // format the date as dd/mm/yyyy
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

const HOPS_COS_HEADERS = [
  "FirstName",
  "Surname",
  "Email",
  "Date of Birth",
  "Gender",
  "Nationality",
  "Address1",
  "Address2",
  "Town",
  "County",
  "Postcode",
  "Country",
  "Phone",
  "NiNo/SSN",
  "RTW Reference Number",
  "RTW Expiry Date",
  "Health Info",
  "Passport or National ID no.",
  "English Level",
  "skills",
  "EmergencyContact Name",
  "Address1 (Emergency Contact)",
  "Address2 (Emergency Contact)",
  "Town (Emergency Contact)",
  "County (Emergency Contact)",
  "PostCode (Emergency Contact)",
  "Country (Emergency Contact)",
  "Email (Emergency Contact)",
  "Phone Day (Emergency Contact)",
  "Phone Evening (Emergency Contact)",
  "Relation (Emergency Contact)",
  "Preffered Language",
  "Bank Account Name",
  "Bank Code",
  "Bank Account No.",
  "Bank Name",
  "Passport Issue Date",
  "Passport Expiry Date",
  "Employment Order",
];

export const addHOPSCosApplicants = (applications: any[]) => {
  if (!applications) {
    return "";
  }

  const csvData =
    "# " +
    [
      HOPS_COS_HEADERS.join(HOPS_DELIMITER),
      ...applications.map((applicationItem) => {
        const applicant = applicationItem.applicant as Applicant;
        const workOrder = applicationItem.workOrder as WorkOrder;
        if (!applicant) {
          return new Array(HOPS_COS_HEADERS.length).fill("").join(HOPS_DELIMITER);
        }

        return [
          applicant.name,
          applicant.surname,
          applicant.email,
          formatDate(applicant.birth_date),
          applicant.gender,
          applicant.nationality?.code || "",
          escapeString(applicant.residency_street, HOPS_DELIMITER),
          "",
          escapeString(applicant.residency_city, HOPS_DELIMITER),
          escapeString(applicant.residency_province, HOPS_DELIMITER),
          escapeString(applicant.residency_zip, HOPS_DELIMITER),
          applicant.residency_country?.code || "",
          escapeString(applicant.phone, HOPS_DELIMITER),
          "",
          "",
          "",
          "",
          escapeString(applicant.passport?.id, HOPS_DELIMITER),
          translateEnglishLevelToHops(applicant.english_level || ""),
          "",
          escapeString(applicant.immergency_contact?.name, HOPS_DELIMITER),
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          escapeString(applicant.immergency_contact?.phone, HOPS_DELIMITER),
          "",
          applicant.immergency_contact?.relation || "",
          "",
          "",
          "",
          "",
          "",
          formatDate(applicant.passport?.issue_date),
          formatDate(applicant.passport?.expiry_date),
          (workOrder && workOrder.name) || "",
        ].join(HOPS_DELIMITER);
      }),
    ].join("\n");

  return csvData;
};

const CANDIDATES_DELIMITER = ";";
const CANDIDATES_HEADERS = [
  "Status",
  "Name",
  "Surname",
  "Phone",
  "Email",
  "Has Passport?",
  "Passport number",
  "Travelled abroad?",
  "Preferred start date",
];

export const generateCandidatesCSV = (candidates: Candidate[]) => {
  const header = CANDIDATES_HEADERS.join(CANDIDATES_DELIMITER);
  if (!candidates) {
    return [header].join("\n");
  }
  const csvData = [
    header,
    ...candidates.map((candidate: Candidate) => {
      const cLine = [
        getCandidateStatusText(candidate.status),
        candidate.name,
        candidate.surname,
        candidate.phone,
        candidate.email,
        candidate.has_passport ? "yes" : "no",
        candidate.passportId,
        candidate.questions && candidate.questions["qid_0008"]
          ? candidate.questions["qid_0008"]
          : "n/a",
        candidate.questions && candidate.questions["qid_0019"]
          ? candidate.questions["qid_0019"]
          : "n/a",
      ].join(CANDIDATES_DELIMITER);

      return cLine;
    }),
  ].join("\n");

  return csvData;
};
