const ru = {
  // ------------ General variables ------------
  radio_yes: "Да",
  radio_no: "Нет",
  radio_undefined: "Пожалуйста, выберите хотя бы один вариант!",
  travel_reason_work: "Работа",
  travel_reason_tourism: "Туризм",
  travel_reason_study: "Обучение",
  travel_reason_transit: "Транзит",

  // ------------ Relatives relationship ------------
  relative_reason_none: "выберите одну опцию",
  relative_reason_adoped_child: "Усыновленный ребенок",
  relative_reason_brother: "Брат",
  relative_reason_brother_in_law: "Шурин",
  relative_reason_child: "Ребенок",
  relative_reason_child_spouce: "Невестка / Зять",
  relative_reason_daughter: "Дочь",
  relative_reason_daughter_in_law: "Невестка",
  relative_reason_father: "Отец",
  relative_reason_father_in_law: "Свекор",
  relative_reason_grandchild: "Внук",
  relative_reason_grandparent: "Бабушка / Дедушка",
  relative_reason_husband: "Муж",
  relative_reason_mother: "Мать",
  relative_reason_mother_in_law: "Свекровь",
  relative_reason_parent: "Родитель",
  relative_reason_sister: "Сестра",
  relative_reason_sister_in_law: "Золовка",
  relative_reason_son: "Сын",
  relative_reason_son_in_law: "Зять",
  relative_reason_step_child: "Приемный ребенок",
  relative_reason_step_parents: "Приемные родители",
  relative_reason_step_sibling: "Приемный брат / сестра",
  relative_reason_unmarried_partner: "Неженатый партнер",
  relative_reason_wife: "Жена",

  // ------------ Relatives Type of Stay ------------
  typeofstay_none: "выберите одну опцию",
  typeofstay_temporary: "Временная виза",
  typeofstay_permanently: "С постоянным пребыванием",
  typeofstay_other: "Нет визы и нет постоянного пребывания",
  typeofstay_cannotcontact: "Не могу связаться с ними",

  // ------------ Steps ------------
  step_0: "Начало",
  step_1: "Общая информация",
  step_2: "Семейное положение",
  step_3: "Поездки",
  step_4: "Дополнительная информация",
  step_text_finished: "Все данные заполнены - вы готовы!",
  button_next: "Следующая",
  button_back: "Предыдущая",
  button_finish: "Завершить",

  // ------------ Dividers ------------
  divider_general_information: "Общая информация",
  divider_birth_information: "Информация о рождении",
  divider_passport_information: "Паспортная информация",
  divider_residency_information: "Информация о месте жительства",
  divider_phone_information: "Телефон",
  //
  divider_marital_status: "Семейное положение",
  divider_children: "Дети",
  divider_partner_details: "Данные о партнере",
  divider_parents: "Данные о родителях",
  divider_mother_details: "Данные о матери",
  divider_father_details: "Данные об отце",
  divider_emergency_contact: "Контакт в случае чрезвычайной ситуации",
  divider_additional_information: "Дополнительная информация",
  divider_relatives_UK: "Родственники в Великобритании",
  //
  divider_uk_status: "Информация о Великобритании",
  divider_travel_UK: "Поездки в Великобританию",
  divider_travel_abroad: "Заграничные поездки",
  divider_final_provisions: "Заключительные положения",
  divider_data_privacy: "Защита личных данных",
  divider_sufficient_funds: "Достаточные средства",

  // ------------- Welcome Page -------------
  welcome_title: "Работа в Великобритании с визой",
  welcome_hint1:
    "Пожалуйста, укажите ответы на вопросы латинскими буквами. Вопросы, отмеченные звездочкой (*), обязательны для заполнения.",
  welcome_hint2:
    "Наша услуга БЕСПЛАТНАЯ! Пожалуйста, не платите никому! Сбор за визу составляет 298 британских фунтов стерлингов (приблизительно 363 EUR/ 386 USD), а также сбор TLS (в некоторых странах), который оплачивается карточкой Visa или Mastercard непосредственно в Визовом центре Великобритании UKVI. У нас нет агентов! Заявки подаются напрямую!",

  // ------------- General Information Page -------------
  name: "Имя",
  name_error: "Имя обязательно и должно содержать только буквы!",
  surname: "Фамилия",
  surname_error: "Фамилия обязательна и должна содержать только буквы!",
  maiden_name: "Девичья фамилия (если есть)",
  maiden_name_error: "Девичья фамилия должна содержать только буквы!",
  gender: "Пол",
  gender_error: "Пол обязателен!",
  sex_male: "Мужской",
  sex_female: "Женский",
  sex_diverse: "Другой",
  birth_date: "Дата рождения",
  birth_date_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  birth_place: "Место рождения (город)",
  birth_place_eror: "Место рождения обязательно!",
  pass_id: "Номер паспорта",
  pass_id_error: "Номер паспорта обязателен и должен содержать только буквы и цифры!",
  pass_issuing_place: "Город выдачи",
  pass_issuing_place_error: "Место выдачи обязательно и должно содержать только буквы!",
  pass_issue_date: "Дата выдачи",
  pass_issue_date_error: "Дата выдачи паспорта обязательна и должна быть в прошлом.",
  pass_expiry_date: "Дата окончания",
  pass_expiry_date_error: "Дата окончания паспорта обязательна и должна быть в будущем!",
  biometric_pass_error: "Пожалуйста, выберите тип вашего паспорта!",
  full_address: "Домашний адрес (улица, дом, город)",
  residency_street: "Домашний адрес (улица, дом, этаж, квартира)",
  residency_street_error: "Домашний адрес обязателен!",
  residency_city: "Домашний адрес (город/посёлок)",
  residency_city_error: "Домашний адрес обязателен и должен содержать только буквы!",
  residency_province: "Область / Регион",
  residency_province_error: "Область должна содержать только буквы!",
  residency_zip: "Почтовый индекс",
  residency_zip_error: "Почтовый индекс обязателен!",
  residency_date_since: "С какого времени вы проживаете по этому адресу?",
  residency_date_since_error: "Дата заселения обязательна и должна быть в прошлом!",
  pass_biometric: "У вас есть биометрический паспорт?",
  nationality: "Гражданство",
  nationality_error: "Гражданство обязательно!",
  additional_nationality: "У вас есть другое гражданство?",
  additional_nationality_error: "",
  birth_country: "Страна рождения",
  birth_country_error: "Страна рождения обязательна!",
  pass_issuing_country: "Страна выдачи",
  pass_issuing_country_error: "Страна выдачи обязательна!",
  residency_country: "Страна проживания",
  residency_country_error: "Страна проживания обязательна!",
  ownership_own: "Моя собственность",
  ownership_rent: "Арендовано",
  ownership_other: "Другое",
  residency_ownership: "Это ваше жилье?",
  residency_ownership_error: "Пожалуйста, выберите соответствующий статус для этого жилья!",
  residency_ownership_other: "Уточните, пожалуйста",
  residency_ownership_other_error: "Пожалуйста, укажите ваше отношение к жилью латинскими буквами!",
  phone: "Номер телефона",
  phone_error: "Номер телефона обязателен и должен содержать только цифры!",

  // ------------- Family Information Page -------------
  marital_status: "Семейное положение",
  marital_status_error: "Семейное положение обязательно!",
  marital_status_single: "Не женат (не замужем)",
  marital_status_married: "Женат (замужем)",
  marital_status_divorced: "Разведен (разведена)",
  has_children: "У вас есть дети?",
  has_children_error: "Данные хотя бы об одном ребенке обязательны!",
  child_0_name: "Имя первого ребенка",
  child_1_name: "Имя второго ребенка",
  child_2_name: "Имя третьего ребенка",
  child_3_name: "Имя четвертого ребенка",
  child_0_surname: "Фамилия первого ребенка",
  child_1_surname: "Фамилия второго ребенка",
  child_2_surname: "Фамилия третьего ребенка",
  child_3_surname: "Фамилия четвертого ребенка",
  child_0_birthday: "Дата рождения первого ребенка",
  child_1_birthday: "Дата рождения второго ребенка",
  child_2_birthday: "Дата рождения третьего ребенка",
  child_3_birthday: "Дата рождения четвертого ребенка",
  child_0_lives_home: "Живет ли с вами",
  child_1_lives_home: "Живет ли с вами",
  child_2_lives_home: "Живет ли с вами",
  child_3_lives_home: "Живет ли с вами",
  //
  child_0_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_1_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_2_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_3_name_error: "Имя ребенка обязательно и должно содержать только буквы!",
  child_0_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_1_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_2_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_3_surname_error: "Фамилия ребенка обязательна и должна содержать только буквы!",
  child_0_birthday_error: "Необходимо указать дату рождения ребенка!",
  child_1_birthday_error: "Необходимо указать дату рождения ребенка!",
  child_2_birthday_error: "Необходимо указать дату рождения ребенка!",
  child_3_birthday_error: "Необходимо указать дату рождения ребенка!",
  //
  partner_name: "Имя",
  partner_name_error: "Имя обязательно и должно содержать только буквы!",
  partner_surname: "Фамилия",
  partner_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  partner_nationality: "Гражданство",
  partner_nationality_error: "Гражданство обязательно!",
  partner_birthday: "Дата рождения",
  partner_birthday_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  partner_birth_place: "Место рождения (город)",
  partner_birth_place_error: "Место рождения обязательно!",
  partner_birth_country: "Страна рождения",
  partner_birth_country_error: "Страна рождения обязательна!",
  partner_livesHome: "Партнер живет с вами?",
  partner_livesHome_error: "Пожалуйста, выберите подходящий вариант!",
  //
  parents_unknown: "Не могу предоставить информацию о своих родителях",
  //
  mother_name: "Имя",
  mother_name_error: "Имя обязательно и должно содержать только буквы!",
  mother_surname: "Фамилия",
  mother_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  mother_nationality: "Гражданство",
  mother_nationality_error: "Гражданство обязательно!",
  mother_birthday: "Дата рождения",
  mother_birthday_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  mother_birthPlace: "Место рождения (город)",
  mother_birthPlace_error: "Место рождения обязательно!",
  mother_birthCountry: "Страна рождения",
  mother_birthCountry_error: "Страна рождения обязательна!",
  //
  father_name: "Имя",
  father_name_error: "Имя обязательно и должно содержать только буквы!",
  father_surname: "Фамилия",
  father_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  father_nationality: "Гражданство",
  father_nationality_error: "Гражданство обязательно!",
  father_birthday: "Дата рождения",
  father_birthday_error: "Требуется дата рождения, которая должна быть как минимум 17 лет назад.",
  father_birthPlace: "Место рождения (город)",
  father_birthPlace_error: "Место рождения обязательно!",
  father_birthCountry: "Страна рождения",
  father_birthCountry_error: "Страна рождения обязательна!",
  //
  immergency_contact_name: "Имя контактного лица",
  immergency_contact_name_error:
    "Имя контактного лица обязательно и должно содержать только буквы!",
  immergency_contact_phone: "Номер телефона",
  immergency_contact_phone_error: "Номер телефона обязателен и должен содержать только цифры!",
  immergency_contact_relation: "Как вы связаны с этим контактом?",
  immergency_contact_relation_error: "Отношение обязательно!",
  immergency_contact_relation_relatives: "Родственник",
  immergency_contact_relation_parent: "Родитель",
  immergency_contact_relation_friend: "Друг",
  //
  children_travelling: "Дети будут путешествовать с вами?",
  //
  has_relatives_in_UK: "Имеете ли родственников в Великобритании?",
  has_relatives_in_UK_error: "Требуется указать информация хотя бы одного родственника!",

  relative_0_name: "Имя первого родственника",
  relative_1_name: "Имя второго родственника",
  relative_2_name: "Имя третьего родственника",
  relative_3_name: "Имя четвертого родственника",

  relative_0_surname: "Фамилия первого родственника",
  relative_1_surname: "Фамилия второго родственника",
  relative_2_surname: "Фамилия третьего родственника",
  relative_3_surname: "Фамилия четвертого родственника",

  relative_0_nationality: "Национальность первого родственника",
  relative_1_nationality: "Национальность второго родственника",
  relative_2_nationality: "Национальность третьего родственника",
  relative_3_nationality: "Национальность четвертого родственника",

  relative_0_relation: "Ваша связь с первым родственником",
  relative_1_relation: "Ваша связь со вторым родственником",
  relative_2_relation: "Ваша связь с третьим родственником",
  relative_3_relation: "Ваша связь с четвертым родственником",

  relative_0_typeofstay: "Статус пребывания первого родственника",
  relative_1_typeofstay: "Статус пребывания второго родственника",
  relative_2_typeofstay: "Статус пребывания третьего родственника",
  relative_3_typeofstay: "Статус пребывания четвертого родственника",

  relative_0_name_error: "Имя обязательно и должно содержать только буквы!",
  relative_1_name_error: "Имя обязательно и должно содержать только буквы!",
  relative_2_name_error: "Имя обязательно и должно содержать только буквы!",
  relative_3_name_error: "Имя обязательно и должно содержать только буквы!",

  relative_0_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  relative_1_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  relative_2_surname_error: "Фамилия обязательна и должна содержать только буквы!",
  relative_3_surname_error: "Фамилия обязательна и должна содержать только буквы!",

  relative_0_nationality_error: "Гражданство обязательно!",
  relative_1_nationality_error: "Гражданство обязательно!",
  relative_2_nationality_error: "Гражданство обязательно!",
  relative_3_nationality_error: "Гражданство обязательно!",

  relative_0_relation_error: "Выберите свою связь с родственником!",
  relative_1_relation_error: "Выберите свою связь с родственником!",
  relative_2_relation_error: "Выберите свою связь с родственником!",
  relative_3_relation_error: "Выберите свою связь с родственником!",

  relative_0_typeofstay_error: "Выберите статус пребывания!",
  relative_1_typeofstay_error: "Выберите статус пребывания!",
  relative_2_typeofstay_error: "Выберите статус пребывания!",
  relative_3_typeofstay_error: "Выберите статус пребывания!",

  // ------------- Travel Information Page -------------
  UK_insurance: "Имеете ли национальный страховой номер в Великобритании?",
  visited_UK_doctor: "Посещали ли вы врача в Великобритании?",
  granted_UK_visa: "Выдавалась ли вам виза в Великобританию?",
  refused_visa: "Отказывали ли вам виза в Великобританию ранее?",
  refused_entry: "Вам отказывали во въезде в Великобританию?",
  has_worked_illegally: "Работали ли вы нелегально в Великобритании?",
  required_to_leave_UK: "Были ли вы депортированы из Великобритании?",
  has_applied_for_visa:
    "Подавали ли вы заявление на статус или пребывание в Великобритании за последние 10 лет?",
  //
  has_travelled_to_UK: "Вы ранее ездили в Великобританию?",
  has_travelled_to_UK_error: "Необходимо указать хотя бы одну поездку!",
  travel_uk_hint: "Укажите ваши последние 3 поездки в Великобританию.",
  //
  traveluk_0_from: "Дата начала первой поездки",
  traveluk_1_from: "Дата начала второй поездки",
  traveluk_2_from: "Дата начала третьей поездки",
  traveluk_3_from: "Дата начала четвёртой поездки",

  traveluk_0_to: "Дата окончания первой поездки",
  traveluk_1_to: "Дата окончания второй поездки",
  traveluk_2_to: "Дата окончания третьей поездки",
  traveluk_3_to: "Дата окончания четвёртой поездки",

  traveluk_0_reason: "Цель первой поездки",
  traveluk_1_reason: "Цель второй поездки",
  traveluk_2_reason: "Цель третьей поездки",
  traveluk_3_reason: "Цель четвёртой поездки",

  traveluk_0_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",
  traveluk_1_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",
  traveluk_2_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",
  traveluk_3_from_error: "Необходимо указать дату начала вашего пребывания в Великобритании!",

  traveluk_0_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",
  traveluk_1_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",
  traveluk_2_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",
  traveluk_3_to_error: "Необходимо указать дату окончания вашего пребывания в Великобритании!",

  traveluk_0_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  traveluk_1_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  traveluk_2_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  traveluk_3_reason_error: "Необходимо указать цель вашего пребывания в Великобритании!",
  //
  refused_visa_abroad: "Вам отказывали визу в какую-либо страну?",
  deported_abroad: "Вы были депортированы из какой-либо страны?",
  deported_abroad_details:
    "Если вы были депортированы из другой страны, пожалуйста, укажите подробности",
  deported_abroad_details_error:
    'Если вы ответили "да" на предыдущий вопрос, необходимо предоставить подробную информацию!',

  //
  has_travelled_abroad: "Вы ездили за границу за последние 10 лет?",
  has_travelled_abroad_error: "Необходимо указать хотя бы одну поездку!",
  destination_0: "Первая страна",
  destination_1: "Вторая страна",
  destination_2: "Третья страна",
  destination_3: "Четвертая страна",
  destination_4: "Пятая страна",
  travelabroad_0_from: "Дата начала первой поездки",
  travelabroad_1_from: "Дата начала второй поездки",
  travelabroad_2_from: "Дата начала третьей поездки",
  travelabroad_3_from: "Дата начала четвертой поездки",
  travelabroad_4_from: "Дата начала пятой поездки",
  travelabroad_0_to: "Дата окончания первой поездки",
  travelabroad_1_to: "Дата окончания второй поездки",
  travelabroad_2_to: "Дата окончания третьей поездки",
  travelabroad_3_to: "Дата окончания четвертой поездки",
  travelabroad_4_to: "Дата окончания пятой поездки",

  travelabroad_0_reason: "Причина первой поездки",
  travelabroad_1_reason: "Причина второй поездки",
  travelabroad_2_reason: "Причина третьей поездки",
  travelabroad_3_reason: "Причина четвертой поездки",
  travelabroad_4_reason: "Причина пятой поездки",

  destination_0_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_1_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_2_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_3_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  destination_4_error:
    "Необходимо указать страну, в которой вы пребывали! Для Великобритании, пожалуйста, используйте предыдущий раздел.",
  travelabroad_0_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_1_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_2_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_3_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_4_from_error: "Необходимо указать дату начала вашего пребывания в прошлом!",
  travelabroad_0_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_1_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_2_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_3_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_4_to_error: "Необходимо указать дату окончания вашего пребывания в прошлом!",
  travelabroad_0_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_1_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_2_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_3_reason_error: "Необходимо указать причину вашего пребывания за границей!",
  travelabroad_4_reason_error: "Необходимо указать причину вашего пребывания за границей!",

  // ------------- Additional Information Page -------------
  convictions: "Были ли вы осуждены? (включая нарушения правил дорожного движения)",
  war_crimes: "Участвовали ли вы в военных конфликтах?",
  terrorist_org_member: "Участвовали/поддерживали ли вы террористические организации?",
  not_good_character:
    "Участвовали ли вы в деятельности, которая может создать негативное впечатление о вас?",
  application_additional_information:
    "Желаете ли предоставить дополнительную информацию в рамках этой заявки?",
  work_experience: "Пожалуйста, опишите ваш опыт работы",
  has_health_issues: "У вас есть проблемы со здоровьем/аллергии? (Пожалуйста, опишите)",
  english_level: "Уровень владения английским языком",
  english_level_error: "Пожалуйста, укажите ваш уровень владения английским языком!",
  language_level_none: "Отсутствует",
  language_level_beginner: "Начальный",
  language_level_intermediate: "Средний",
  language_level_advanced: "Продвинутый",
  has_driving_license: "Имеете ли вы водительское удостоверение?",
  has_driving_license_error: "Пожалуйста, укажите, есть ли у вас водительское удостоверение!",
  //
  privacy_hint:
    "Почему нам нужны ваши личные данные и как мы их обрабатываем? " +
    "Ай Би Си Студентски Обмен ООД является администратором ваших личных данных " +
    "и обрабатывает их в соответствии с Общим регламентом по защите персональных данных (GDPR). " +
    "Сбор ваших личных данных необходим в связи с вашей кандидатурой " +
    "на участие в наших программах. Здесь вы можете прочитать подробную информацию " +
    "о нашей Политике конфиденциальности: ",
  privacy_policy:
    "Подтверждаю, что я подробно ознакомлен/а, понимаю " +
    "и соглашаюсь с Политикой конфиденциальности IBC",
  privacy_policy_error:
    "Пожалуйста, примите политику конфиденциальности, " +
    "чтобы мы могли продолжить работу с вашей кандидатурой!",
  //
  sufficient_funds:
    "Я заявляю, что у меня достаточно средств, чтобы оплатить тест на туберкулез, визу и расходы на проезд в Великобританию и обратно.",
  sufficient_funds_error: "Пожалуйста, подтвердите наличие средств!",

  // ------------- Candidate Registration Page -------------
  register_finished_title:
    "Спасибо за регистрацию. Мы свяжемся с вами относительно следующих шагов.",
  register_welcome_title: "Работа в Великобритании с визой",
  register_welcome_hint1:
    "Здесь вы можете зарегистрироваться на собеседование, заполнив ваше имя, адрес электронной почты и номер телефона.",
  button_register: "Регистрация",
  email: "Электронная почта",
  has_passport: "У вас есть паспорт?",
  passportId: "Номер паспорта",
};

export default ru;
