import { Timestamp } from "firebase/firestore";
import { Address, TenantRecord, TrackedRecord } from "./commons";

export const DEFAULT_WORK_ORDER_DURATION = 183;
export type WorkOrderStatus = "open" | "approved" | "closed";

export interface Employer extends TrackedRecord {
  name: string;
  logo?: string;
  email?: string;
  phone?: string;
  address?: Address;
  color?: string;
}

export interface Season extends TrackedRecord {
  name: string;
  start_date: string;
  end_date?: string;
}

export interface SeasonFB extends TrackedRecord {
  name: string;
  start_date: Timestamp;
  end_date?: Timestamp;
}

export interface WorkOrder extends TrackedRecord, TenantRecord {
  name: string;
  employerId: string;
  seasonId: string;
  start_date: string;
  end_date?: string;
  status: WorkOrderStatus;
  description?: string;
  requireReturnVerification?: boolean;
  demand: number;
  workType?: string;
  sector?: string;
}

export interface WorkOrderFB extends TrackedRecord, TenantRecord {
  name: string;
  employerId: string;
  seasonId: string;
  start_date: Timestamp;
  end_date?: Timestamp;
  status: WorkOrderStatus;
  description?: string;
  requireReturnVerification?: boolean;
  demand: number;
  workType?: string;
  sector?: string;
}

export type EmployersDict = Record<string, Employer>;
export type EmployerDoc = { id: string; employer: Employer };
export type WorkOrdersDict = Record<string, WorkOrder>;
export type WorkOrderDoc = { id: string; workOrder: WorkOrder };
export type SeasonsDict = Record<string, Season>;
export type SeasonDoc = { id: string; season: Season };
