import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

interface RadioYesNoProps {
  id: string;
  label: string;
  value?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (event: any, newValue: any) => void;
  autoFocus?: boolean;
  sx?: any;
  translated?: boolean;
}

export const RadioYesNo: React.FC<RadioYesNoProps> = ({
  id,
  value,
  label,
  error,
  helperText,
  onChange,
  autoFocus,
  sx,
  translated = true,
}) => {
  const labelElement = translated ? <FormattedMessage id={id} defaultMessage={label} /> : label;

  const yesLabel = translated ? <FormattedMessage id="radio_yes" defaultMessage="Yes" /> : "Yes";

  const noLabel = translated ? <FormattedMessage id="radio_no" defaultMessage="No" /> : "No";

  return (
    <FormControl error={error}>
      <FormLabel id={"radio-" + id}>{labelElement}</FormLabel>
      <RadioGroup
        row
        aria-labelledby={"radio-" + id}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        sx={sx}
      >
        <FormControlLabel value={true} control={<Radio />} label={yesLabel} />
        <FormControlLabel value={false} control={<Radio />} label={noLabel} />
      </RadioGroup>
      <FormHelperText>
        {helperText &&
          (translated ? (
            <FormattedMessage id={id + "_error"} defaultMessage={helperText} />
          ) : (
            helperText
          ))}
      </FormHelperText>
    </FormControl>
  );
};
