import React, { useEffect, useRef, useState } from "react";
import { VirtualPresentation } from "../../types/virtual-presentations";
import "react-image-gallery/styles/css/image-gallery.css";

import header_Image from "../../ressources/global workforce_thin font - large.png";

import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { loadSponsor } from "../../data-functions/system-data_api";
import { Circle, CircleOutlined } from "@mui/icons-material";

interface PlayPresentationProps {
  presentation: VirtualPresentation;
  sponsor: string;
  startSlide?: number;
  onChangeSlide: (slideNumber: number) => void;
  onSign: (confirmed: boolean) => void;
}

type SlideStatus = "start" | "playing" | "sign" | "end";

const confirm_contents =
  "I hereby confrim, that I have watched the presentation and understand its content.";

export const PlayPresentation: React.FC<PlayPresentationProps> = ({
  presentation: initialPresentation,
  sponsor,
  startSlide = 0,
  onChangeSlide,
  onSign,
}) => {
  const [slideItems, setSlideItems] = useState<ReactImageGalleryItem[]>([]);
  const [slideStatus, setSlideStatus] = useState<SlideStatus>("start");
  const [confrimContent, setConfirmContent] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [imageURL, setImageURL] = useState("");

  const galleryRef = useRef<ImageGallery>(null);
  const currentSlide = useRef<number>(0);

  useEffect(() => {
    const slideItems: ReactImageGalleryItem[] = initialPresentation.slides.map((slide, index) => {
      return {
        original: slide.url,
        //thumbnail: slide.thumbnail,
        //description: slide.description,
      };
    });
    setSlideItems(slideItems);
    setSlideStatus("start");
  }, [initialPresentation]);

  useEffect(() => {
    const fetchSponsorLogo = async () => {
      const sponsorDoc = await loadSponsor(sponsor);
      let url;
      if (sponsorDoc && sponsorDoc.sponsor) {
        url = sponsorDoc.sponsor.logo || "";
      } else {
        url = header_Image;
      }

      if (url !== imageURL) {
        setImageURL(url);
      }
    };

    fetchSponsorLogo();
  }, [sponsor]);

  useEffect(() => {
    currentSlide.current = startSlide;
    if (galleryRef.current) galleryRef.current.slideToIndex(startSlide);

    if (startSlide === 0) {
      setSlideStatus("start");
    } else setSlideStatus("playing");
  }, [startSlide]);

  const handleNext = () => {
    if (currentSlide.current === slideItems.length - 1) {
      if (slideStatus === "playing") {
        setErrors({ confirm_content: "" });
        setConfirmContent(false);
        setSlideStatus("sign");
      } else {
        if (!confrimContent) {
          setErrors({
            confirm_content: "It is necessary to confirm before continuing",
          });
          return;
        } else {
          setSlideStatus("end");
          onSign(confrimContent);
        }
      }
    } else {
      if (slideStatus === "start") {
        currentSlide.current = 0;
        setSlideStatus("playing");
      } else {
        currentSlide.current += 1;
      }
      console.log("currentSlide: " + currentSlide.current);
      if (galleryRef.current) galleryRef.current.slideToIndex(currentSlide.current);
      onChangeSlide(currentSlide.current);
    }
  };
  const handlePrev = () => {
    if (currentSlide.current > 0) {
      currentSlide.current -= 1;
      if (galleryRef.current) galleryRef.current.slideToIndex(currentSlide.current);
      onChangeSlide(currentSlide.current);
    }
  };

  function getLogo(imageURL: string) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pt="1rem">
        <CardMedia
          component="img"
          //alt="green iguana"
          //height="140"
          image={imageURL}
          sx={{
            marginY: "1rem",
            maxHeight: "200px",
            width: "auto",
            maxWidth: { xs: "100%", md: "90%" },
          }}
        />
      </Box>
    );
  }

  const getStartContent = () => {
    return (
      <Box sx={{ backgroundColor: "white" }}>
        {getLogo(imageURL)}

        <Box
          sx={{
            padding: {
              xs: "0",
              md: "3rem",
            },
            paddingTop: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.25rem", md: "1.5rem" },
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: { xs: "1rem", md: "0" },
            }}
          >
            <FormattedMessage
              id="vp_welcome_title"
              defaultMessage={`Welcome to the "Work in the UK" presentation`}
            />
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="vp_title_hint1"
              defaultMessage="With this presentation you'll get all the information about working in the UK."
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="vp_title_hint2"
              defaultMessage="Please, watch all slides to the end. Finally, you need to confirm, that you've seen all slides and understand the content."
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  const getSignContent = () => {
    return (
      <Box sx={{ backgroundColor: "white" }}>
        {getLogo(imageURL)}

        <Box
          sx={{
            padding: {
              xs: "0",
              md: "3rem",
            },
            paddingTop: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.25rem", md: "1.5rem" },
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: { xs: "1rem", md: "0" },
            }}
          >
            <FormattedMessage
              id="vp_sign_title"
              defaultMessage={`Thank you for watching this presentation`}
            />
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="vp_sign_hint1"
              defaultMessage="Please confirm that you have understood the content of this presentation."
            />
          </Typography>

          <FormControl error={!!errors.privacy_policy_accepted}>
            <FormControlLabel
              control={
                <Checkbox
                  name="confirm_content"
                  size="medium"
                  checkedIcon={<Circle />}
                  icon={<CircleOutlined />}
                  onChange={(e) => setConfirmContent(e.target.checked)}
                />
              }
              label={<FormattedMessage id="vp_confirm_content" defaultMessage={confirm_contents} />}
            />
            <FormHelperText sx={{ color: "red" }}>
              {errors.confirm_content && (
                <FormattedMessage
                  id="vp_confirm_content_error"
                  defaultMessage={errors.confirm_content}
                />
              )}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    );
  };

  const getEndContent = () => {
    return (
      <Box sx={{ backgroundColor: "white" }}>
        {getLogo(imageURL)}
        <Box
          sx={{
            padding: {
              xs: "0",
              md: "3rem",
            },
            paddingTop: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.25rem", md: "1.5rem" },
              fontWeight: "bold",
              marginBottom: "1rem",
              marginTop: { xs: "1rem", md: "0" },
            }}
          >
            <FormattedMessage
              id="vp_end_title"
              defaultMessage={`Congratulations, you have successfully completed the presentation!`}
            />
          </Typography>
          <Typography sx={{ marginBottom: "1rem" }}>
            <FormattedMessage id="vp_end_hint1" defaultMessage="You can now close this window." />
          </Typography>
        </Box>
      </Box>
    );
  };

  const getPlayingContent = () => {
    return (
      <ImageGallery
        ref={galleryRef}
        items={slideItems}
        infinite={false}
        showNav={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
      />
    );
  };

  const getPlayContent = () => {
    switch (slideStatus) {
      case "start":
        return getStartContent();
      case "playing":
        return getPlayingContent();
      case "sign":
        return getSignContent();
      case "end":
        return getEndContent();
      default:
        return <div></div>;
    }
  };

  return (
    <Box>
      <Box sx={{ borderRadius: "12px", overflow: "hidden" }}>{getPlayContent()}</Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", m: "16px 0" }}>
        {slideStatus === "playing" && (
          <Button onClick={handlePrev} sx={{ mr: 2 }}>
            <FormattedMessage id="button_back" defaultMessage="Previous" />
          </Button>
        )}
        {slideStatus !== "playing" && <div></div>}
        {slideStatus !== "end" && (
          <Button onClick={handleNext} sx={{ mr: 2 }}>
            <FormattedMessage id="button_next" defaultMessage="Next" />
          </Button>
        )}
      </Box>
    </Box>
  );
};
