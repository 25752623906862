import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import header_Image from "../../ressources/ibc_logo.png";

const IbcMainPage: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      minWidth="100vw"
    >
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia component="img" image={header_Image} sx={{ marginBottom: "2rem" }} />
        <CardContent>
          <Typography gutterBottom variant="h4" align={"center"} component="div">
            IBC - Work abroad
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default IbcMainPage;
