import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
import { AppUser, EMPTY_APP_USER } from "../../types/users";
import { isEMailValid, isNameValid } from "../../utils/utils";
import {
  User,
  //createUserWithEmailAndPassword,
  updatePassword,
  updateProfile,
} from "firebase/auth";
import { UserSettingsMode } from "../../types/users";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import { auth } from "../../types/firebase";

interface UserSettingsDialogProps {
  user: AppUser;
  mode: UserSettingsMode;
  open: boolean;
  onClose: () => void;
}

interface DialogError {
  name: string;
  surname: string;
  email: string;
  password: string;
}

const EMPTY_ERROR: DialogError = {
  name: "",
  surname: "",
  email: "",
  password: "",
};

const UserSettingsDialog = ({ user, mode, open, onClose }: UserSettingsDialogProps) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [appUser, setAppUser] = useState<AppUser>({ ...EMPTY_APP_USER });
  const [errors, setErrors] = useState<DialogError>({ ...EMPTY_ERROR });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // ------------------
  useEffect(() => {
    if (mode === "edit") {
      setAppUser(user);
    } else {
      setAppUser({ ...EMPTY_APP_USER });
    }
    clearFields();
  }, [user, mode]);

  const validateInput = () => {
    let newErrors = { ...EMPTY_ERROR };
    let formIsValid = true;

    if (!(appUser && isNameValid(appUser.name))) {
      newErrors.name = "Name is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(appUser && isNameValid(appUser.surname))) {
      newErrors.surname = "Surname is required and should only contain alphabetic characters";
      formIsValid = false;
    }

    if (!(appUser && isEMailValid(appUser.email))) {
      newErrors.email = "Please enter a valid email address";
      formIsValid = false;
    }

    if (password) {
      if (password.length < 8) {
        newErrors.password = "The password must be at least 8 characters";
        formIsValid = false;
      } else {
        if (password !== passwordRepeat) {
          newErrors.password = "The passwords do not match";
          formIsValid = false;
        }
      }
    }

    setErrors(newErrors);
    return formIsValid;
  };

  // saving the profile settings
  const handleSave = async () => {
    console.log("Saving user: ");
    console.log(appUser);
    if (!validateInput()) {
      return;
    }

    let fbUser: User | null = auth.currentUser;

    // if (mode === "add") {
    //   try {

    //     const userCredential = await createUserWithEmailAndPassword(
    //       auth,
    //       appUser.email,
    //       password
    //     );
    //     fbUser = userCredential.user;
    //     appUser.id = fbUser.uid;
    //     appUser.role = "operator";
    //     console.log("created new user with password credentials");

    //     // Store additional fields in a Firestore document or Realtime Database.
    //     // Example: Role, which might be used for role-based access control in your app.
    //   } catch (error) {
    //     console.error("Error registering user:", error);
    //   }
    // } else
    //{

    if (fbUser && password) {
      console.log("updating password");
      await updatePassword(fbUser, password);
    }
    //}

    // Update the user's profile with additional information
    if (fbUser) {
      console.log("updating the display name: ");
      console.log(fbUser);
      await updateProfile(fbUser, {
        displayName: `${appUser.name} ${appUser.surname}`,
      });
    }

    console.log("Updating user with id: " + appUser.id);

    const db = getFirestore();
    const userQuery = query(collection(db, "users"), where("id", "==", appUser.id));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const docId = userSnapshot.docs[0].id; // We are assuming that id is unique, so we directly take the first document
      const docRef = doc(db, "users", docId);
      console.log("updating the db");
      await setDoc(docRef, appUser);
    } else {
      console.log("user does not exist. addingnew db entry");
      await addDoc(collection(db, "users"), appUser);
    }
    handleClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("property: " + event.target.name);
    console.log("value: " + event.target.value);

    if (event.target.name === "password") {
      setPassword(event.target.value);
    } else if (event.target.name === "passwordRepeat") {
      setPasswordRepeat(event.target.value);
    } else {
      console.log("setting user property");
      const newUser = { ...appUser, [event.target.name]: event.target.value };
      console.log(newUser);
      setAppUser(newUser);
    }
  };

  const clearFields = () => {
    setErrors({ ...EMPTY_ERROR });
    setPassword("");
    setPasswordRepeat("");
  };

  const handleClose = () => {
    setAppUser(user);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{mode === "add" ? "Add New user" : "Profile information"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add"
            ? "Please, enter the details of the new user"
            : "Here you can change your data"}
        </DialogContentText>
        <TextField
          error={!!errors.name}
          helperText={errors.name}
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={appUser.name}
          onChange={handleInputChange}
        />
        <TextField
          error={!!errors.surname}
          helperText={errors.surname}
          margin="dense"
          name="surname"
          label="Surname"
          type="text"
          fullWidth
          value={appUser.surname}
          onChange={handleInputChange}
        />
        <TextField
          error={!!errors.email}
          helperText={errors.email}
          margin="dense"
          name="email"
          label="Email Address"
          type="email"
          fullWidth
          value={appUser?.email}
          onChange={handleInputChange}
        />

        {/* ------ Password  ---------- */}
        <Divider light sx={{ mt: "12px", mb: "8px" }} />
        <FormControl
          sx={{ mt: "8px", mb: "4px", width: "100%" }}
          variant="outlined"
          error={!!errors.password}
        >
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            name="password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            value={password}
            onChange={handleInputChange}
            label="Password"
          />
          <FormHelperText id="password-helper-text">{errors.password}</FormHelperText>
        </FormControl>
        {/* ------ Password Repeat ---------- */}
        <FormControl sx={{ mt: "8px", mb: "4px", width: "100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Repeat Password</InputLabel>
          <OutlinedInput
            name="passwordRepeat"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            value={passwordRepeat}
            onChange={handleInputChange}
            label="Repeat Password"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSettingsDialog;
