import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Snackbar,
} from "@mui/material";
import { ContentCopy as CopyIcon, Email as EmailIcon } from "@mui/icons-material";
import { copyToClipboard } from "../../utils/utils";
import { useState } from "react";
import QRCode from "qrcode.react";

interface SimpleLinkDialogProps {
  label: string;
  open: boolean;
  showQRCode: boolean;
  showSendLink: boolean;
  title?: string;
  onSendLink: () => void;
  onClose: () => void;
}

const SimpleLinkDialog = ({
  label,
  open,
  showQRCode,
  showSendLink,
  title,
  onSendLink,
  onClose,
}: SimpleLinkDialogProps) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenSnackError(false);
  };

  const onCopySuccess = () => {
    setOpenSnack(true);
  };

  const onCopyError = (reason: any) => {
    console.error(reason);
    setOpenSnackError(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogTitle>{title || "Info"}</DialogTitle>

      <DialogContent>
        <Link href={label} target="_blank" rel="noreferrer">
          {label}
        </Link>
        <Box
          marginTop="24px"
          display="flex"
          flexDirection="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          {showSendLink && (
            <Button variant="contained" startIcon={<EmailIcon />} onClick={onSendLink}>
              Send Link
            </Button>
          )}
          <Button
            variant="contained"
            startIcon={<CopyIcon />}
            onClick={() => copyToClipboard(label, onCopySuccess, onCopyError)}
          >
            Copy to Clipboard
          </Button>
        </Box>
        {showQRCode && (
          <Box marginTop="24px" display="flex" flexDirection="column" alignItems="center">
            <QRCode value={label} size={250} />
          </Box>
        )}
        <Snackbar
          open={openSnack}
          autoHideDuration={1000}
          onClose={handleCloseSnack}
          message="Link successfully copied to the clipoard!"
        />
        <Snackbar open={openSnackError} autoHideDuration={1000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="error" sx={{ width: "100%" }}>
            The Link could not be copied to the clipboard!
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleLinkDialog;
