import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { AppUser } from "../types/users";
import db from "../types/firebase";
import { Agent, AgentDoc, AgentsDict } from "../types/agents";

export const loadAgents = async (user: AppUser | null | undefined, cLimit: number = 0) => {
  let agentsRef;
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";

  if (sponsorId) {
    agentsRef = query(collection(db, "agents"), where("sponsorId", "==", sponsorId));
  } else {
    agentsRef = collection(db, "agents");
  }

  if (cLimit > 0) agentsRef = query(agentsRef, limit(cLimit));
  const agentsSnapshot = await getDocs(agentsRef);
  const agentsDict: AgentsDict = {};

  agentsSnapshot.forEach((doc) => {
    const agent = doc.data() as Agent;
    agentsDict[doc.id] = agent;
  });

  return agentsDict;
};

export const loadAgent = async (agent: AgentDoc) => {
  let loadedAgent = null;

  if (agent && agent.id) {
    const docRef = doc(db, "agents", agent.id);
    const agentSnapshot = await getDoc(docRef);
    if (agentSnapshot) {
      loadedAgent = {
        id: agentSnapshot.id,
        agent: agentSnapshot.data() as Agent,
      };
    }
  }
  return loadedAgent;
};

export const saveAgent = async (
  user: AppUser | null | undefined,
  agent: AgentDoc,
  createIfNoId: boolean = false
) => {
  const appDoc = { ...agent };
  if (appDoc.id) {
    const docRef = doc(db, "agents", appDoc.id);
    const agentSnapshot = await getDoc(docRef);
    // Update the document
    appDoc.agent.updatedAt = new Date().toISOString();
    appDoc.agent.updatedBy = user ? user.id : "anonymous";
    agentSnapshot && (await setDoc(docRef, appDoc.agent));
    console.log("updated agent:", appDoc.id);
  } else if (createIfNoId) {
    appDoc.agent.createdAt = new Date().toISOString();
    appDoc.agent.createdBy = user ? user.id : "anonymous";
    appDoc.id = (await addDoc(collection(db, "agents"), appDoc.agent)).id;
    console.log("created agent:", appDoc.id);
  }

  return appDoc.id;
};

export const deleteAgent = async (agentId: string) => {
  return deleteDoc(doc(db, "agents", agentId));
};
