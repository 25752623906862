import { Box } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "./auth-provider";

interface BoxProps {
  children: React.ReactNode;
}

const ApplicationWrapper: React.FC<BoxProps> = ({ children }) => {
  const { currentUser } = useContext(AuthContext)!;

  return (
    <Box>
      <Box>{children}</Box>
      {!currentUser?.appUser?.role.includes("applicant") && (
        <div
          style={{
            display: "flex",
            width: "100%",
            textAlign: "right",
            fontSize: "12px",
            color: "grey",
          }}
        >
          <div
            style={{
              width: "100%",
              paddingRight: "16px",
              marginBottom: "10px",
            }}
          >
            {"v. "}
            {process.env.REACT_APP_VERSION}
          </div>{" "}
        </div>
      )}
    </Box>
  );
};

export default ApplicationWrapper;
