import { Box, CardMedia, Typography } from "@mui/material";

import header_Image from "../../../ressources/global workforce_thin font - large.png";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { VisaApplication } from "../../../types/visa-application";
import { loadSponsor } from "../../../data-functions/system-data_api";

interface PageWelcomProps {
  application: VisaApplication | undefined;
}
export const PageWelcome: React.FC<PageWelcomProps> = ({ application }) => {
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const getSponsorURL = async () => {
      let url = "";
      if (application) {
        if (application.sponsorId) {
          const sponsorDoc = await loadSponsor(application.sponsorId);

          if (sponsorDoc && sponsorDoc.sponsor) {
            url = sponsorDoc.sponsor.logo || "";
          }
        } else {
          url = header_Image;
        }

        if (url !== imageURL) {
          setImageURL(url);
        }
      }
    };

    getSponsorURL();
  }, [application]);

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CardMedia
          component="img"
          //alt="green iguana"
          //height="140"
          image={imageURL}
          sx={{
            marginY: "1rem",
            maxHeight: "200px",
            width: "auto",
            maxWidth: { xs: "100%", md: "90%" },
          }}
        />
      </Box>

      <Box
        sx={{
          padding: {
            xs: "0",
            md: "3rem",
          },
          paddingTop: "1rem",
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "1.25rem", md: "1.5rem" },
            fontWeight: "bold",
            marginBottom: "1rem",
            marginTop: { xs: "1rem", md: "0" },
          }}
        >
          <FormattedMessage
            id="welcome_title"
            defaultMessage="Working in the United Kingdom with a visa"
          />
        </Typography>
        <Typography sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="welcome_hint1"
            defaultMessage="Please, provide your answers in English. All fields, marked with an asterisk (*) are
          required."
          />
        </Typography>
        <Typography>
          <FormattedMessage
            id="welcome_hint2"
            defaultMessage="Our service is FREE! Please do not pay anyone! Visa fee of 298 GBP (363 EUR/ 386 USD) and TLS fee (if applicable) are the only fees. The visa fee is paid with a debit or credit card to UKVI directly. We don’t have agents! You can apply directly!"
          />
        </Typography>
      </Box>
    </React.Fragment>
  );
};
