import { ReactNode } from "react";
import { useEffect, useState, createContext } from "react";
import { app } from "../types/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AppUser, AuthUser } from "../types/users";
import { loadAppUser } from "../data-functions/users-api";

interface AuthProviderProps {
  children: ReactNode;
}

interface ContextType {
  currentUser: AuthUser | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const AuthContext = createContext<ContextType | null>(null);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth(app);

  useEffect(() => {
    const fetchAppUser = async (id: string) => {
      if (!id) {
        return null;
      }

      let appUser: AppUser | null = null;

      appUser = await loadAppUser(id);
      if (!appUser) {
        console.error("No app user found for id: " + id);
      }

      return appUser;
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      let cUser: AuthUser = {};
      if (user) {
        const appUser = await fetchAppUser(user.uid);
        cUser = { authUser: user, appUser: appUser || null };
        if (appUser) {
          cUser.realSponsorId = appUser.sponsorId || "";
          cUser.realAgentId = appUser.agentId || "";
        }
      }

      setCurrentUser(cUser);

      setIsLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, isLoading, setIsLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
