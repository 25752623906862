import { Box, CircularProgress, Typography, Container } from "@mui/material";
import header_Image from "../../ressources/ibc_logo.png";

const LoadingApplication = () => {
  return (
    <Container component="main" maxWidth="xs" sx={{ textAlign: "center", pt: "5em" }}>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <img
          src={header_Image} // Replace with your logo path
          alt="Logo"
          style={{ maxWidth: "150px", marginBottom: "1em" }}
        />
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading application...
        </Typography>
      </Box>
    </Container>
  );
};

export default LoadingApplication;
