import { Applicant } from "../../types/applicants";
import { EMPTY_COUNTRY } from "../../types/commons";
import { Country } from "../../types/commons";
import { EMPTY_TRAVEL, NUM_TRAVEL_DESTINATIONS, NUM_TRAVEL_UK } from "../../types/travels";

export const handleInputTravelChange = (
  applicant: Applicant,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  let newApplicant = null;
  if (applicant) {
    newApplicant = {
      ...applicant,
    };
    const key = event.target.name;
    console.log("Received an event for input: " + key);
    if (key === "has_travelled_to_UK") {
      console.log("Setting: travelled to UK");
      newApplicant.has_travelled_to_UK = event.target.value === "true" ? true : false;
    } else if (key === "has_travelled_abroad") {
      console.log("Setting: travelled abroad");
      newApplicant.has_travelled_abroad = event.target.value === "true" ? true : false;
    }
    // travel to UK
    else if (key.startsWith("traveluk_") && newApplicant) {
      console.log("Setting: travel uk data");
      const cKey = key.split("_");
      const num: number = parseInt(cKey[1]);
      // Make sure the children propert has enough elements
      const count = newApplicant.travel_details_UK ? newApplicant.travel_details_UK.length : 0;
      if (count === 0) {
        newApplicant.travel_details_UK = [];
      }
      for (let i = count; i < NUM_TRAVEL_UK; i++) {
        console.log("Adding travel: " + i);
        newApplicant.travel_details_UK &&
          newApplicant.travel_details_UK.push({
            destination: EMPTY_COUNTRY,
            from: "",
            to: "",
            reason: "",
          });
      }
      console.log("Setting property " + cKey[2]);
      console.log(newApplicant.travel_details_UK);
      if (newApplicant.travel_details_UK) {
        switch (cKey[2]) {
          case "from":
            newApplicant.travel_details_UK[num].from = event.target.value;
            break;
          case "to":
            newApplicant.travel_details_UK[num].to = event.target.value;
            break;
          case "reason":
            newApplicant.travel_details_UK[num].reason = event.target.value;
            break;
        }
      }
    }
    // travel abroad
    else if (key.startsWith("travelabroad_") && newApplicant) {
      console.log("Setting: travel abroad data");
      const cKey = key.split("_");
      const num: number = parseInt(cKey[1]);
      // Make sure the children propert has enough elements
      const count = newApplicant.travel_details_abroad
        ? newApplicant.travel_details_abroad.length
        : 0;
      if (count === 0) {
        newApplicant.travel_details_abroad = [];
      }
      for (let i = count; i < NUM_TRAVEL_DESTINATIONS; i++) {
        console.log("Adding travel: " + i);
        newApplicant.travel_details_abroad &&
          newApplicant.travel_details_abroad.push({
            destination: EMPTY_COUNTRY,
            from: "",
            to: "",
            reason: "",
          });
      }
      console.log("Setting property " + cKey[2]);
      if (newApplicant.travel_details_abroad) {
        switch (cKey[2]) {
          case "from":
            newApplicant.travel_details_abroad[num].from = event.target.value;
            break;
          case "to":
            newApplicant.travel_details_abroad[num].to = event.target.value;
            break;
          case "reason":
            newApplicant.travel_details_abroad[num].reason = event.target.value;
            break;
        }
      }
    }
  }
  return newApplicant;
};

export const handleTravelDestinationChange = (
  applicant: Applicant,
  event: any,
  newValue: Country | null
) => {
  let newApplicant = null;
  if (applicant) {
    const key = event.target.id.split("-option")[0];
    console.log("key: " + key);
    if (key && key.startsWith("destination_")) {
      //        const newApplicant = applicant;
      const destNumber: number = parseInt(key.split("_")[1]);

      const newTravelDetails = applicant.travel_details_abroad
        ? [...applicant.travel_details_abroad]
        : [];

      // Make sure the children propert has enough elements
      const count = newTravelDetails.length;

      for (let i = count; i < NUM_TRAVEL_DESTINATIONS; i++) {
        console.log("Adding destination: " + i);
        newTravelDetails.push({ ...EMPTY_TRAVEL });
      }

      if (newValue) {
        console.log("setting the " + destNumber + " destination to: ");
        console.log(newValue);
        newTravelDetails[destNumber] = {
          ...newTravelDetails[destNumber],
          destination: newValue,
        };
      }

      newApplicant = {
        ...applicant,
        travel_details_abroad: newTravelDetails,
      };

      console.log(newApplicant.travel_details_abroad);
    }
  }
  return newApplicant;
};
