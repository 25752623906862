import { collection, getDocs, query, where } from "firebase/firestore";
import { AppUser } from "../types/users";
import db from "../types/firebase";

export const fetchDocumentHistory = async (
  user: AppUser | null | undefined,
  documentId: string
) => {
  let documentHistory: any[] = [];
  if (user) {
    let historyRef = query(collection(db, "logs"), where("documentId", "==", documentId));
    if (user.sponsorId) {
      historyRef = query(historyRef, where("sponsorId", "==", user.sponsorId));
    }
    const historySnapshot = await getDocs(historyRef);
    if (historySnapshot) {
      documentHistory = historySnapshot.docs.map((doc) => doc.data());
    }
  }
  return documentHistory;
};
