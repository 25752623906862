import { Box, CardMedia, Typography } from "@mui/material";

import header_Image from "../../ressources/global workforce_thin font - large.png";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { loadSponsor } from "../../data-functions/system-data_api";

interface PageFormProps {
  formState: "expired" | "badID" | "completed";
  sponsor?: string;
}

export const PageInvalidPresentation: React.FC<PageFormProps> = ({ formState, sponsor }) => {
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const fetchSponsorLogo = async () => {
      let url = header_Image;
      if (sponsor) {
        const sponsorDoc = await loadSponsor(sponsor);
        if (sponsorDoc && sponsorDoc.sponsor) {
          url = sponsorDoc.sponsor.logo || "";
        }
      }

      if (url !== imageURL) {
        setImageURL(url);
      }
    };

    fetchSponsorLogo();
  }, [sponsor]);

  function getLogo() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" pt="1rem">
        <CardMedia
          component="img"
          //alt="green iguana"
          //height="140"
          image={imageURL}
          sx={{
            marginY: "1rem",
            maxHeight: "200px",
            width: "auto",
            maxWidth: { xs: "100%", md: "90%" },
          }}
        />
      </Box>
    );
  }

  const getFormText = () => {
    switch (formState) {
      case "badID":
        return (
          <Typography variant="h5" textAlign={"center"} sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="invalid_presentation_id"
              defaultMessage="You need a valid presentation link!"
            />
          </Typography>
        );
      case "expired":
        return (
          <Typography variant="h5" textAlign={"center"} sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="invalid_presentation_expired"
              defaultMessage="Your form has expired! Please, contact your agent or sponsor for further
            information!"
            />
          </Typography>
        );
      case "completed":
        return (
          <Typography variant="h5" textAlign={"center"} sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="invalid_presentation_completed"
              defaultMessage="You have already completed this presentation!"
            />
          </Typography>
        );
    }
  };

  return (
    <React.Fragment>
      {getLogo()}
      <Box padding={"3rem"} alignContent={"center"}>
        {/* <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="welcome_title"
            defaultMessage="IBC - working in the United Kingdom with a visa"
          />
        </Typography> */}
        {getFormText()}
      </Box>
    </React.Fragment>
  );
};
