import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./auth-provider";
import { hasRole } from "../utils/user-utils";
import NotAuthorized from "../pages/general/not-authorized";
import LoadingApplication from "../pages/general/loading-application";

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: string[];
}

export const ProtectedRoute = ({ children, allowedRoles }: ProtectedRouteProps) => {
  const { currentUser, isLoading } = useContext(AuthContext)!;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !(currentUser && currentUser.authUser)) {
      // If user is not logged in, redirect to login page
      console.log("not logged in -> redirecting to the login page");
      navigate("/login", location);
    }
  });

  useEffect(() => {
    // Ensure loading is complete and user exists before checking role
    if (!isLoading) {
      if (currentUser && currentUser.authUser && currentUser.appUser) {
        if (!hasRole(currentUser.appUser, allowedRoles)) {
          if (
            currentUser.appUser.role.length === 1 &&
            currentUser.appUser.role.includes("recruiter")
          ) {
            navigate("/interviews");
          } else if (hasRole(currentUser.appUser, ["admin", "operator"])) {
            navigate("/applications");
          } else {
            console.log("not authorized -> redirecting to the not authorized page");
            navigate("/not-authorized"); // Redirect to a 'not authorized' page
          }
        } else {
          if (
            ["/sponsors", "/employers"].includes(location.pathname) &&
            currentUser.appUser.sponsorId
          ) {
            navigate("/applications");
          }
        }

        // if (currentUser.appUser.role.includes("recruiter") && location.pathname !== "/interviews") {
        //   navigate("/interviews");
        // } else if (
        //   hasRole(currentUser.appUser, ["admin", "operator"]) &&
        //   location.pathname === "/"
        // ) {
        //   navigate("/applications");
        // }
        // // If authenticated but the role is not allowed, redirect to a 'not authorized' page or a default page
        // else if (!hasRole(currentUser.appUser, allowedRoles)) {
        //   console.log("not authorized -> redirecting to the not authorized page");
        //   navigate("/not-authorized"); // Redirect to a 'not authorized' page
        // }
      } else {
        // Redirect to login if not logged in
        console.log("not logged in -> redirecting to the login page");
        navigate("/login", { state: { from: location } }); // Pass the current location in state to enable redirecting back after login
      }
    }
  }, [isLoading, currentUser, allowedRoles, navigate, location]);

  // If user is logged in and has the correct role, allow access to route
  return !isLoading ? (
    currentUser &&
    currentUser.authUser &&
    currentUser.appUser &&
    hasRole(currentUser.appUser, allowedRoles) ? (
      children
    ) : (
      <NotAuthorized />
    )
  ) : (
    <LoadingApplication />
  );
};
