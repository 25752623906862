import { Agent } from "../types/agents";
import { Applicant } from "../types/applicants";
import { Candidate } from "../types/recruiting";
import { Country } from "../types/commons";

export const isCountryValid = (country: Country | undefined): boolean => {
  return country && country.code ? true : false;
};

export const applicantFullName = (applicant: Applicant) => {
  return applicant ? applicant.name + " " + applicant.surname : "n/a";
};

export const candidateFullName = (candidate: Candidate) => {
  return candidate ? candidate.name + " " + candidate.surname : "n/a";
};

export const agentFullName = (agent: Agent) => {
  return agent ? (agent.title + " " ?? "") + agent.name + " " + agent.surname : "n/a";
};

export const setApplicantDefaults = (applicant: Applicant): Applicant => {
  if (applicant.has_children === undefined) {
    applicant.has_children = false;
  }

  if (applicant.children_travelling === undefined) {
    applicant.children_travelling = false;
  }
  //-------------
  if (applicant.UK_insurance === undefined) {
    applicant.UK_insurance = false;
  }

  if (applicant.visited_UK_doctor === undefined) {
    applicant.visited_UK_doctor = false;
  }

  if (applicant.granted_UK_visa === undefined) {
    applicant.granted_UK_visa = false;
  }

  if (applicant.refused_visa === undefined) {
    applicant.refused_visa = false;
  }

  if (applicant.refused_entry === undefined) {
    applicant.refused_entry = false;
  }

  if (applicant.has_worked_illegally === undefined) {
    applicant.has_worked_illegally = false;
  }
  ///
  if (applicant.required_to_leave_UK === undefined) {
    applicant.required_to_leave_UK = false;
  }

  if (applicant.has_applied_for_visa === undefined) {
    applicant.has_applied_for_visa = false;
  }

  if (applicant.has_travelled_to_UK === undefined) {
    applicant.has_travelled_to_UK = false;
  }

  if (applicant.refused_visa_abroad === undefined) {
    applicant.refused_visa_abroad = false;
  }

  if (applicant.deported_abroad === undefined) {
    applicant.deported_abroad = false;
  }

  if (applicant.has_travelled_abroad === undefined) {
    applicant.has_travelled_abroad = false;
  }

  if (applicant.convictions === undefined) {
    applicant.convictions = false;
  }

  if (applicant.war_crimes === undefined) {
    applicant.war_crimes = false;
  }

  if (applicant.terrorist_org_member === undefined) {
    applicant.terrorist_org_member = false;
  }

  if (applicant.not_good_character === undefined) {
    applicant.not_good_character = false;
  }

  return applicant;
};
