import { Autocomplete, TextField, Box } from "@mui/material";
import { COUNTRIES_FULL } from "../types/country-codes";
import { EMPTY_COUNTRY } from "../types/commons";
import { Country } from "../types/commons";
import { FormattedMessage } from "react-intl";

interface SelectCountryProps {
  id: string;
  label: string;
  value: Country;
  error?: boolean;
  helperText?: string;
  onChange: (event: any, newValue: any) => void;
  sx?: any;
  required?: boolean;
  translated?: boolean;
}

const COUNTRIES = [{ code: "", label: "Select a country" }, ...COUNTRIES_FULL];

export const SelectCountry: React.FC<SelectCountryProps> = ({
  id,
  value,
  label,
  error,
  helperText,
  onChange,
  sx,
  required,
  translated = true,
}) => {
  return (
    <Autocomplete
      sx={sx}
      id={id}
      value={value && value.code ? value : EMPTY_COUNTRY}
      onChange={onChange}
      //
      options={COUNTRIES}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      //
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={option.code && `https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={option.code && `https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} {option.code && "(" + option.code + ")"}
        </Box>
      )}
      //
      renderInput={(params) => (
        <TextField
          {...params}
          name={id}
          label={translated ? <FormattedMessage id={id} defaultMessage={label} /> : label}
          error={error}
          helperText={
            helperText &&
            (translated ? (
              <FormattedMessage id={id + "_error"} defaultMessage={helperText} />
            ) : (
              helperText
            ))
          }
          required={required}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
