import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useContext, useEffect, useState } from "react";
import { fetchDocumentHistory } from "../../data-functions/logging_api";
import { AuthContext } from "../../components/auth-provider";
import { fbTimestampToDate } from "../../utils/utils";
import { loadAppUsers } from "../../data-functions/users-api";
import { AppUsersDict } from "../../types/users";

type DocumentHistoryDialogProps = {
  open: boolean;
  documentId?: string;
  onClose: () => void;
};

export const DocumentHistoryDialog: React.FC<DocumentHistoryDialogProps> = ({
  open,
  documentId = "",
  onClose,
}) => {
  const [documentHistory, setDocumentHistory] = useState<any[]>([]);
  const [users, setUsers] = useState<AppUsersDict>({});

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const loadUsers = async () => {
      const userDict = await loadAppUsers(currentUser?.appUser);
      console.log("loaded users: ", userDict);
      setUsers(userDict);
    };

    loadUsers();
  }, []);

  useEffect(() => {
    const loadDocumentHistory = async () => {
      if (documentId) {
        const docHistory = await fetchDocumentHistory(currentUser?.appUser, documentId);
        setDocumentHistory(docHistory);
      } else {
        setDocumentHistory([]);
      }
    };

    loadDocumentHistory();
  }, [documentId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Document History</DialogTitle>
      <DialogContent>
        <Box>
          {documentHistory.map((history, index) => {
            const user = Object.values(users).find((u) => u.id === history.userId);
            return (
              <Accordion key={index}>
                <AccordionSummary key={index + "_title"} expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {fbTimestampToDate(history.timestamp) + ": " + history.operation}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box p={2}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Box>
                          <Typography>{"documentId: " + history.documentId}</Typography>
                        </Box>
                        <Box>
                          <Typography>
                            {"user: " + (user ? user.name + " " + user.surname : history.userId)}
                          </Typography>
                        </Box>
                        <Box>
                          <pre> {JSON.stringify(history.changedFields, null, 2)}</pre>
                        </Box>
                      </Box>
                      <Box>{history.user}</Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
