import { VisaApplication, VisaApplicationFB, APPLICATION_STATUS } from "../types/visa-application";
import { Timestamp } from "firebase/firestore";
import { fbTimestampToDate } from "./utils";

export const setApplicationToFB = (application: VisaApplication): VisaApplicationFB => {
  const appl: VisaApplicationFB = {
    applicantId: application.applicantId,
    type: { ...application.type },
    workOrderId: application.workOrderId || "",
    sponsorId: application.sponsorId,
    seasonId: application.seasonId,
    status: application.status,
  };

  application.agentId && (appl.agentId = application.agentId);
  application.cos && (appl.cos = application.cos);
  application.gwf && (appl.gwf = application.gwf);

  application.employerId && (appl.employerId = application.employerId);
  application.workOrderId !== undefined && (appl.workOrderId = application.workOrderId);

  application.questionnaireId && (appl.questionnaireId = application.questionnaireId);

  application.privacy_policy_accepted &&
    (appl.privacy_policy_accepted = application.privacy_policy_accepted);
  application.sufficient_funds && (appl.sufficient_funds = application.sufficient_funds);

  application.visa_application_link &&
    (appl.visa_application_link = application.visa_application_link);

  application.start_date &&
    (appl.start_date = Timestamp.fromDate(new Date(application.start_date)));
  application.end_date && (appl.end_date = Timestamp.fromDate(new Date(application.end_date)));
  application.dataForm_expiry_date &&
    (appl.dataForm_expiry_date = Timestamp.fromDate(new Date(application.dataForm_expiry_date)));
  application.dataForm_completed &&
    (appl.dataForm_completed = Timestamp.fromDate(new Date(application.dataForm_completed)));

  if (application.virtualPresentation) {
    appl.virtualPresentation = {
      virtualPresentationId: application.virtualPresentation.virtualPresentationId,
      link_id: application.virtualPresentation.link_id,
      status: application.virtualPresentation.status,
    };
    application.virtualPresentation.expiry_date &&
      (appl.virtualPresentation.expiry_date = Timestamp.fromDate(
        new Date(application.virtualPresentation.expiry_date)
      ));
    application.virtualPresentation.completedDate &&
      (appl.virtualPresentation.completedDate = Timestamp.fromDate(
        new Date(application.virtualPresentation.completedDate)
      ));
    application.virtualPresentation.lastSlideViewed &&
      (appl.virtualPresentation.lastSlideViewed = application.virtualPresentation.lastSlideViewed);
  }

  application.createdAt && (appl.createdAt = application.createdAt);
  application.createdBy && (appl.createdBy = application.createdBy);
  application.updatedAt && (appl.updatedAt = application.updatedAt);
  application.updatedBy && (appl.updatedBy = application.updatedBy);

  return appl;
};

export const getApplicationFromFB = (application: VisaApplicationFB): VisaApplication => {
  const appl: VisaApplication = {
    applicantId: application.applicantId,
    type: { ...application.type },
    sponsorId: application.sponsorId,
    workOrderId: application.workOrderId || "",
    seasonId: application.seasonId,
    status: application.status,
  };

  application.sponsorId && (appl.sponsorId = application.sponsorId);
  application.agentId && (appl.agentId = application.agentId);
  application.cos && (appl.cos = application.cos);
  application.gwf && (appl.gwf = application.gwf);

  application.employerId && (appl.employerId = application.employerId);
  application.workOrderId !== undefined && (appl.workOrderId = application.workOrderId);

  application.questionnaireId && (appl.questionnaireId = application.questionnaireId);
  application.privacy_policy_accepted &&
    (appl.privacy_policy_accepted = application.privacy_policy_accepted);
  application.sufficient_funds && (appl.sufficient_funds = application.sufficient_funds);
  application.visa_application_link &&
    (appl.visa_application_link = application.visa_application_link);

  application.start_date && (appl.start_date = fbTimestampToDate(application.start_date));
  application.end_date && (appl.end_date = fbTimestampToDate(application.end_date));
  application.dataForm_expiry_date &&
    (appl.dataForm_expiry_date = fbTimestampToDate(application.dataForm_expiry_date));
  if (application.dataForm_completed) {
    appl.dataForm_completed = fbTimestampToDate(application.dataForm_completed);
  }

  if (application.virtualPresentation) {
    appl.virtualPresentation = {
      virtualPresentationId: application.virtualPresentation.virtualPresentationId,
      link_id: application.virtualPresentation.link_id,
      status: application.virtualPresentation.status,
    };
    application.virtualPresentation.expiry_date &&
      (appl.virtualPresentation.expiry_date = fbTimestampToDate(
        application.virtualPresentation.expiry_date
      ));
    application.virtualPresentation.completedDate &&
      (appl.virtualPresentation.completedDate = fbTimestampToDate(
        application.virtualPresentation.completedDate
      ));
    application.virtualPresentation.lastSlideViewed &&
      (appl.virtualPresentation.lastSlideViewed = application.virtualPresentation.lastSlideViewed);
  }

  application.createdAt && (appl.createdAt = application.createdAt);
  application.createdBy && (appl.createdBy = application.createdBy);
  application.updatedAt && (appl.updatedAt = application.updatedAt);
  application.updatedBy && (appl.updatedBy = application.updatedBy);

  return appl;
};

export const displayStatusLabel = (status: string) => {
  let result = APPLICATION_STATUS.find((s) => s.key === status)?.label;
  if (!result) {
    result = "n/a";
  }
  return result;
};

export const displayStatusDescription = (status: string) => {
  let result = APPLICATION_STATUS.find((s) => s.key === status)?.description;
  if (!result) {
    result = "";
  }
  return result;
};
