import { Applicant } from "../../types/applicants";
import { Country } from "../../types/commons";
import {
  EMPTY_CHILD,
  EMPTY_PARENT,
  EMPTY_PARTNER,
  NUM_CHILDREN,
  Person,
} from "../../types/persons";

export const handleInputChildrenChange = (
  applicant: Applicant,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  let newApplicant = null;

  if (applicant) {
    newApplicant = {
      ...applicant,
    };
    const key = event.target.name;
    console.log("Received an event for input: " + key);
    if (key === "has_children") {
      console.log("Setting: has children");
      newApplicant.has_children = event.target.value === "true" ? true : false;
    } else if (key.startsWith("child_") && newApplicant) {
      console.log("Setting: children data");
      const cKey = key.split("_");
      const num: number = parseInt(cKey[1]);
      console.log("child #" + num);
      console.log("typeOf:" + typeof newApplicant.children);

      // Make sure the children property has enough elements
      const count =
        newApplicant.children && !(typeof newApplicant.children === "string")
          ? newApplicant.children.length
          : 0;

      if (count === 0) {
        console.log("no children yet");
        newApplicant.children = [];
      }

      for (let i = count; i < NUM_CHILDREN; i++) {
        console.log("Adding child: " + i);
        newApplicant.children && newApplicant.children.push({ ...EMPTY_CHILD });
      }

      console.log("children: ");
      console.log(newApplicant.children);

      console.log("Setting property " + cKey[2]);

      if (newApplicant.children) {
        console.log(newApplicant.children[num]);
        switch (cKey[2]) {
          case "name":
            newApplicant.children[num].name = event.target.value;
            break;

          case "surname":
            newApplicant.children[num].surname = event.target.value;
            break;

          case "birthday":
            newApplicant.children[num].birthday = event.target.value;
            break;
        }
      }
    }
  }
  return newApplicant;
};

export const handlePersonInputChange = (
  applicant: Applicant,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  let newApplicant = null;

  if (applicant) {
    newApplicant = {
      ...applicant,
    };
    const key = event.target.name;
    console.log("Received an event for input: " + key);
    const cKey = key.split("_");
    const personType = cKey[0];
    const personProperty = cKey[1];
    console.log("person type: " + personType);
    console.log("person property: " + personProperty);
    console.log("value: " + event.target.value);
    let person: Person;

    switch (personType) {
      case "partner":
        person = newApplicant.partner || { ...EMPTY_PARTNER };
        break;
      case "mother":
        person = newApplicant.mother || { ...EMPTY_PARENT };
        break;
      case "father":
        person = newApplicant.father || { ...EMPTY_PARENT };
        break;

      default:
        return null;
    }

    switch (personProperty) {
      case "name":
        person.name = event.target.value;
        break;

      case "surname":
        person.surname = event.target.value;
        break;

      case "birthday":
        person.birthday = event.target.value;
        break;

      case "livesHome":
        person.livesHome = event.target.value === "true";
        break;

      case "travelsWith":
        person.travelsWith = event.target.value === "true";
        break;

      case "birthPlace":
        person.birthPlace = event.target.value;
        break;
    }
    console.log("person: ");
    console.log(person);

    newApplicant = {
      ...applicant,
      [personType]: person,
    };
  }
  return newApplicant;
};

export const handlePersonCountryChange = (
  applicant: Applicant,
  event: any,
  newValue: Country | null
) => {
  if (applicant) {
    let newApplicant: Applicant = {
      ...applicant,
    };

    console.log("event.target.id: " + event.target.id);
    const key: string = event.target.id.split("-option")[0];
    console.log("country key: " + key);
    if (key) {
      const cKey = key.split("_");
      const personType = cKey[0];
      const personProperty = cKey[1];
      let person = newApplicant[personType as keyof typeof newApplicant] as Person;

      if (newValue) {
        console.log("setting the country to: ");
        console.log(newValue);
        person = {
          ...person,
          [personProperty]: newValue,
        };
      }

      newApplicant = {
        ...applicant,
        [personType]: person,
      };
      console.log("newApplicant: ");
      console.log(newApplicant);
      return newApplicant;
    }
  } else return null;
};
