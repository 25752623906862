import { Box, CardMedia, Typography } from "@mui/material";

import header_Image from "../../ressources/global workforce_thin font - large.png";
import React from "react";
import { FormattedMessage } from "react-intl";

interface PageFormProps {
  formState: "expired" | "badID";
}

export const PageInvalidQuestionnaire: React.FC<PageFormProps> = ({ formState }) => {
  const getFormText = () => {
    switch (formState) {
      case "badID":
        return (
          <Typography variant="h5" textAlign={"center"} sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="invalid_questionnaire_id"
              defaultMessage="You need a valid link for your questionnaire!"
            />
          </Typography>
        );
      case "expired":
        return (
          <Typography variant="h5" textAlign={"center"} sx={{ marginBottom: "1rem" }}>
            <FormattedMessage
              id="invalid_questionnaire_expired"
              defaultMessage="Your form has expired! Please, contact your agent or sponsor for further
            information!"
            />
          </Typography>
        );
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ padding: { xs: "1rem 3rem", md: "2rem 1rem" } }}
      >
        <CardMedia
          component="img"
          //alt="green iguana"
          //height="140"
          image={header_Image}
          sx={{ width: "100%", maxWidth: "450px" }}
        />
      </Box>

      <Box padding={"3rem"} alignContent={"center"}>
        {/* <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          <FormattedMessage
            id="welcome_title"
            defaultMessage="IBC - working in the United Kingdom with a visa"
          />
        </Typography> */}
        {getFormText()}
      </Box>
    </React.Fragment>
  );
};
