import {
  EMPTY_ADDRESS,
  EMPTY_ADDRESS_ERRORS,
  Address,
  AddressErrors,
  TrackedRecord,
  TenantRecord,
} from "./commons";

export const AGENT_TITLES: Record<string, string> = {
  Mr: "Mr",
  Mrs: "Mrs",
  Miss: "Miss",
  Ms: "Ms",
  Dr: "Doctor",
  Rev: "Revernend",
};

export interface Agent extends TrackedRecord, TenantRecord {
  title?: string;
  name: string;
  surname: string;
  company: string;
  email: string;
  phone?: string;
  address: Address;
}

export interface AgentErrors {
  title: string;
  name: string;
  surname: string;
  company: string;
  email: string;
  phone: string;
  address: AddressErrors;
  sponsorId: string;
}

export type AgentsDict = Record<string, Agent>;
export type AgentDoc = { id: string; agent: Agent };

export const EMPTY_AGENT: Agent = {
  name: "",
  surname: "",
  company: "New Agent",
  email: "",
  address: { ...EMPTY_ADDRESS },
  sponsorId: "",
};

export const EMPTY_AGENT_ERRORS: AgentErrors = {
  title: "",
  name: "",
  surname: "",
  company: "",
  email: "",
  phone: "",
  address: { ...EMPTY_ADDRESS_ERRORS },
  sponsorId: "",
};
