import { useContext, useEffect, useState } from "react";
import { Season, SeasonDoc, SeasonsDict } from "../../types/orders";
import { AuthContext } from "../../components/auth-provider";
import { deleteSeason, loadSeasons, storeSeason } from "../../data-functions/orders-api";
import { TitledPage } from "../../components/titled-page";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { isEqual } from "lodash";

const SeasonsData = () => {
  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [selectedSeason, setSelectedSeason] = useState<SeasonDoc | null>(null);

  const [errors, setErrors] = useState<{
    name: string;
    startData: string;
    endDate: string;
  }>({ name: "", startData: "", endDate: "" });

  const { currentUser } = useContext(AuthContext)!;

  const fetchSeasons = async () => {
    // Fetch seasons from the database
    const seasons = await loadSeasons(currentUser?.appUser);
    setSeasons(seasons);
  };

  useEffect(() => {
    fetchSeasons();
  }, []);

  const validate = () => {
    let valid = true;
    const newErrors = { name: "", startData: "", endDate: "" };

    if (!selectedSeason) {
      console.error(`No season is selected for validation!`);
      return false;
    }

    if (!selectedSeason.season.name) {
      newErrors.name = "Name is required!";
      valid = false;
    }

    if (!selectedSeason.season.start_date) {
      newErrors.startData = "Start date is required!";
      valid = false;
    }

    if (
      selectedSeason.season.end_date &&
      selectedSeason.season.start_date > selectedSeason.season.end_date
    ) {
      newErrors.endDate = "End date must be after start date!";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleAddSeason = () => {
    const currentYear = new Date().getFullYear() + 1;
    const startDate = new Date(currentYear, 0, 1); // January 1st of the next year

    const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(startDate.getDate()).padStart(2, "0");

    const formattedStartDate = `${currentYear}-${month}-${day}`;

    const newSeason: Season = {
      name: "S" + currentYear,
      start_date: formattedStartDate,
    };

    setSelectedSeason({ id: "", season: newSeason });
  };

  const handleSaveSeason = async () => {
    if (!validate()) {
      return;
    }

    if (selectedSeason) {
      let newSeason: SeasonDoc | null = { ...selectedSeason };
      const startDate = new Date(newSeason.season.start_date);
      const endYear = startDate.getFullYear();
      const endDate = new Date(endYear, 11, 31); // December 31st of the start_date's year

      newSeason.season.end_date = endDate.toISOString().split("T")[0]; // Format as yyyy-mm-dd

      // Update the document
      newSeason = await storeSeason(currentUser?.appUser, newSeason, true);

      if (newSeason) {
        const newSeasons = { ...seasons };
        newSeasons[newSeason.id] = { ...newSeason.season };
        setSeasons(newSeasons);
        setSelectedSeason(newSeason);
      }
    } else {
      console.error(`No sponsor is selected for saving!`);
    }
  };

  const handleDelete = async (seasonId: string) => {
    if (!seasonId) return;

    if (window.confirm("Are you sure you want to delete this season?")) {
      const result = await deleteSeason(currentUser?.appUser, seasonId);

      switch (result) {
        case "success":
          const newSeasons = { ...seasons };
          delete newSeasons[seasonId];
          if (selectedSeason && selectedSeason.id === seasonId) {
            setSelectedSeason(null);
          }
          setSeasons(newSeasons);
          break;
        case "no-season":
          window.alert("The season does not exist");
          break;
        case "season-in-use":
          window.alert("The season is linked to other data and cannot be deleted");
          break;
        case "insufficient-permissions":
          window.alert("You do not have enough permissions to delete this sponsor.");
          break;

        default:
          console.error("Unknown error");
      }
    }
  };

  const handleSelectSeason = (seasonId: string) => {
    if (seasonId) {
      const season = seasons[seasonId];
      if (season) {
        if (selectedSeason && selectedSeason?.id !== seasonId) {
          const sWO = selectedSeason.id ? seasons[selectedSeason.id] : null;

          if (!isEqual(sWO, selectedSeason.season)) {
            const conf = window.confirm(
              "The selected season has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }

        setSelectedSeason({ id: seasonId, season: { ...season } });
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //    if (event.target.name === "expiry_date") {

    console.log("handleInputChange", event.target.name, event.target.value);
    if (selectedSeason) {
      const newSponsor: SeasonDoc = {
        ...selectedSeason,
        season: {
          ...selectedSeason.season,
          [event.target.name]: event.target.value,
        },
      };

      console.log("newSponsor", newSponsor);
      setSelectedSeason(newSponsor);
    }
  };

  const formatSeasonDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  return (
    <TitledPage title="Seasons">
      <Box>
        {!currentUser?.appUser?.sponsorId && (
          <Box display={"flex"} sx={{ gap: 2 }}>
            <Button variant="outlined" color="primary" onClick={handleAddSeason}>
              Add New Season
            </Button>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "1rem",
          }}
        >
          {!currentUser?.appUser?.sponsorId && (
            <Box sx={{ width: "50%", marginRight: "2%" }}>
              <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">Name</TableCell>
                      <TableCell component="th">Start Date</TableCell>
                      <TableCell component="th" align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(seasons).map(([seasonId, season]) => {
                      return (
                        <TableRow
                          key={seasonId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onClick={() => handleSelectSeason(seasonId)}
                          className={
                            selectedSeason && selectedSeason.id === seasonId ? "selected" : ""
                          }
                        >
                          <TableCell scope="row" size="small">
                            {season.name}
                          </TableCell>
                          <TableCell scope="row" size="small">
                            {formatSeasonDate(season.start_date)}
                          </TableCell>
                          <TableCell align="right" style={{ width: 100 }} size="small">
                            <Tooltip title="Delete">
                              <IconButton size="small" onClick={() => handleDelete(seasonId || "")}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Box sx={{ width: "48%", margin: !currentUser?.appUser?.sponsorId ? "initial" : "auto" }}>
            {selectedSeason && (
              <Card>
                <CardHeader title={"Sponsor Details"} />
                <CardContent>
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        error={!!errors.name}
                        helperText={errors.name}
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedSeason && selectedSeason.season.name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!!errors.startData}
                        helperText={errors.startData}
                        margin="dense"
                        name="start_date"
                        label="Email for applications"
                        type="date"
                        fullWidth
                        value={selectedSeason && selectedSeason.season.start_date}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box flexGrow={1}></Box>
                  <Button variant="contained" onClick={handleSaveSeason} sx={{ mr: 2 }}>
                    Save
                  </Button>
                </CardActions>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default SeasonsData;
