import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDSXhCvuZOs96pa9ahX5FI6MZRd_MRjF3I",
  authDomain: "visa-autofiller.firebaseapp.com",
  projectId: "visa-autofiller",
  storageBucket: "visa-autofiller.appspot.com",
  messagingSenderId: "468479969892",
  appId: "1:468479969892:web:cd95526c9771e52892ad71",
};

const REGION = "europe-west3";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const db = getFirestore(app);
export const storage = getStorage(app);

export const functions = getFunctions(app, REGION);

export const BASE_FUNCTIONS_URL = `https://${REGION}-${firebaseConfig.projectId}.cloudfunctions.net/api`;

if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export default db;
