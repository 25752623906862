import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import {
  APPLICATION_STATUS,
  ApplicationDoc,
  EMPTY_VISA_APPLICATION,
} from "../../types/visa-application";

interface ApplicationStatusDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (application: ApplicationDoc) => void;
  application: ApplicationDoc | null;
}

const EMPTY_APPLICATION_DOC = { id: "", application: { ...EMPTY_VISA_APPLICATION } };

const EMPTY_ERRORS = {
  status: "",
};

const ApplicationStatusDialog = ({
  open,
  onClose,
  onSave,
  application: initialApplication,
}: ApplicationStatusDialogProps) => {
  const [appDoc, setApplication] = useState<ApplicationDoc>({
    id: "",
    application: { ...EMPTY_VISA_APPLICATION },
  });
  const [errors, setErrors] = useState<{
    status: string;
  }>({ ...EMPTY_ERRORS });

  useEffect(() => {
    if (initialApplication) {
      setApplication({ ...initialApplication });
    } else {
      const newApplication = {
        ...EMPTY_APPLICATION_DOC,
      };
      console.log(newApplication);
      setApplication(newApplication);
    }
  }, [initialApplication]);

  const validateInput = async () => {
    let newErrors = { ...EMPTY_ERRORS };
    let formIsValid = true;

    if (!appDoc.application.status) {
      newErrors.status = "Please, provide a valid status for this application";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }

    onSave(appDoc);
    setApplication({ ...EMPTY_APPLICATION_DOC });
    handleClose();
  };

  const handleClose = () => {
    setErrors({ ...EMPTY_ERRORS });
    onClose();
  };

  const onInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (appDoc) {
      const value = event.target.value;
      const newApplication: ApplicationDoc = {
        ...appDoc,
      };
      console.log(event.target.name);
      switch (event.target.name) {
        case "status":
          const applicationType = APPLICATION_STATUS.find((type) => type.key === value);
          console.log("new status: ");
          console.log(applicationType);
          if (applicationType) {
            newApplication.application.status = applicationType.key;
          }
          break;

        default:
          break;
      }

      setApplication(newApplication);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogTitle>Application Status</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, change the application's status.</DialogContentText>
        <Grid container spacing={2} padding={2}>
          {/* ----------- Status Type ----------- */}
          <Grid item xs={12}>
            <TextField
              name={"status"}
              label={"Application status"}
              select
              error={!!errors.status}
              helperText={errors.status}
              onChange={onInputComboChange}
              value={(appDoc && appDoc.application && appDoc.application.status) || ""}
              required
              fullWidth
              margin={"dense"}
            >
              {Object.entries(APPLICATION_STATUS).map(([key, vtype]) => {
                return (
                  <MenuItem value={vtype.key} key={key}>
                    {vtype.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApplicationStatusDialog;
