import { useEffect, useState } from "react";

import { Button, Menu, MenuItem } from "@mui/material";
import {
  isLanguageSupported,
  languageMappping,
  SupportedLanguages,
  supportedLanguages,
} from "../languages/languages";

interface LanguageSwitcherProps {
  onLanguageChange: (language: SupportedLanguages) => void;
  initialLanguage?: SupportedLanguages;
}
export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({
  onLanguageChange,
  initialLanguage,
}) => {
  const [language, setLanguage] = useState<SupportedLanguages>(initialLanguage || "en");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    console.log("setting the default language: " + initialLanguage);
    if (initialLanguage) {
      setLanguage(initialLanguage);
    }
  }, [initialLanguage]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageChange = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: string | null
  ) => {
    if (newLanguage && isLanguageSupported(newLanguage)) {
      setLanguage(newLanguage as SupportedLanguages);
      onLanguageChange(newLanguage as SupportedLanguages);
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        startIcon={
          <img
            src={`https://flagcdn.com/w40/${languageMappping[language]}.png`}
            alt={language}
            style={{
              width: "32px",
              height: "32px",
              objectFit: "contain",
            }}
          />
        }
        onClick={handleClick}
        className="language-switcher"
      >
        {language}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {supportedLanguages.map((lang) => (
          <MenuItem key={lang} onClick={(event) => handleLanguageChange(event, lang)}>
            <img
              src={`https://flagcdn.com/w40/${languageMappping[lang]}.png`}
              alt={lang}
              style={{ width: "24px", height: "24px", objectFit: "contain", marginRight: "8px" }}
            />
            {lang.toLocaleUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
