import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../components/auth-provider";
import { TitledPage } from "../../components/titled-page";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete as DeleteIcon, Edit, ZoomIn } from "@mui/icons-material";
import {
  EMPTY_VIRTUAL_PRESENTATION,
  VirtualPresentationDoc,
  VirtualPresentationsDict,
} from "../../types/virtual-presentations";
import {
  deletePresentation,
  deleteSlideImages,
  isPresentationAssigned,
  loadVirtualPresentations,
  savePresentation,
} from "../../data-functions/virtual_presentataions_api";
import VirtualPresentationDialog from "../dialogs/dialogVirtualPresentationSettings";
import _ from "lodash";

const VirtualPresentationsPage: React.FC = () => {
  const [presentations, setPresentations] = useState<VirtualPresentationsDict>({});
  const [editPresentation, setEditPresentation] = useState<VirtualPresentationDoc | null>(null);
  const [selectedPresentation, setSelectedPresentation] = useState<VirtualPresentationDoc | null>(
    null
  );

  const [openSettingsDialog, setOpenSettingsDialog] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const { currentUser } = useContext(AuthContext)!;

  const fetchPresentations = async () => {
    const presentationsDict: VirtualPresentationsDict = await loadVirtualPresentations(
      currentUser?.appUser
    );
    setPresentations(presentationsDict);
  };

  useEffect(() => {
    fetchPresentations();
    setEditPresentation(null);
    setSelectedPresentation(null);
    setSearchValue("");
    setOpenSettingsDialog(false);
  }, [currentUser]);

  const handleAddPresentation = () => {
    console.log("Add new presentation");

    if (!currentUser?.appUser?.sponsorId) {
      alert("Only users with a sponsor can create a presentation");
      return;
    }

    const newPresentation = { ...EMPTY_VIRTUAL_PRESENTATION };
    newPresentation.sponsorId = currentUser?.appUser?.sponsorId || "";
    setSelectedPresentation({ id: "", presentation: newPresentation });
    setOpenSettingsDialog(true);
  };

  const handleSavePresentation = async (presentation: VirtualPresentationDoc) => {
    console.log("Saving presentation...");
    if (presentation.id) {
      console.log("saving existing presentation: " + presentation.id);
      const oridignalPresentation = presentations[presentation.id];
      if (oridignalPresentation) {
        console.log("original presentataion found, checking for removed slides...");
        // make sure to first delete removed slide images and thumbnails
        oridignalPresentation.slides.forEach((slide) => {
          const newSlide = presentation.presentation.slides.find((s) => s.id === slide.id);
          if (!newSlide) {
            // slide was removed
            console.log("foubnd removed slide: " + slide.id);
            deleteSlideImages(slide);
          }
        });
      }
    }

    const newPresentation = await savePresentation(currentUser?.appUser, presentation, true);
    if (newPresentation) {
      const newPresentations = _.cloneDeep(presentations);
      newPresentations[newPresentation.id] = newPresentation.presentation;
      setPresentations(newPresentations);
      setSelectedPresentation(newPresentation);
      setOpenSettingsDialog(false);
    }
  };

  const handleCloseSetteingsDialog = () => {
    setOpenSettingsDialog(false);
    setEditPresentation(null);
  };

  const handleEditPresentation = (presentationId: string) => {
    if (!presentationId && !presentations[presentationId]) return;

    setEditPresentation({
      id: presentationId,
      presentation: { ...presentations[presentationId] },
    });
    setOpenSettingsDialog(true);
  };

  const handleDelete = async (presentationId: string) => {
    if (!presentationId && !presentations[presentationId]) return;

    const newPresentationspresentation = {
      id: presentationId,
      presentation: presentations[presentationId],
    };

    const presentationAssigned = await isPresentationAssigned(presentationId);
    if (presentationAssigned) {
      window.alert(
        "This presentation has applications assosiated with it and cannot be deleted! Try setting its status to Closed instead."
      );
      return;
    }

    if (window.confirm("Are you sure you want to delete this presentation?")) {
      deletePresentation(newPresentationspresentation).then(() => {
        const newPresentations = { ...presentations };
        delete newPresentations[presentationId];
        setSelectedPresentation(null);
        setPresentations(newPresentations);
      });
    }
  };

  const handleSelectPresentation = (presentationId: string) => {
    console.log("selecting presentation: " + presentationId);
    setSelectedPresentation({
      id: presentationId,
      presentation: { ...presentations[presentationId] },
    });
  };

  const getPresentationStatusChip = (status: string | undefined) => {
    switch (status) {
      case "enabled":
        return <Chip label="Enabled" color="success" />;
      case "disabled":
        return <Chip label="Disabled" color="error" />;

      default:
        return <></>;
    }
  };

  /* ------------ Search functions -------------- */

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredPresentations = useMemo(
    () =>
      Object.entries(presentations).filter(([presentationId, presentation]) => {
        try {
          return (
            searchValue.length < 3 ||
            presentation.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            (presentation.status &&
              presentation.status.toLowerCase().includes(searchValue.toLowerCase()))
          );
        } catch (err) {
          console.error("filter exception:");
          console.log(presentation);
          return false;
        }
      }),
    [searchValue, presentations]
  );

  return (
    <TitledPage title="Virtual Presentations">
      <Box>
        <Box display={"flex"} sx={{ gap: 2 }}>
          <VirtualPresentationDialog
            presentation={editPresentation}
            open={openSettingsDialog}
            onSave={handleSavePresentation}
            onClose={handleCloseSetteingsDialog}
          />
          <Button variant="outlined" color="primary" onClick={handleAddPresentation}>
            Add New Presentation
          </Button>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            value={searchValue}
            onChange={handleSearchValueChange}
            sx={{ minWidth: "250px" }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "1rem" }}>
          <Box
            sx={{ flexGrow: 1, flexBasis: "0", maxWidth: `calc(100% - 450px)`, marginRight: "8px" }}
          >
            <TableContainer component={Paper} sx={{ minHeight: "300px" }}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">Name</TableCell>
                    <TableCell component="th">Status</TableCell>
                    <TableCell component="th" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(filteredPresentations).map(([presentationId, presentation]) => {
                    return (
                      <TableRow
                        key={presentationId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => handleSelectPresentation(presentationId)}
                        className={selectedPresentation?.id === presentationId ? "selected" : ""}
                      >
                        <TableCell scope="row" size="small">
                          {presentation.name}
                        </TableCell>
                        <TableCell scope="row" size="small">
                          {getPresentationStatusChip(presentation.status)}
                        </TableCell>
                        <TableCell align="right" style={{ width: 100 }} size="small">
                          <Tooltip title="Edit the virtual presentation">
                            <IconButton
                              size="small"
                              onClick={() => handleEditPresentation(presentationId)}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete the virtual presentation">
                            <IconButton
                              size="small"
                              onClick={() => handleDelete(presentationId || "")}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* -------- Presentation Details -------- */}
          <Box sx={{ width: "48%", maxWidth: "450px" }}>
            {selectedPresentation && (
              <Card>
                <CardHeader
                  title={
                    selectedPresentation && selectedPresentation.presentation
                      ? selectedPresentation.presentation.name
                      : "Presentation Details"
                  }
                />
                <CardContent sx={{ backgroundColor: "lightgray" }}>
                  {selectedPresentation && (
                    <ImageList
                      cols={1}
                      sx={{
                        width: 170,
                        height: 450,
                        border: "0px solid",
                      }}
                    >
                      {selectedPresentation.presentation.slides.map((slide, index) => (
                        <ImageListItem key={slide.id} sx={{}}>
                          <img
                            style={{
                              objectFit: "scale-down",
                              width: "170px",
                              marginTop: "auto",
                            }}
                            src={slide.thumbnail || slide.url}
                            alt={slide.description}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            //title={slide.des}
                            subtitle={slide.description}
                            actionIcon={
                              <IconButton
                                sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                aria-label={`info about ${slide.description}`}
                                onClick={() => window.open(slide.url, "_blank")}
                              >
                                <ZoomIn />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  )}
                </CardContent>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
    </TitledPage>
  );
};

export default VirtualPresentationsPage;
