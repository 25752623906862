import { useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

import { useParams } from "react-router-dom";

import { isLanguageSupported, messages, SupportedLanguages } from "../../languages/languages";
import { Typography, Container, Card } from "@mui/material";
import { ApplicantDoc } from "../../types/applicants";
import { ApplicantDetails } from "./applicant-data";
import { PageInvalidQuestionnaire } from "./pageInvalidQuestionnaire";
import { isDateInThePast } from "../../utils/utils";
import { ApplicationDoc } from "../../types/visa-application";
import { loadApplications } from "../../data-functions/applications-api";
import { loadApplicant } from "../../data-functions/applicants-api";
import { AuthContext } from "../../components/auth-provider";

type ParamTypes = {
  qid: string;
};

type FormState = "loading" | "done" | "expired" | "badID";

export const Questionnaire = () => {
  const { qid } = useParams<ParamTypes>();
  const [formState, setFormState] = useState<FormState>("loading");
  const [applicantDoc, setApplicant] = useState<ApplicantDoc | null>(null);
  const [applicationDoc, setApplication] = useState<ApplicationDoc | null>(null);
  const [language, setLanguage] = useState<SupportedLanguages>("en");

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const fetchData = async () => {
      console.log("qid: " + qid);
      if (!qid) {
        setFormState("badID");
        //setMessage("You need a valid link for your questionnaire");
        return;
      }

      const applications = await loadApplications(null, { questionnaireId: qid });

      if (!applications || Object.keys(applications).length === 0) {
        console.log("no document with this id found");
        setFormState("badID");
        //        setMessage("You need a valid link for your questionnaire");
        return;
      }

      const id = Object.keys(applications)[0];
      const applDoc: ApplicationDoc = {
        id,
        application: applications[id],
      };

      const isApplicant =
        !currentUser || !currentUser.appUser || currentUser.appUser.role.includes("applicant");

      console.log("loaded application: ");
      console.log(applDoc);
      console.log(applDoc.application.dataForm_expiry_date);
      console.log("isApplicant:  " + isApplicant);
      console.log("language: " + language);

      if (applDoc.application.dataForm_expiry_date) {
        console.log(
          "date in the past: " +
            isDateInThePast(applDoc.application.dataForm_expiry_date.toString())
        );
      }

      if (
        applDoc.application &&
        applDoc.application.dataForm_expiry_date &&
        isDateInThePast(applDoc.application.dataForm_expiry_date.toString()) &&
        isApplicant
      ) {
        console.log("expired");
        setFormState("expired");
        return;
      }

      const userId = applDoc.application.applicantId;
      console.log("applicant: " + userId);

      const applicantData = await loadApplicant(userId);
      console.log("Applicant data:");
      console.log(applicantData);

      if (applicantData) {
        console.log("setting applicant: " + applicantData.id);
        setApplication(applDoc);
        setApplicant(applicantData);
        if (applicantData.applicant.preferredLanguage) {
          setLanguage(applicantData.applicant.preferredLanguage as SupportedLanguages);
        }
        setFormState("done");
      } else {
        setFormState("badID");
      }
    };

    const lng = navigator.language.split(/[-_]/)[0];
    console.log("Browser language: " + lng);
    if (isLanguageSupported(lng)) {
      setLanguage(lng as SupportedLanguages);
    }
    if (currentUser === null) {
      return;
    }
    fetchData();
  }, [qid, currentUser]);

  const handleLanguageChange = (language: SupportedLanguages) => {
    console.log("Language: " + language);
    setLanguage(language);
  };

  const getForm = () => {
    switch (formState) {
      case "loading":
        return (
          <Typography variant="h5" color="error">
            Loading form data...
          </Typography>
        );

      case "badID":
        return <PageInvalidQuestionnaire formState="badID" />;

      case "expired":
        return <PageInvalidQuestionnaire formState="expired" />;

      default:
        break;
    }
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Card sx={{ marginTop: "1rem", padding: { xs: "0", md: "1rem" } }}>
          {formState !== "done" && getForm()}
          {applicantDoc && applicationDoc && (
            <ApplicantDetails
              applicant={applicantDoc}
              application={applicationDoc}
              defaultLanguage={language}
              changeLanguage={handleLanguageChange}
            />
          )}
        </Card>
      </Container>
    </IntlProvider>
  );
};
