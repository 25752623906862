import { useContext, useEffect, useState, useRef } from "react";
import { IntlProvider } from "react-intl";

import { useParams } from "react-router-dom";

import { isLanguageSupported, messages, SupportedLanguages } from "../../languages/languages";
import { Typography, Container, Card } from "@mui/material";
//import { PageInvalidQuestionnaire } from "./pageInvalidQuestionnaire";
import { ApplicationDoc } from "../../types/visa-application";
import { PageInvalidPresentation } from "./pageInvalidPresentation";
import { isDateInThePast } from "../../utils/utils";
import { AuthContext } from "../../components/auth-provider";
import { loadApplications, storeApplication } from "../../data-functions/applications-api";
import { loadApplicant } from "../../data-functions/applicants-api";
import { PlayPresentation } from "./pagePlayPresentation";
import { EMPTY_VIRTUAL_PRESENTATION, VirtualPresentation } from "../../types/virtual-presentations";
import { loadVirtualPresentation } from "../../data-functions/virtual_presentataions_api";

type ParamTypes = {
  pid: string;
};

type FormState = "loading" | "done" | "expired" | "badID";

export const VirtualPresentationPlay = () => {
  const { pid } = useParams<ParamTypes>();
  const [formState, setFormState] = useState<FormState>("loading");
  const [presentation, setPresentation] = useState<VirtualPresentation>({
    ...EMPTY_VIRTUAL_PRESENTATION,
  });
  const [language, setLanguage] = useState<SupportedLanguages>("en");

  const applicationDoc = useRef<ApplicationDoc | null>(null);

  const { currentUser } = useContext(AuthContext)!;

  useEffect(() => {
    const fetchData = async () => {
      console.log("pid: " + pid);
      if (!pid) {
        setFormState("badID");
        //setMessage("You need a valid link for your questionnaire");
        return;
      }
      const applications = await loadApplications(null, { "virtualPresentation.link_id": pid });

      if (!applications || Object.keys(applications).length === 0) {
        console.log("no document with this id found");
        setFormState("badID");
        //        setMessage("You need a valid link for your questionnaire");
        return;
      }

      const id = Object.keys(applications)[0];
      const applDoc: ApplicationDoc = {
        id,
        application: applications[id],
      };

      const vp = applDoc.application.virtualPresentation;
      if (!(vp && vp.virtualPresentationId)) {
        setFormState("badID");
        return;
      }

      const isApplicant =
        !currentUser || !currentUser.appUser || currentUser.appUser.role.includes("applicant");

      console.log("loaded application: ");
      console.log(applDoc);
      console.log(vp.expiry_date);
      console.log("isApplicant:  " + isApplicant);
      console.log("language: " + language);

      if (vp.expiry_date) {
        console.log("date in the past: " + isDateInThePast(vp.expiry_date));
      }

      if (vp.expiry_date && isDateInThePast(vp.expiry_date) && isApplicant) {
        console.log("expired");
        setFormState("expired");
        return;
      }

      const userId = applDoc.application.applicantId;
      console.log("applicant: " + userId);

      const applicantData = await loadApplicant(userId);
      console.log("Applicant data:");
      console.log(applicantData);

      if (applicantData) {
        console.log("setting applicant: " + applicantData.id);
        if (applicantData.applicant.preferredLanguage) {
          setLanguage(applicantData.applicant.preferredLanguage as SupportedLanguages);
        }
      }

      const presentation = await loadVirtualPresentation(vp.virtualPresentationId);
      if (!presentation) {
        setFormState("badID");
        return;
      }

      applicationDoc.current = applDoc;
      setPresentation(presentation);
      setFormState("done");
    };

    const lng = navigator.language.split(/[-_]/)[0];
    console.log("Browser language: " + lng);
    if (isLanguageSupported(lng)) {
      setLanguage(lng as SupportedLanguages);
    }

    fetchData();
  }, [pid, currentUser]);

  // const handleLanguageChange = (language: SupportedLanguages) => {
  //   console.log("Language: " + language);
  //   setLanguage(language);
  // };

  const getForm = () => {
    switch (formState) {
      case "loading":
        return (
          <Typography variant="h5" color="error">
            Loading form data...
          </Typography>
        );

      case "badID":
        return <PageInvalidPresentation formState="badID" />;

      case "expired":
        return <PageInvalidPresentation formState="expired" />;

      default:
        break;
    }
  };

  const handleChangeSlide = async (num: number) => {
    if (!applicationDoc.current) {
      return;
    }

    const vp = applicationDoc.current.application.virtualPresentation;
    if (!vp) {
      return;
    }

    if (!vp.lastSlideViewed || vp.lastSlideViewed < num) {
      vp.lastSlideViewed = num;
    }

    await storeApplication(currentUser?.appUser, applicationDoc.current, false);
  };

  const handleSignPresentation = async () => {
    if (!applicationDoc.current) {
      return;
    }

    const vp = applicationDoc.current.application.virtualPresentation;
    if (!vp) {
      return;
    }

    vp.completedDate = new Date().toISOString();
    vp.status = "completed";

    await storeApplication(currentUser?.appUser, applicationDoc.current, false);
  };

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Container maxWidth="md" sx={{ marginTop: "2rem", marginBottom: "2rem" }}>
        {formState === "done" &&
          applicationDoc.current &&
          applicationDoc.current.application.virtualPresentation && (
            <PlayPresentation
              presentation={presentation}
              sponsor={
                (applicationDoc.current && applicationDoc.current.application.sponsorId) || ""
              }
              onChangeSlide={handleChangeSlide}
              onSign={handleSignPresentation}
            />
          )}
        {formState !== "done" && (
          <Card sx={{ marginTop: "1rem", padding: "0px" }}>{getForm()}</Card>
        )}
      </Container>
    </IntlProvider>
  );
};
