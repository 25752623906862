import { Grid, TextField } from "@mui/material";
import { Address, AddressErrors, Country, EMPTY_COUNTRY } from "../types/commons";
import { SelectCountry } from "./select-country";

interface AddressInputProps {
  address: Address;
  errors: AddressErrors;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryChange: (event: any, newValue: Country | null) => void;
  autoFocus?: boolean;
  secondStreetLine?: boolean;
}

export const AddressInput: React.FC<AddressInputProps> = ({
  address,
  errors,
  onInputChange,
  onCountryChange,
  autoFocus,
  secondStreetLine,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <TextField
          error={!!errors.street}
          helperText={errors.street}
          autoFocus={!!autoFocus}
          margin="dense"
          name="street"
          label="Address"
          type="text"
          fullWidth
          value={address && address.street}
          onChange={onInputChange}
        />
      </Grid>
      {secondStreetLine && (
        <Grid item xs={12} md={12}>
          <TextField
            error={!!errors.street}
            helperText={errors.street}
            autoFocus={!!autoFocus}
            margin="dense"
            name="street2"
            label="Address 2"
            type="text"
            fullWidth
            value={(address && address.street2) || ""}
            onChange={onInputChange}
          />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <TextField
          error={!!errors.city}
          helperText={errors.city}
          margin="dense"
          name="city"
          label="City"
          type="text"
          fullWidth
          value={(address && address.city) || ""}
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          error={!!errors.zip}
          helperText={errors.zip}
          margin="dense"
          name="zip"
          label="ZIP"
          type="text"
          fullWidth
          value={(address && address.zip) || ""}
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          error={!!errors.region}
          helperText={errors.region}
          margin="dense"
          name="region"
          label="Province"
          type="text"
          fullWidth
          value={(address && address.region) || ""}
          onChange={onInputChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectCountry
          id="country"
          label="Country"
          error={!!errors.country}
          helperText={errors.country}
          value={
            address && address.country && address.country.code ? address.country : EMPTY_COUNTRY
          }
          onChange={onCountryChange}
          translated={false}
        />
      </Grid>
    </Grid>
  );
};

// export interface Address {
//     address: string;
//     zip: string;
//     city: string;
//     region?: string;
//     country: Country;
//   }
