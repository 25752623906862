import { Applicant } from "../../types/applicants";
import { Country } from "../../types/commons";
import { EMPTY_RELATIVE, NUM_RELATIVES } from "../../types/relatives";

export const handleInputRelativesChange = (
  applicant: Applicant,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  let newApplicant = null;

  if (applicant) {
    newApplicant = {
      ...applicant,
    };
    const key = event.target.name;
    console.log("Received an event for input: " + key);
    if (key === "has_relatives_in_UK") {
      console.log("Setting: has relatives");
      newApplicant.has_relatives_in_UK = event.target.value === "true" ? true : false;
    } else if (key.startsWith("relative_") && newApplicant) {
      const cKey = key.split("_");
      const num: number = parseInt(cKey[1]);
      console.log("Setting: relative's data for the " + num + " element");

      // Make sure the children propert has enough elements
      const count = newApplicant.relatives_UK ? newApplicant.relatives_UK.length : 0;
      console.log("Found " + count + " relatives");

      if (count === 0) {
        newApplicant.relatives_UK = [];
      }

      for (let i = count; i < NUM_RELATIVES; i++) {
        console.log("Adding relative: " + i);
        newApplicant.relatives_UK && newApplicant.relatives_UK.push({ ...EMPTY_RELATIVE });
      }

      console.log("Setting property " + cKey[2]);
      console.log("value: " + event.target.value);
      if (newApplicant.relatives_UK) {
        switch (cKey[2]) {
          case "name":
            newApplicant.relatives_UK[num].name = event.target.value;
            break;

          case "surname":
            newApplicant.relatives_UK[num].surname = event.target.value;
            break;

          case "relation":
            newApplicant.relatives_UK[num].relation = event.target.value;
            break;

          case "typeofstay":
            newApplicant.relatives_UK[num].typeOfStay = event.target.value;
            break;
        }
      }
    }
  }
  return newApplicant;
};

export const handleRelativeNationalityChange = (
  applicant: Applicant,
  event: any,
  newValue: Country | null
) => {
  let newApplicant = null;
  if (applicant) {
    const key = event.target.id.split("-option")[0];
    console.log("key: " + key);
    if (key && key.startsWith("relative_") && key.endsWith("_nationality")) {
      const relativeNumber: number = parseInt(key.split("_")[1]);

      const newRelatives = applicant.relatives_UK ? [...applicant.relatives_UK] : [];

      // Make sure the relatives property has enough elements
      const count = newRelatives.length;

      for (let i = count; i < NUM_RELATIVES; i++) {
        console.log("Adding relative: " + i);
        newRelatives.push({ ...EMPTY_RELATIVE });
      }

      if (newValue) {
        console.log("setting the " + relativeNumber + " nationality to: ");
        console.log(newValue);
        newRelatives[relativeNumber] = {
          ...newRelatives[relativeNumber],
          nationality: newValue,
        };
      }

      newApplicant = {
        ...applicant,
        relatives_UK: newRelatives,
      };

      console.log(newApplicant.relatives_UK);
    }
  }
  return newApplicant;
};
