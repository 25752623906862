import { useContext, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { CampaignsDict, CandidatesDict } from "../../types/recruiting";
import { Applicant } from "../../types/applicants";
import { VisaApplication, APPLICATION_TYPES, ApplicationsDict } from "../../types/visa-application";
import { functions } from "../../types/firebase";
import { httpsCallable } from "firebase/functions";
import { saveCandidate } from "../../data-functions/recruiting_api";
import { getExpiryDate } from "../../utils/utils";
import { storeApplication, updateApplication } from "../../data-functions/applications-api";
import { existingApplicants, storeApplicant } from "../../data-functions/applicants-api";
import { AuthContext } from "../../components/auth-provider";
import { SeasonsDict } from "../../types/orders";
import { loadSeasons } from "../../data-functions/orders-api";

interface ConvertCandidatesDialogProps {
  candidates: CandidatesDict;
  campaigns: CampaignsDict;
  open: boolean;
  onClose: () => void;
}

interface ResultData {
  status: string;
  message: string;
}

const DEFAULT_EXPIRE_DAYS = 3;

const ConvertCandidatesDialog = ({
  candidates: initialCandidates,
  campaigns: initialCampaigns,
  open,
  onClose,
}: ConvertCandidatesDialogProps) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [openSnackError, setOpenSnackError] = useState(false);
  const [candidates, setCandidates] = useState<CandidatesDict>({});
  const [sendLink, setSendLink] = useState(false);
  const [expireDays, setExpireDays] = useState(DEFAULT_EXPIRE_DAYS);
  const [campaigns, setCampaigns] = useState<CampaignsDict>({});
  const [activePage, setActivePage] = useState(1);
  const [progress, setProgress] = useState(0);

  const [seasons, setSeasons] = useState<SeasonsDict>({});
  const [selectedSeason, setSelectedSeason] = useState<string>("");

  const [errors, setErrors] = useState<{ season: string }>({ season: "" });

  const { currentUser } = useContext(AuthContext)!;

  const fetchSeasons = async () => {
    const seasonsDict = await loadSeasons(currentUser?.appUser);
    const year = new Date().getFullYear();
    const seasonId = Object.entries(seasonsDict).find(([id, season]) => {
      return new Date(season.start_date).getFullYear() === year;
    })?.[0];
    setSeasons(seasonsDict);
    if (seasonsDict && seasonId) setSelectedSeason(seasonId);
  };

  useEffect(() => {
    fetchSeasons();
    const newCandidates = { ...initialCandidates };
    setActivePage(1);
    setSendLink(false);
    setExpireDays(DEFAULT_EXPIRE_DAYS);
    setCandidates(newCandidates);
  }, [initialCandidates]);

  useEffect(() => {
    setCampaigns({ ...initialCampaigns });
  }, [initialCampaigns]);

  const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setOpenSnackError(false);
  };

  //   const onCopySuccess = () => {
  //     setOpenSnack(true);
  //   };

  //   const onCopyError = (reason: any) => {
  //     console.error(reason);
  //     setOpenSnackError(true);
  //   };

  const handleSendLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendLink(event.target.checked);
  };

  const handleExpireDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const days: number = parseInt(event.target.value);
    if (days >= 3 && days <= 14) {
      setExpireDays(days);
    }
  };

  const convertCandidates = async () => {
    if (!selectedSeason) {
      setErrors({ season: "Please select a season fot the new applications" });
      return;
    } else {
      setErrors({ season: "" });
    }
    setProgress(0);
    setActivePage(2);

    const candidateIds = Object.keys(candidates);
    const numCandidates = candidateIds.length;

    const expiryDate = getExpiryDate(expireDays);

    const passApplications: ApplicationsDict = {};
    const noPassApplications: string[] = [];

    const emailList = Object.keys(candidates).map((key) => candidates[key].email);
    const existingEmails = await existingApplicants(currentUser?.appUser, emailList);

    if (existingEmails.length > 0) {
      console.log("existingEmails: ", existingEmails);
      const msg =
        `${existingEmails.length} of the candidates already have an application. ` +
        `They will not be converted to applicants.`;

      window.alert(msg);
    }

    for (let i = 0; i < numCandidates; i++) {
      const candidate = candidates[candidateIds[i]];
      if (
        candidate &&
        candidate.status !== "applicant" &&
        !existingEmails.includes(candidate.email.toLowerCase())
      ) {
        let preferredLanguage = candidate.preferredLanguage;
        if (!preferredLanguage) {
          preferredLanguage =
            candidate.campaignId && campaigns[candidate.campaignId].language
              ? campaigns[candidate.campaignId].language
              : "en";
        }

        let sponsorId = candidate.campaignId ? campaigns[candidate.campaignId].sponsorId : "";
        if (!sponsorId) {
          sponsorId = currentUser?.appUser?.sponsorId || "";
        }

        const agentId = candidate.campaignId ? campaigns[candidate.campaignId].agentId || "" : "";

        const newApplicant: Applicant = {
          sponsorId: sponsorId,
          agentId: agentId,
          name: candidate.name,
          surname: candidate.surname,
          email: candidate.email,
          phone: candidate.phone,
          preferredLanguage: preferredLanguage,
          createdAt: new Date().toISOString(),
          createdBy: currentUser?.appUser?.id || "anonymous",
        };

        if (candidate.has_passport && candidate.passportId) {
          newApplicant.passport = {
            id: candidate.passportId,
          };
        }

        const applId = await storeApplicant(
          currentUser?.appUser,
          { id: "", applicant: newApplicant },
          true
        );

        //const applId = uuidv4();
        if (applId) {
          const newApplication: VisaApplication = {
            applicantId: applId,
            type: { ...APPLICATION_TYPES[0] },
            status: "new",
            workOrderId: "",
            seasonId: selectedSeason,
            questionnaireId: uuidv4(),
            dataForm_expiry_date: expiryDate.toISOString(),
            sponsorId: sponsorId || "",
            agentId: agentId || "",
            createdAt: new Date().toISOString(),
            createdBy: currentUser?.appUser?.id || "anonymous",
          };

          const application = await storeApplication(
            currentUser?.appUser,
            { id: "", application: newApplication },
            true
          );

          if (application) {
            if (candidate.has_passport) {
              passApplications[application.id] = newApplication;
            } else noPassApplications.push(application.id);
          }

          candidate.status = "applicant";
          candidate.applicantId = applId;
          await saveCandidate(currentUser?.appUser, { id: candidateIds[i], candidate: candidate });
        }
      }

      setProgress(Math.round((i / numCandidates) * 100));
      console.log("progress: " + progress);
    }

    if (sendLink) {
      const mailDataFormFunction = httpsCallable(functions, "api/mailDataForm");
      console.log("mailing " + passApplications.length + " candidates with passport");
      console.log("mailing " + noPassApplications.length + " candidates without passport");

      try {
        let result;
        if (noPassApplications.length > 0) {
          result = await mailDataFormFunction({
            template: "no_passport",
            applications: noPassApplications,
          });
          console.log(result);
        }

        const passIds = Object.keys(passApplications);
        console.log("passIds: ", passApplications);

        if (passIds.length > 0) {
          result = await mailDataFormFunction({
            template: "approved",
            applications: passIds,
          });
          console.log(result);
          const resultData = result.data as ResultData;
          if (resultData.status === "OK") {
            // set the application status to "Sent for Visa"
            for (let i = 0; i < passIds.length; i++) {
              await updateApplication(currentUser?.appUser, passIds[i], {
                status: "survey_link",
              });
            }
          }
        }
      } catch (error) {
        console.error("Error calling cloud function:", error);
      }
    }
    setActivePage(3);
  };

  const handleClose = () => {
    onClose();
  };

  const onSeasonInputComboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("season changed", event.target.value);
    const value = event.target.value;
    setSelectedSeason(value);
  };

  const sortedSeasons = useMemo(
    () => Object.entries(seasons).sort(([, a], [, b]) => a.name.localeCompare(b.name)),
    [seasons]
  );

  const getActivePage = () => {
    switch (activePage) {
      case 1:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography fontWeight="bold">
                {candidates && Object.keys(candidates).length > 0
                  ? Object.keys(candidates).length + " "
                  : "No "}
                candidates will be be converted to applicants.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <Box paddingRight={"1rem"}>Assign the candidates to a season:</Box>
                <TextField
                  name={"seasonId"}
                  id={"seasonId"}
                  label={"Season"}
                  error={!!errors.season}
                  helperText={errors.season}
                  select
                  onChange={onSeasonInputComboChange}
                  value={selectedSeason}
                  margin={"dense"}
                  size="small"
                  sx={{ width: "200px" }}
                >
                  {sortedSeasons.map(([seasonId, season]) => {
                    return (
                      <MenuItem value={seasonId} key={seasonId}>
                        {season.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                As applicants they will be sent a data form to fill and can be assigned to an
                employers.
              </Typography>
              <Typography>
                Their status will be changed from "Approved" to "Sent for Visa".
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{ marginTop: "16px" }}
                  control={
                    <Switch onChange={handleSendLinkChange} checked={sendLink} name="sendLink" />
                  }
                  label="Send the candidate a link to their data form"
                />
                {sendLink && (
                  <FormControlLabel
                    sx={{ marginTop: "8px", marginLeft: "4px" }}
                    control={
                      <OutlinedInput
                        type="number"
                        size="small"
                        sx={{ width: "70px", marginRight: "8px" }}
                        value={expireDays}
                        onChange={handleExpireDaysChange}
                        inputProps={{
                          min: "1", // Set the minimum value
                          max: "14", // Set the maximum value
                        }}
                      />
                    }
                    label="days to fill the data form (min 1 day, max 14 days)"
                  />
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                disabled={Object.keys(candidates).length === 0}
                sx={{ marginTop: "16px" }}
                onClick={convertCandidates}
              >
                Conver candidates
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        );
      case 2:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <CircularProgress />
              <Typography>Converting candidates...</Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <CircularProgress variant="determinate" value={progress} /> */}
            </Grid>
          </Grid>
        );

      case 3:
        return (
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography>
                All the candidates have been moved to the apllications' section
                {sendLink && " and an invitation email has been sent to the candidates"}.
              </Typography>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogTitle>Convert Candidates</DialogTitle>
      <DialogContent sx={{ minHeight: "100px", width: "700px" }}>
        {getActivePage()}
        <Snackbar
          open={openSnack}
          autoHideDuration={1000}
          onClose={handleCloseSnack}
          message="Link successfully copied to the clipoard!"
        />
        <Snackbar open={openSnackError} autoHideDuration={1000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="error" sx={{ width: "100%" }}>
            The Link could not be copied to the clipboard!
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConvertCandidatesDialog;
