import { Applicant, BOOL_APPLICANT_PROPERTIES } from "../../types/applicants";
import { EMPTY_COUNTRY } from "../../types/commons";
import { Country } from "../../types/commons";
import { EMPTY_IMMERGENCY_CONTACT, ImmergencyContact } from "../../types/relatives";
import { Passport, EMPTY_PASSPORT } from "../../types/travels";

export const handleInputChange = (
  applicant: Applicant,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  let newApplicant = null;
  if (applicant) {
    const key = event.target.name;

    let value: string | boolean = event.target.value;
    if (BOOL_APPLICANT_PROPERTIES.includes(key)) {
      value = value === "true";
    }

    // Set the immergency contact property
    if (key.startsWith("immergency_contact")) {
      const iC: ImmergencyContact =
        applicant.immergency_contact && applicant.immergency_contact.name
          ? applicant.immergency_contact
          : { ...EMPTY_IMMERGENCY_CONTACT };
      if (key.endsWith("name")) {
        iC.name = value.toString();
      } else if (key.endsWith("phone")) {
        iC.phone = value as string;
      } else if (key.endsWith("relation")) {
        iC.relation = value as string;
      }

      newApplicant = {
        ...applicant,
        immergency_contact: iC,
      };
      // Set the passport property
    } else if (key.startsWith("pass_")) {
      const pass: Passport = applicant.passport ? { ...applicant.passport } : { ...EMPTY_PASSPORT };

      if (key.endsWith("id")) {
        pass.id = value.toString();
      } else if (key.endsWith("issue_date")) {
        pass.issue_date = value as string;
      } else if (key.endsWith("expiry_date")) {
        pass.expiry_date = value as string;
      } else if (key.endsWith("issuing_place")) {
        pass.issuing_place = value as string;
      } else if (key.endsWith("biometric")) {
        pass.is_biometric = value as boolean;
      }

      newApplicant = {
        ...applicant,
        passport: pass,
      };
    } else if (key === "parents_unknown") {
      newApplicant = {
        ...applicant,
        parents_unknown: event.target.checked,
      };
    } else {
      newApplicant = {
        ...applicant,
        [key]: value,
      };
    }
  }
  return newApplicant;
};

export const handleAddressChange = (applicant: Applicant, event: any, newValue: string | null) => {
  console.log("address change");
  console.log(event.target.id + " --> " + newValue);

  const key = event.target.id;
  let newApplicant = null;
  if (applicant && key) {
    newApplicant = {
      ...applicant,
      [key]: newValue ?? "",
    };
  }
  return newApplicant;
};

export const handleCountryChange = (applicant: Applicant, event: any, newValue: Country | null) => {
  let newApplicant = null;
  if (applicant) {
    const key = event.target.id.split("-option")[0];
    if (key) {
      switch (key) {
        case "pass_issuing_country":
          const pass: Passport = applicant.passport
            ? { ...applicant.passport }
            : { ...EMPTY_PASSPORT };
          pass.issuing_country = newValue ?? { ...EMPTY_COUNTRY };
          newApplicant = {
            ...applicant,
            passport: pass,
          };
          break;

        default:
          newApplicant = {
            ...applicant,
            [key]: newValue ?? { ...EMPTY_COUNTRY },
          };
      }
      console.log("key: " + key + "; value: " + newValue?.code);
      console.log(newApplicant[key as keyof Applicant]);
    }
  }
  return newApplicant;
};
