import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";

import { parse } from "papaparse";
import { collection, getDocs, doc, setDoc, query, where } from "firebase/firestore";
import db from "../types/firebase";
import { Applicant, CSV_MAPPING, BOOL_APPLICANT_PROPERTIES } from "../types/applicants";

const ImportDataPage: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);

  const mapCsvRowToApplicant = (row: Record<string, string>): Applicant => {
    const applicant: Partial<Applicant> = {
      name: "",
      surname: "",
    };
    const csvKeys = Object.keys(row);
    csvKeys.forEach((key) => {
      const propertyName = CSV_MAPPING[key.trim()];
      if (propertyName) {
        const csvValue = row[key].trim();
        // check for a boolean value
        if (BOOL_APPLICANT_PROPERTIES.includes(propertyName)) {
          (applicant[propertyName as keyof Applicant] as any) = csvValue
            .toLowerCase()
            .includes("yes");
        } else {
          (applicant[propertyName as keyof Applicant] as any) = csvValue;
        }
      }
    });
    applicant.english_level = applicant.english_level?.split("/")[0].trim();

    applicant.marital_status = applicant.marital_status?.split("/")[0].trim();

    // applicant.nationality = applicant.nationality?.split("/")[0].trim();

    // if (applicant.nationality?.toLocaleLowerCase() === "other") {
    //   applicant.nationality = row["Nationality Other"].trim();
    // }

    // if (applicant.pass_issuing_country?.toLocaleLowerCase() === "other") {
    //   applicant.pass_issuing_country =
    //     row["passport Issuing authority other"].trim();
    // }

    return applicant as Applicant;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const text: string = await file.text();
      console.log(text);
      const results = parse(text, {
        header: true,
        delimiter: ";",
      });
      const applicantsRef = collection(db, "applicants");

      for (let row of results.data) {
        if (typeof row === "object" && row !== null) {
          const applicant = mapCsvRowToApplicant(row as Record<string, string>);
          console.log("applicant read: ");
          console.log(applicant);
          const q = query(applicantsRef, where("passport.id", "==", applicant.passport?.id));
          const querySnapshot = await getDocs(q);
          if (querySnapshot.empty) {
            await setDoc(doc(applicantsRef), applicant);
          }
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", margin: "1.5rem" }}>
      <form noValidate autoComplete="off">
        <TextField
          type="file"
          id="outlined-basic"
          label="Outlined"
          variant="outlined"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          onChange={handleFileChange}
        />
        <Button variant="contained" onClick={handleUpload}>
          Import the applicants
        </Button>
      </form>
    </Box>
  );
};

export default ImportDataPage;
