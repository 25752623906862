import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface AboutDialogProps {
  open: boolean;
  handleClose: () => void;
}

const AboutDialog = ({ open, handleClose }: AboutDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle>About </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          {process.env.REACT_APP_NAME} {"(v. "}
          {process.env.REACT_APP_VERSION}
          {")"}
        </Typography>
        <Typography variant="body1">All rights reserved </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AboutDialog;
