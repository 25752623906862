import { User } from "firebase/auth";
import { TenantRecord, TrackedRecord } from "./commons";

export const USER_ROLES = [
  "applicant",
  "admin",
  "operator",
  "cos_operator",
  "recruiter",
  "employer",
];

export type UserSettingsMode = "add" | "edit";

export const EMPTY_APP_USER = {
  id: "",
  name: "",
  surname: "",
  email: "",
  sponsorId: "",
  role: [],
};

export interface AppUser extends TrackedRecord, TenantRecord {
  id: string;
  name: string;
  surname: string;
  email: string;
  role: string[];
  resetPasswordUponLogin?: boolean;
  resetExpiryDate?: string;
  disabled?: boolean;
}

export interface AuthUser {
  authUser?: User | null;
  appUser?: AppUser | null;
  realSponsorId?: string | null;
  realAgentId?: string | null;
}

export type AppUsersDict = Record<string, AppUser>;
export type AppUserDoc = { id: string; user: AppUser };
