import { EMPTY_COUNTRY } from "./commons";
import { Country } from "./commons";

export const NUM_TRAVEL_DESTINATIONS = 5;
export const NUM_TRAVEL_UK = 3;

export const EMPTY_TRAVEL = {
  destination: { ...EMPTY_COUNTRY },
  from: "",
  to: "",
  reason: "",
};

export const EMPTY_PASSPORT = {
  id: "",
  issue_date: "",
  expiry_date: "",
  issuing_country: { ...EMPTY_COUNTRY },
  issuing_place: "",
  is_biometric: false,
};

export const EMPTY_PASSPORT_ERRORS = {
  id: "",
  issue_date: "",
  expiry_date: "",
  issuing_country: "",
  issuing_place: "",
  is_biometric: "",
};

export interface TravelDetails {
  destination: Country;
  from: string;
  to: string;
  reason: string;
}

export interface TravelDetailsError {
  destination: string;
  from: string;
  to: string;
  reason: string;
}

export interface Passport {
  id: string;
  issue_date?: string;
  expiry_date?: string;
  issuing_country?: Country;
  issuing_place?: string;
  is_biometric?: boolean;
}

export interface PassportErrors {
  id: string;
  issue_date?: string;
  expiry_date?: string;
  issuing_country?: string;
  issuing_place?: string;
  is_biometric?: string;
}
