import { Box, Typography } from "@mui/material";

interface TitledPageProps {
  children: JSX.Element;
  title: string;
}

export const TitledPage = ({ children, title }: TitledPageProps) => {
  return (
    <Box
      sx={{
        margin: "1.5rem",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        {title}
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
