import {
  CollectionReference,
  Query,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  CampaignDoc,
  CampaignsDict,
  Candidate_FB,
  CandidateDoc,
  CandidatesDict,
  RecruitingCampaign,
} from "../types/recruiting";
import db from "../types/firebase";
import { AppUser } from "../types/users";
import { chunkArray } from "../utils/utils";
import { Filter } from "../types/commons";
import { applyFilter } from "../utils/db-utils";
import { getCandidateFromFB, setCandidateToFB } from "../utils/candidate-utils";

export const loadCandidate = async (candidateId: string) => {
  let loadedCandidate = null;

  if (candidateId) {
    const docRef = doc(db, "candidates", candidateId);
    const candidateSnapshot = await getDoc(docRef);
    if (candidateSnapshot) {
      loadedCandidate = {
        id: candidateSnapshot.id,
        candidate: getCandidateFromFB(candidateSnapshot.data() as Candidate_FB),
      };
    }
  }
  return loadedCandidate;
};

export const loadCandidateByVerificationCode = async (verificationCode: string) => {
  let loadedCandidate = null;

  if (verificationCode) {
    const candidatesRef = query(
      collection(db, "candidates"),
      where("verification.verificationCode", "==", verificationCode)
    );
    const candidateSnapshot = await getDocs(candidatesRef);

    if (candidateSnapshot && candidateSnapshot.size > 0) {
      loadedCandidate = {
        id: candidateSnapshot.docs[0].id,
        candidate: getCandidateFromFB(candidateSnapshot.docs[0].data() as Candidate_FB),
      };
    }
  }
  return loadedCandidate;
};

export const saveCandidate = async (
  user: AppUser | null | undefined,
  candidate: CandidateDoc,
  createIfNoId: boolean = false
) => {
  const appDoc = { ...candidate };
  const fbDoc = setCandidateToFB(appDoc.candidate);
  console.log("fbDoc", fbDoc);

  if (appDoc.id) {
    const docRef = doc(db, "candidates", appDoc.id);
    const candidateSnapshot = await getDoc(docRef);
    // Update the document

    if (candidateSnapshot) {
      appDoc.candidate.updatedAt = new Date().toISOString();
      appDoc.candidate.updatedBy = user ? user.id : "anonymous";
      await setDoc(docRef, fbDoc);
      console.log("updated candidate:", appDoc.id);
    }
  } else if (createIfNoId) {
    appDoc.candidate.createdAt = new Date().toISOString();
    appDoc.candidate.createdBy = user ? user.id : "anonymous";

    appDoc.id = (await addDoc(collection(db, "candidates"), fbDoc)).id;
    console.log("created candidate:", appDoc.id);
  }

  return appDoc;
};

export const deleteCandidate = async (candidateId: string) => {
  return deleteDoc(doc(db, "candidates", candidateId));
};

export const loadCandidates = async (
  user: AppUser | null | undefined,
  campaigns: CampaignsDict,
  filter: Filter = {},
  cLimit: number = 0
) => {
  let candidatesRef: Query | CollectionReference;
  const candidatesDict: CandidatesDict = {};

  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  const agentId = user && user.agentId ? user.agentId : "";

  const campaignIds: string[] = Object.keys(campaigns).filter((id) => {
    let res = sponsorId ? campaigns[id].sponsorId === sponsorId : true;
    res &&= agentId ? campaigns[id].agentId === agentId : true;
    return res;
  });

  // if no campaigns are given, load all campaigna for the given sponsor
  if (campaignIds.length === 0 && sponsorId !== "") {
    const sCampaigns = await loadCampaigns(user);
    campaignIds.push(...Object.keys(sCampaigns));
  }

  if (campaignIds.length > 0) {
    const chunks = chunkArray(campaignIds, 30);
    for (const chunk of chunks) {
      candidatesRef = query(collection(db, "candidates"), where("campaignId", "in", chunk));
      candidatesRef = applyFilter(candidatesRef, filter, cLimit);

      const snapshot = await getDocs(candidatesRef);

      snapshot.forEach((doc) => {
        candidatesDict[doc.id] = getCandidateFromFB(doc.data() as Candidate_FB);
        if (cLimit > 0 && Object.keys(candidatesDict).length === cLimit) {
          console.log("loaded " + Object.keys(candidatesDict).length + " candidates");
          return candidatesDict;
        }
      });
    }
  } else {
    if (sponsorId === "") {
      candidatesRef = query(collection(db, "candidates"), where("campaignId", "==", ""));
      candidatesRef = applyFilter(candidatesRef, filter, cLimit);
      const candidatesSnapshot = await getDocs(candidatesRef);

      console.log("loaded " + candidatesSnapshot.size + " candidates");

      candidatesSnapshot.forEach((doc) => {
        const candidate = getCandidateFromFB(doc.data() as Candidate_FB);
        candidatesDict[doc.id] = candidate;
      });
    } else return {};
  }

  return candidatesDict;
};

export const loadCampaign = async (campaignId: string) => {
  let loadedCampaign = null;

  if (campaignId) {
    const docRef = doc(db, "campaigns", campaignId);
    const campaignSnapshot = await getDoc(docRef);
    if (campaignSnapshot) {
      loadedCampaign = {
        id: campaignSnapshot.id,
        campaign: campaignSnapshot.data() as RecruitingCampaign,
      };
    }
  }
  return loadedCampaign;
};

export const loadCampaigns = async (
  user: AppUser | null | undefined,
  filter: Filter = {},
  cLimit: number = 0
) => {
  const sponsorId = user && user.sponsorId ? user.sponsorId : "";
  const agentId = user && user.agentId ? user.agentId : "";

  let campaignsRef: Query | CollectionReference;
  let campaignsSnapshot;
  const campaignsDict: CampaignsDict = {};

  if (agentId) {
    campaignsRef = query(collection(db, "campaigns"), where("agentId", "==", agentId));
  } else if (sponsorId) {
    campaignsRef = query(collection(db, "campaigns"), where("sponsorId", "==", sponsorId));
  } else {
    campaignsRef = collection(db, "campaigns");
  }

  campaignsRef = applyFilter(campaignsRef, filter, cLimit);

  campaignsSnapshot = await getDocs(campaignsRef);

  campaignsSnapshot.forEach((doc) => {
    const campaign = doc.data() as RecruitingCampaign;
    campaignsDict[doc.id] = campaign;
  });

  return campaignsDict;
};

export const saveCampaign = async (
  user: AppUser | null | undefined,
  campaign: CampaignDoc,
  createIfNoId: boolean = false
) => {
  const appDoc: CampaignDoc = { ...campaign };
  if (appDoc.id) {
    const docRef = doc(db, "campaigns", appDoc.id);
    const campaignSnapshot = await getDoc(docRef);
    // Update the document
    if (campaignSnapshot) {
      appDoc.campaign.updatedAt = new Date().toISOString();
      appDoc.campaign.updatedBy = user ? user.id : "anonymous";

      await setDoc(docRef, appDoc.campaign);
      console.log("updated campaign:", appDoc.id);
    } else return null;
  } else if (createIfNoId) {
    appDoc.campaign.createdAt = new Date().toISOString();
    appDoc.campaign.createdBy = user ? user.id : "anonymous";
    appDoc.id = (await addDoc(collection(db, "campaigns"), appDoc.campaign)).id;
    console.log("created campaign:", appDoc.id);
  }

  return appDoc;
};

export const deleteCampaign = async (campaignId: string) => {
  return deleteDoc(doc(db, "campaigns", campaignId));
};
